import { Box, Button, Dialog, DialogTitle, Grid, IconButton, Link, Slide, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { BodyMeasurementsForSize, CalculateClothingMeasurementsFromBodyMeasurements, GetKey2CategoryClothingSizeMeasurementValues, RoundClothingMeasurementsToHalfPoint, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { inputMeasurementsForm, postClothingMeasurement, postClothingMeasurementPreference, resetUserMeasurementDraft, saveLocallyClothingMeasurementPreference } from '../redux/MeasurementsRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import OverlayComponentCanvas from './OverlayComponentCanvasFitAnimation';
import { RegularSizeValue } from 'common-ts/dist/models/Cart';
import { ClothingMeasurementDetailPanel, getClothingMeasurementsSubtext } from './OverlayViewGenerateMeasureMethod3Favorite1ModifySize';
import { VarietySliderComponent } from './OverlayViewUniversalSize4Result';
import { getSortKey } from './OverlayViewPersonalSize';
import { Close } from '@material-ui/icons';
import { openOverlay as openOverlayEditView, closeOverlayAndReset, setValues } from '../redux/UIOverlayMeasurementEditViewRedux';
import { FareastButton, FareastButtonWhite } from './OverlayComponentButton';
import { setPilihUkuranDataValues } from '../redux/UIOverlayMeasurementSelectionViewRedux';
import { formatTimestampSize } from './PageProductDetailSizeSelection';

interface stylesProps {
  isFullScreen: boolean
}

const useStyles = ({ isFullScreen }: stylesProps) =>  makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 2) + " !important") as any, // LEVEL 2 IN Z INDEX
      '& .MuiPaper-root': {
        borderRadius: isFullScreen ? '0px' : '30px',
        margin: isFullScreen ? '0px' : '0px 16px',
        maxHeight: isFullScreen ? '' : 'calc(100% - 16px)',
        maxWidth: isFullScreen ? '' : '380px',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    dialogContentContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center', 
    },
    toggleSignUpSignInContainer: {
      paddingTop: '15px',
      paddingBottom: '30px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    titleLogoContainer: {
      paddingTop: '30px',
      paddingBottom: '20px',
      height: '42px',
    },
    titleLogoDesktop: {
      height: '24px',
    },
    canvasContainer: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.only('xs')]: {
        height: '40vw',
      },
      position: 'relative',
    },
    popupTitle: {
      padding: '0.5em 0.5em 0 0.5em',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    popupTitlePilihUkuranText: {
      fontSize: '12px',
      color: '#D1D1D1',
      fontWeight: 300,
      padding: '16px 0px 0px 18px'
    },
    popupCloseButton: {
      marginRight: '3px',
    },
  }),
);

export function MauUbahUkuranComponent({ onClickFunction }: { onClickFunction: () => void }) {
  return (
    <Box style={{
      width: '82%',
      padding: '3.9% 4.5%',
      borderRadius: '4.5vw',
      marginBottom: '4vw',
      backgroundColor: '#efefef',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    }} onClick={onClickFunction}>
      <Box>
        <Typography style={{
          fontSize: '2.6vw',
          fontWeight: 300,
          letterSpacing: '0.1vw',
          color: '#878787',
        }}>
          ADA YANG MAU DIUBAH?
        </Typography>
        <Typography style={{
          fontSize: '2.9vw',
          fontWeight: 400,
        }}>
          Buat ukuran baru dari ukuran ini.
        </Typography>
      </Box>
      <Typography style={{
        padding: '5px 7px 3px 7px',
        border: '1px solid black',
        borderRadius: '20px',
        fontSize: '2.4vw',
        fontWeight: 500,
      }}>
        UBAH UKURAN
      </Typography>
    </Box>
  )
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayView() {
  const overlayViewSelection = useAppSelector(state => state.overlayMeasurementSelectionView);
  const overlayView = useAppSelector(state => state.overlayMeasurementEditView);
  const dispatch = useAppDispatch();
  const classes = useStyles({ isFullScreen: overlayView.isFullScreen })();

  const isMinimized = !overlayView.isFullScreen

  const clothingType = overlayView.clothingType;
  const fitType = overlayView.fitType;

  const clothBodyMeasurements = overlayView.currentMeasurementObject?.adjustedMeasurements ? 
  overlayView.currentMeasurementObject?.adjustedMeasurements : BodyMeasurementsForSize[RegularSizeValue.L]; // Works on body and clothBody

  const productsDictionary = useAppSelector(state => state.productsList.productsDictionary);
  const productSizeAvailabilityDict = useAppSelector(state => state.productsList.productSizeAvailabilityDict);
  
  let displayImagesArray: string[] = [];

  if (productsDictionary) {
    const startingProductIds = Object.keys(productsDictionary)
    .sort((a, b) => getSortKey(productsDictionary[a], productSizeAvailabilityDict?.[a]) > getSortKey(productsDictionary[b], productSizeAvailabilityDict?.[b]) ? 1 : -1)

    const clothingTypeCurrent = clothingType;
    const fitTypeCurrent = fitType;
    const filteredArray = startingProductIds.filter((productId) => productsDictionary[productId].clothingType === clothingTypeCurrent && productsDictionary[productId].defaultFitType === fitTypeCurrent);
    displayImagesArray = filteredArray.map((productId) => productsDictionary[productId].displayImage);
  }

  const key2ClothSizeValues = GetKey2CategoryClothingSizeMeasurementValues(clothingType, 
    fitType, false);
  const currentClothingMeas = RoundClothingMeasurementsToHalfPoint(CalculateClothingMeasurementsFromBodyMeasurements(
    clothingType, 
    fitType,
    clothBodyMeasurements, RegularSizeValue.L));

  const subtextDict = getClothingMeasurementsSubtext(clothingType, key2ClothSizeValues, currentClothingMeas);

  let mainName = 'Detail Ukuran';
  let caraBuatString = 'Dibuat Online';
  let tanggalBuatString = '-';
  if (overlayView?.currentMeasurementObject?.creationDateTime) {
    tanggalBuatString = 'Dibuat ' + (formatTimestampSize(overlayView.currentMeasurementObject.creationDateTime));
  }
  if (overlayView.currentMeasurementObject?.bodyOrClothingMeasurements === 'CLOTHING') {
    mainName = overlayView?.currentMeasurementObject?.measurementName ? overlayView.currentMeasurementObject.measurementName : 'Detail Ukuran';
    caraBuatString = overlayView.currentMeasurementObject.caraBuatString ? overlayView.currentMeasurementObject.caraBuatString : 'Dibuat Online';
  } else {
    mainName = overlayView.currentMeasurementObject?.nama ? overlayView.currentMeasurementObject.nama : 'User';
    caraBuatString = 'Dari Ukuran Badan';
  }


  function closeOverlayAndResetUserMeasurementDraft() {
    dispatch(closeOverlayAndReset());
  }

  return (
    <Dialog
    maxWidth="lg"
    fullScreen={overlayView.isFullScreen}
    open={overlayView.status === UIOverlayViewStatus.On && overlayView.overlayType === 'view'}
    TransitionComponent={Transition}
    keepMounted
    onClose={closeOverlayAndResetUserMeasurementDraft}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    className={classes.dialogContainer}>
      <DialogTitle style={{ padding: '0px 0px' }}>
        <Box className={classes.popupTitle}>
          <Typography className={classes.popupTitlePilihUkuranText}>
            {overlayView.origin === 'qr' ? 'UBAH UKURAN PRODUK' : 'DETAIL UKURAN'}
          </Typography>
          <IconButton
            className={classes.popupCloseButton}
            edge="end"
            color="inherit"
            onClick={closeOverlayAndResetUserMeasurementDraft}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box className={classes.dialogContentContainer}>
      {
      overlayView.currentMeasurementObject?.bodyOrClothingMeasurements === 'BODY' ? (
        <>
          <Typography style={{
            fontSize: '9px',
            fontWeight: 300,
            marginBottom: '0.3vw',
          }}>
            UKURAN BAJU
          </Typography>
          <Typography style={{
            fontSize: '18px',
            fontWeight: 500,
            marginBottom: '1.1vw',
          }}>
            {mainName}
          </Typography>
        </>
      ) : (
        isMinimized ? (
          <>
            <Typography style={{
              fontSize: '15px',
              fontWeight: 'bold',
              marginBottom: '3.75px',
              marginTop: '15px',
              textAlign: 'center',
            }}>
              {mainName}
            </Typography>
            <Typography style={{
              fontSize: '9.375px',
              fontWeight: 300,
              marginBottom: '11.25px',
              letterSpacing: '0.5px'
            }}>
              {tanggalBuatString}
            </Typography>
          </>
        ) : (
          <Typography style={{
            fontSize: '4.5vw',
            fontWeight: 500,
            marginBottom: '3vw',
          }}>
            {mainName}
          </Typography>
        )
      )}
      {
        !isMinimized && (
          <Grid container style={{
            width: '90%',
            padding: '7px 0px 5px 0px',
            borderRadius: '6vw',
            border: '1px solid #eaeaea',
          }} justifyContent='space-around'>
            <Grid item xs={6} style={{
              overflow: 'hidden',
              textAlign: 'center',
            }}>
              <Typography style={{
                fontSize: '2.2vw',
                letterSpacing: '0.1vw',
                color: '#6d6d6d',
                fontWeight: 300,
              }}>
                CARA BUAT
              </Typography>
              <Typography style={{
                paddingTop: '0.2vw',
                fontSize: '2.3vw',
                fontWeight: 400,
              }}>
                {caraBuatString}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{
              overflow: 'hidden',
              textAlign: 'center',
            }}>
              <Typography style={{
                fontSize: '2.2vw',
                letterSpacing: '0.1vw',
                color: '#6d6d6d',
                fontWeight: 300,
              }}>
                TANGGAL BUAT
              </Typography>
              <Typography style={{
                paddingTop: '0.2vw',
                fontSize: '2.3vw',
                fontWeight: 400,
              }}>
                {tanggalBuatString}
              </Typography>
            </Grid>
          </Grid>
        )
      }
        
        <Box className={classes.canvasContainer} style={{
          height: isMinimized ? '175px' : '35vw',
        }}>
          <OverlayComponentCanvas
          clothingType={clothingType}
          fitType={fitType}
          putAtTop={false}
          scaleRatio={isMinimized ? 0.8 : 0.75}
          incrementTogether={true}
          goalFit={currentClothingMeas}
          drawGuidelinesData={undefined}
          canvasManualHeight={isMinimized ? '175px' : '35vw'}/>
        </Box>
        {
          !isMinimized && (
            <VarietySliderComponent
              isLoading={false}
              displayImagesArray={displayImagesArray}
              customScrollProductHeight={'28vw'}
              customElementWidth={'16.5vw'}
            />
          )
        }
        <Box style={{
          margin: '0px 10px',
          padding: '12px 0px 12px 19px',
          display: isMinimized ? 'flex' : 'block',
          justifyContent: isMinimized ? 'center' : 'flex-start',
        }}>
          <ClothingMeasurementDetailPanel
            clothingType={clothingType}
            fitType={fitType}
            allowEditing={false}
            currentClothingMeas={currentClothingMeas}
            currentClothingMeasSubtextDict={subtextDict}
            currentlyFetchingAvailability={false}
            isMinimized={isMinimized}
          />
        </Box>
        {
          overlayView.canEdit === false ? <Box style={{width: '100%', height: '20px'}}/> :
          isMinimized ? (
            <Box style={{
              width: '85%',
              marginTop: '18.75px',
              marginBottom: '22.5px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Button
                onClick={() => {
                  if (overlayView.currentMeasurementObject) {
                    dispatch(closeOverlayAndReset());
                    dispatch(setValues({
                      overlayType: 'edit',
                      clothingType: clothingType,
                      fitType: fitType,
                      isFullScreen: overlayView.isFullScreen,
                      currentMeasurementObject: overlayView.currentMeasurementObject,
                      productDetail: overlayView.productDetail ?? undefined,
                      origin: overlayView.origin
                    }))
                    dispatch(openOverlayEditView());
                  }
                }}
                style={{
                  padding: '8px 50px 6px 50px',
                  border: '1px solid #969696',
                  borderRadius: '20px',
                  fontSize: '10.5px',
                  fontWeight: 600,
                  color: 'black',
                  letterSpacing: '0.75px',
                }}
              >
                UBAH
              </Button>
              <Button
                onClick={() => {
                  // Apabila di selection screen
                  if (overlayViewSelection.status === UIOverlayViewStatus.On) {
                    dispatch(setPilihUkuranDataValues({
                      key2: overlayViewSelection.clothingType + '-' + overlayViewSelection.fitType,
                      creationDateTime: overlayView.currentMeasurementObject?.creationDateTime ?? overlayViewSelection?.pilihUkuranData?.creationDateTime,
                      prefCreationDateTime: overlayView.currentMeasurementObject?.creationDateTime ?? overlayViewSelection?.pilihUkuranData?.creationDateTime, // TODO - Nanti saat body harus tambahkan isBody
                      isOwnedByUser: true
                    }))
                  }
                  dispatch(closeOverlayAndReset());
                }}
                style={{
                  padding: '8px 50px 6px 50px',
                  border: '2px solid black',
                  borderRadius: '20px',
                  fontSize: '10.5px',
                  fontWeight: 600,
                  color: 'white',
                  letterSpacing: '0.75px',
                  backgroundColor: 'black'
                }}
              >
                PAKAI
              </Button>
            </Box>
          ) : (
            <MauUbahUkuranComponent onClickFunction={() => {
              if (overlayView.currentMeasurementObject) {
                dispatch(closeOverlayAndReset());
                dispatch(setValues({
                  overlayType: 'edit',
                  clothingType: clothingType,
                  fitType: fitType,
                  isFullScreen: overlayView.isFullScreen,
                  currentMeasurementObject: overlayView.currentMeasurementObject,
                  productDetail: overlayView.productDetail ?? undefined,
                  origin: overlayView.origin
                }))
                dispatch(openOverlayEditView());
              }
            }} />
          )
        }
      </Box>
    </Dialog>
  )
}