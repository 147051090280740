import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import {
  Box, Button, createStyles, Dialog, Grid, IconButton, makeStyles, Theme, Typography, useTheme
} from '@material-ui/core';
import { ArrowBack, Close } from '@material-ui/icons';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import Icon from '@mui/material/Icon';

import { FareastButton } from './OverlayComponentButton';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { Order } from 'common-ts/dist/models/Orders';
import { BodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { closeOverlayAndReset as closeOverlayAndResetEditView, openOverlay as openOverlayEditView, setValues as setValuesEditView } from '../redux/UIOverlayMeasurementEditViewRedux';
import React from 'react';
import OverlayComponentButton from './OverlayComponentButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 1) + " !important") as any,
    },
    backButton: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(2),
      backgroundColor: 'black',
      borderRadius: '50%',
      padding: theme.spacing(1),
    },
    backButtonIcon: {
      padding: '0',
      color: 'white',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '1em 1.5em',
      overflowY: 'hidden',
    },
    pageTitle: {
      marginTop: '1em',
      fontSize: '1.2em',
      width: '80%',
      fontWeight: 550,
    },
    pageItem: {
      display: 'grid',
      gridTemplateColumns: '1fr 8fr',
      marginTop: '1em',
    },
    pageItemIcon: {
      marginRight: '0.2em',
    },
    pageItemTitle: {
      paddingTop: '0.2em',
      fontSize: '0.8em',
      fontWeight: 500,
    },
    pageItemDescription: {
      gridColumn: '2',
      paddingTop: '0.2em',
      paddingRight: '0.5em',
      fontSize: '0.7em',
    },
  }),
);

interface ClothBodyMeasSuggestion {
  productId: string
  productName: string
  measurementName: string
  productDisplayImage: string
  purchaseDateString: string
  clothMeasValue: BodyMeasurements
  purchaseCount: number
  clothingType: string
  fitType: string
  userMeasurementsForItem: any
}

function getClothBodyMeasSuggestions(ordersList: Order[]) {

  const paidClothBodyMeasSuggestion: ClothBodyMeasSuggestion[] = [];

  for (var i = 0; i < ordersList.length; i += 1) {
    const currentOrder = ordersList[i];
    if (currentOrder.paymentStatus === 'capture' || 
    currentOrder.paymentStatus === 'capture_stockonly' || 
    currentOrder.paymentStatus === 'settlement') {
      for (var j = 0; j < currentOrder.cartProductList.length; j += 1) {
        const currentPaidItem = currentOrder.cartProductList[j];
        paidClothBodyMeasSuggestion.push({
          productId: currentPaidItem.productDetail.productId,
          productName: currentPaidItem.productDetail.name,
          productDisplayImage: currentPaidItem.productDetail.displayImage,
          measurementName: (currentPaidItem.userMeasurementsForItem as any)?.measurementName ?? (currentPaidItem.userMeasurementsForItem as any)?.regularSize,
          purchaseDateString: currentOrder.creationDateTime,
          clothMeasValue: (currentPaidItem.userMeasurementsForItem as any)?.adjustedMeasurements,
          purchaseCount: ordersList.length - i,
          clothingType: (currentPaidItem as any)?.clothingType,
          fitType: (currentPaidItem as any)?.fitType,
          userMeasurementsForItem: (currentPaidItem.userMeasurementsForItem as any),
        })
      }
    }
  }

  return paidClothBodyMeasSuggestion;
}

interface PreviousSizeSuggestionElementProps {
  srcImageUrl: string
  productName: string
  measurementName: string
  clothingType: string
  fitType: string
  userMeasurementsForItem: any
  purchaseDateTime: string
  isSelected: boolean
  purchaseCount: number
  handleClick: () => void
}

const counterText = ['Pertama', 'Pertama', 'Kedua', 'Ketiga', 'Keempat', 'Kelima', 'Keenam', 'Ketujuh', 'Kedelapan'];

export function PreviousSizeSuggestionElement({ srcImageUrl, productName, measurementName, clothingType, fitType,
  purchaseDateTime, isSelected, handleClick, purchaseCount, userMeasurementsForItem }: PreviousSizeSuggestionElementProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  return (
    <Grid container
      direction="row"
      justifyContent="space-between"
      alignItems="center" style={{
        backgroundColor: isSelected ? '#e9e9e9' : '#f8f8f8',
        borderRadius: '30px',
        padding: '14px',
        border: isSelected ? '1px solid #7a7a7a' : '1px solid #d7d7d7',
        marginBottom: '8px',
        position: 'relative',
      }}
      >
      <Grid xs={3} onClick={handleClick}>
        <img src={srcImageUrl} alt={'disp-img'}
        style={{
          width: '90%'
        }}/>
      </Grid>
      <Grid xs={9} onClick={handleClick} style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '26vw',
      }}>
        <Typography
        style={{
          fontSize: '3.4vw',
          fontWeight: 500,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        >{productName}</Typography>
        <Box>
          <Typography
          style={{
            fontSize: '2vw',
            letterSpacing: '0.07vw',
            fontWeight: 600,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            paddingBottom: '0.2vw',
            color: '#121212',
          }}
          >SIZE YANG DIPAKAI</Typography>
          <Typography
          style={{
            fontSize: '4.1vw',
            fontWeight: 300,
            color: '#222222'
          }}
          >{measurementName}</Typography>
        </Box>
        <Typography
        style={{
          fontSize: '2.4vw',
          fontWeight: 300,
          color: '#232323',
        }}
        >Dari Pembelian <span style={{textDecoration: 'underline', textUnderlineOffset: '2px'}}>{counterText?.[purchaseCount] ?? 'Terakhir'}</span> Kamu
        </Typography>
      </Grid>
      {
      userMeasurementsForItem ?
      <Box style={{position: 'absolute', bottom: '17px', right: '16px'}}>
        <IconButton
          style={{
            border: '1px solid #232323',
            borderRadius: '30px',
            backgroundColor: '#232323',
            color: '#fefefe',
            padding: '1.2vw 2.4vw',
            [theme.breakpoints.up('sm')]: {
              padding: '5px 10px 5px 10px',
            }
          }}
          disabled={!userMeasurementsForItem}
          onClick={() => {
            dispatch(closeOverlayAndResetEditView());
            dispatch(setValuesEditView({
              overlayType: 'view',
              clothingType: (clothingType as any),
              fitType: (fitType as any),
              isFullScreen: false,
              currentMeasurementObject: userMeasurementsForItem,
              productDetail: undefined,
              canEdit: false,
              origin: 'order'
            }))
            dispatch(openOverlayEditView());
          }}aria-label="zoom-out">
            <Typography style={{
              fontWeight: 500,
              letterSpacing: 0.5,
              paddingTop: '2px',
              fontSize: '2.1vw', // 9px -> 3px is 0.8vw
              [theme.breakpoints.up('sm')]: {
                fontSize: '9px',
                paddingTop: '2px',
              }
            }}>
              DETAIL SIZE ⓘ
            </Typography>
        </IconButton>
      </Box>
      : null
      }
    </Grid>);
}

interface Props {
  handleBack: () => void
  handleNext: (updatedCurrentFlowData: any) => void
}

export default function PersonalSizeBenefit({ handleBack, handleNext }: Props) {
  const classes = useStyles()

  const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
  const clothMeasSuggestions = useAppSelector(state => getClothBodyMeasSuggestions(state.ordersList.ordersList));
  
  return (
  <>
    <Box
      className={classes.backButton}
      onClick={handleBack}
    >
      <IconButton className={classes.backButtonIcon}>
        <Close />
      </IconButton>
    </Box>
    <Box className={classes.dialogContent}>
      <Typography className={classes.pageTitle} style={{marginTop: '70px'}}>
        Pilih Ukuran Pembelian Terakhir
      </Typography>
      <Typography className={classes.pageItemDescription} style={{margin: '5px 0px 23px', color: '#535353', fontWeight: 300, letterSpacing: '0.1vw'}}>
        Pilih produk yang kamu yang paling kamu suka fitnya di badan kamu.
      </Typography>
      {
        clothMeasSuggestions.map((suggestionObject, index) => {

          return (
            <PreviousSizeSuggestionElement
              srcImageUrl={suggestionObject.productDisplayImage}
              productName={suggestionObject.productName}
              measurementName={suggestionObject.measurementName}
              purchaseDateTime={suggestionObject.purchaseDateString}
              isSelected={selectedIndex === index}
              purchaseCount={suggestionObject.purchaseCount}
              clothingType={suggestionObject.clothingType}
              fitType={suggestionObject.fitType}
              userMeasurementsForItem={suggestionObject.userMeasurementsForItem}
              handleClick={() => {
                if (selectedIndex === index) {
                  setSelectedIndex(-1);
                } else {
                  setSelectedIndex(index); 
                }
              }}
            />
          )
        })
      }
      <Box style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
        <Box style={{width: '30%'}}>
          <OverlayComponentButton
            handleClick={() => handleNext({
              ...clothMeasSuggestions[selectedIndex]
            })} 
            disabled={selectedIndex === -1}
            isTypeSubmit={false}
            formButtonText="PAKAI"
            buttonMarginTopBottom="0px"
            buttonMarginLeftRight="0px"
          />
        </Box>
      </Box>
    </Box>
  </>);
}