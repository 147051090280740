import { Box, CircularProgress } from '@material-ui/core';
import {
  useParams, Redirect,
} from "react-router-dom";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { signIn } from '../redux/AccountRedux';
import { openOverlay as openOverlayPersonalSize, updatePanelIndex, updateCategoryIndex } from '../redux/UIOverlayPersonalSizeRedux';
import { closeOverlayAndReset, openOverlay as openOverlayEditView, setValues } from '../redux/UIOverlayMeasurementEditViewRedux';
import { CartStateItem } from 'common-ts/dist/models/Cart';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { fetchUserItemQRSize } from '../redux/MeasurementsRedux';
import { itemCategoriesClothingType, itemCategoriesFitType } from './OverlayViewPersonalSize';
import { sendWebsiteLivestreamMessage } from '../telegrambotevents';

export default function PageCreatePersonalSize() {
  let { userItemQRSizeId } = useParams<{ userItemQRSizeId: string }>();
  const userIsSignedInAndHasPurchased = useAppSelector(state => state.account.authState === 'signedin' && state.ordersList.paidOrderCount >= 1);
  const userAcc = useAppSelector(state => state.account);
  const isSignedIn = useAppSelector(state => state.account.authState === 'signedin');
  const dispatch = useAppDispatch();

  const currentUserItemQRSizeId = useAppSelector(state => state.userMeasurementsList.userItemQRSizeId);
  const currentUserItemQRSize = useAppSelector(state => state.userMeasurementsList.userItemQRSize); // userMeasurementsForItem
  const currentUserItemQRCartProductItem: CartStateItem = useAppSelector( state => state.userMeasurementsList.userItemQRCartProductItem); // cartProductItem
  const fetchUserItemQRSizeStatus = useAppSelector(state => state.userMeasurementsList.fetchUserItemQRSizeStatus);

  const orderId = userItemQRSizeId.substring(0, 50);
  const userSubAndItemNumber = userItemQRSizeId.substring(25);
  const userSub = userSubAndItemNumber.substring(0, userSubAndItemNumber.length - 5);
  const itemNumber = Number(userSubAndItemNumber.slice(-4));

  // 01. Auto sign in on non-trivial userSub
  useEffect(() => {

    // START HOTJAR RECORDING
    (window as any).hj('event', 'start_hotjar_rec');
    console.log("START-HOTJAR-REC");
    sendWebsiteLivestreamMessage('[HOTJAR REC - UX] STARTED - MAKE SIZE FROM OTHER');

    if (userSub && (!isSignedIn || userAcc.sub !== userSub)) {
      dispatch(signIn({ password: '123', useEmail: true, otpValue: '', subTempPass: 'skfajsakjf', userSub: userSub}));
    }
  }, [userSub]);

  // 02. We wait until sign in is completed
  if (!isSignedIn) {
    return (
    <Box style={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress/>
    </Box>
    );
  }

  // 02b. Fetch user item QR size
  if (currentUserItemQRSizeId !== userItemQRSizeId && fetchUserItemQRSizeStatus !== APIRequestStatus.RequestInProgress) {
    dispatch(fetchUserItemQRSize(userItemQRSizeId));
  }

  // 03. If the user is signed in and has purchased AND the item size has been fetched, we open personal size overlay with kategori tab,
  // open the edit size flow, and redirect to shop page
  if (userIsSignedInAndHasPurchased && currentUserItemQRSizeId === userItemQRSizeId) {
    dispatch(updatePanelIndex(1)); // Set to UKURAN TIAP KATEGORI
    for (let i = 0; i < itemCategoriesClothingType.length; i++) {
      const clothingTypeCur = itemCategoriesClothingType[i];
      const fitTypeCur = itemCategoriesFitType[i];

      if (clothingTypeCur === currentUserItemQRCartProductItem.clothingType && fitTypeCur === currentUserItemQRCartProductItem.fitType) {
        dispatch(updateCategoryIndex(i));
        break;
      }
    }
    // dispatch(openOverlayPersonalSize());

    dispatch(closeOverlayAndReset());
    dispatch(setValues({
      overlayType: 'edit',
      clothingType: currentUserItemQRCartProductItem.clothingType,
      fitType: currentUserItemQRCartProductItem.fitType,
      isFullScreen: true,
      currentMeasurementObject: currentUserItemQRSize,
      origin: 'qr'
    }))
    dispatch(openOverlayEditView());

    return (<Redirect to={"/shop"} />);
  }

  // 04. If the user is signed in but has not purchased, we just return null / a blank page
  return (
    <Box style={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress/>
    </Box>
    );

}
