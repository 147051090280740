import { Box, Dialog, Divider, Fade, IconButton, Link, Slide } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { Mixpanel } from '../mixpanel';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { closeOverlayAndReset, updateCurrentScreen } from '../redux/UIOverlayMeasurementInstantSizeTeamViewRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import HorizontalBar from './OverlayViewGenerateMeasure0HorizontalProgressBar';
import VerticalBar from './OverlayViewGenerateMeasure0VerticalProgressBar';
import { FlowComponentMaster } from './OverlayViewMeasurementInstantSizeTeamBlocks';
import { Close } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { FitType, ClothingType } from 'common-ts/dist/models/UserMeasurements';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 3) + " !important") as any,
    },
    dialogContentContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start', 
    },
    dialogContentLeftContainer: {
      width: '46%',
      paddingLeft: '29%',
      [theme.breakpoints.only('xs')]: {
        paddingLeft: '10%',
        paddingTop: '20px',
        width: '80%',
        justifyContent: 'center',
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
    titleLogoContainer: {
      width: "100%",
      position: "relative",
      paddingTop: '2.5vw',
      paddingBottom: '6vw',
      height: '5vw',
      [theme.breakpoints.only('xs')]: {
        paddingTop: '5vw',
        paddingBottom: '7vw',
        height: '10vw',
      },
      cursor: 'pointer',
    },
    titleLogoDesktop: {
      paddingTop: '1.5vw',
      height: '5vw',
      [theme.breakpoints.only('xs')]: {
        paddingTop: '3vw',
        height: '10vw',
      }
    },
    titleLogoClose: {
      position: "absolute",
      top: "3vw",
      right: "3vw",
      color: "#000000",
    },
    signInsignUpToggleText: {
      fontSize: '15px',
      [theme.breakpoints.only('xs')]: {
        fontSize: '14px',
      }

    },
    horizontalBarDivider: {
      width: '100%',
      margin: '10px 0px 6px 0px',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      }
    },
    horizontalContent: {
      width: '100%',
    }
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayView() {
  const overlayView = useAppSelector(state => state.overlayMeasurementInstantSizeTeamView);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [childrenVisible, setChildrenVisible] = React.useState<boolean>(true);
  const [titleNumber, setTitleNumber] = React.useState<number>(-1);

  React.useEffect(() => {
    const unblockFunction = history.block((() => {
      if (overlayView.status === UIOverlayViewStatus.On) {
        return false;
      }
      return true;
    }) as any);
  
    return function cleanup() {
      unblockFunction();
    };
  }, [overlayView.status]);

  function delayedDispatch(currentScreen: string) {
    setChildrenVisible(false);
    setTimeout(() => {
      dispatch(updateCurrentScreen(currentScreen));
      setChildrenVisible(true);
    }, 1000)
  }

  function closeOverlayAndResetUserMeasurementDraft() {
    dispatch(closeOverlayAndReset());
  }

  let content: JSX.Element = 
  FlowComponentMaster({
    clothingType: overlayView.productDetail?.clothingType || ClothingType.KemejaPendek,
    fitType: overlayView.productDetail?.defaultFitType || FitType.RegularFit,
    productDetail: overlayView.productDetail || undefined
  }, {
    currentScreen: overlayView.currentScreen,
    titleNumber: titleNumber,
    selectedRegularSize: overlayView.selectedRegularSize,
    height: overlayView.height,
    weight: overlayView.weight
  }, {
    delayedDispatch: delayedDispatch,
    setTitleNumber: (titleNumber) => { setTitleNumber(titleNumber); },
  });

  return (
    <Dialog
    fullScreen
    maxWidth="lg"
    open={overlayView.status === UIOverlayViewStatus.On}
    TransitionComponent={Transition}
    keepMounted
    onClose={closeOverlayAndResetUserMeasurementDraft}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    className={classes.dialogContainer}>
      <div className={classes.dialogContentContainer}>
        <Box className={classes.titleLogoContainer} >
          <Link onClick={closeOverlayAndResetUserMeasurementDraft}>
            <img className={classes.titleLogoDesktop} src={'https://studiosclo.uk/legacy/CreateSizeOverlay_StudioSLogo.svg'} alt="maja logo"/>
          </Link>
          <IconButton aria-label="closebutton" className={classes.titleLogoClose} 
          onClick={closeOverlayAndResetUserMeasurementDraft}>
            <Close />
          </IconButton>
        </Box>
        <div className={classes.dialogContentLeftContainer}>
          <Box className={classes.horizontalContent}>
            <Fade in={overlayView.status === UIOverlayViewStatus.On && childrenVisible} timeout={800}>
              {content}
            </Fade>
          </Box>
        </div>
      </div>
    </Dialog>
  );
}