import { Box } from '@material-ui/core';
import { Mixpanel } from '../mixpanel';
import { SizeType } from 'common-ts/dist/models/Cart';
import { BodyMeasCompleteForClothingType, BodyMeasurementDetailList, BodyMeasurements, ClothingType, CoreBodyMeasurements, ExtractBodyMeasurementsFromClothBodyMeasurements, FitType, UserBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { fetchUserMeasurementEstimatesBody, inputBodyMeasurementsForm, postBodyMeasurement } from '../redux/MeasurementsRedux';
import { closeOverlayAndResetWithCleanup } from '../redux/UIOverlayUniversalSizeRedux';
import { useAppDispatch } from '../reduxhooks';
import PersonalSizeBenefitStart from './OverlayViewUniversalSize0BenefitStart';
import PersonalSizeSizeSelect from './OverlayViewUniversalSize1SizeSelect';
import PersonalSizeSizeAdjust from './OverlayViewUniversalSize2SizeAdjust';
import PersonalSizeResultLoading from './OverlayViewUniversalSize3ResultLoading';
import PersonalSizeResult from './OverlayViewUniversalSize4Result';
import PersonalSizeBenefitEnd from './OverlayViewUniversalSize5BenefitEnd';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import { UniversalSizeActiveScreen } from '../redux/UIOverlayUniversalSizeRedux';
import { closeOverlayAndReset as closeOverlayAndResetUnivSizeCreateView, setValues as setValuesUnivSizeCreateView, openOverlay as openOverlayUnivSizeCreateView } from '../redux/UIOverlayUniversalSizeCreateMeasRedux';
import React from 'react';
import ProductOnUser from 'common-ts/dist/models/Product';

// Determines which flow is used based on starting parameters
export interface FlowSelectionData {
  isSignedIn: boolean
}

// State data for flow
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateData {
  currentScreen: UniversalSizeActiveScreen
  titleNumber: number
  currentUserBodyMeasurements: UserBodyMeasurements
  userSub: string
  currentFlowData: any
  productDetail: ProductOnUser | undefined,
}

// Callback to change state data for flow. 
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateCallback {
  delayedDispatch: (currentScreen: UniversalSizeActiveScreen) => void
  setTitleNumber: (titleNumber: number) => void
  setCurrentFlowData: (updatedCurrentFlowData: any) => void
}

//
// RENDERER 
// Master function that is used to select and pass data to the block 
// selected using the flow selection data.
//
export function FlowComponentMaster(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  
  let children: JSX.Element[] = [];
  children = FlowComponentMensTops(flowSelectionData, flowStateData, flowStateCallback);
  
  // We choose the right block to use with the flow selection data.
  return (
    <Box>
      {
        children
      }
    </Box>
  );
}

function FlowComponentMensTops(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  const dispatch = useAppDispatch();

  let titleNumber = -1;

  let delayedDispatch = flowStateCallback.delayedDispatch;

  let children: JSX.Element[] = [];

  switch (flowStateData.currentScreen) {
    case UniversalSizeActiveScreen.BenefitStart:
      children.push(<PersonalSizeBenefitStart
        handleBack={() => {
          dispatch(closeOverlayAndResetWithCleanup());
          sendWebsiteEventMessage("[PERSONAL FIT] 00. Benefit start - BACK & CLOSE");
        }}
        handleNext={() => {
          delayedDispatch(UniversalSizeActiveScreen.SizeSelect);
          flowStateCallback.setCurrentFlowData({});
          sendWebsiteEventMessage("[PERSONAL FIT] 00. Benefit start - COMPLETE");
        }}
      />);
      break;
    case UniversalSizeActiveScreen.SizeSelect:
      children.push(<PersonalSizeSizeSelect
        handleBack={() => {
          dispatch(closeOverlayAndResetWithCleanup());
          sendWebsiteEventMessage("[PERSONAL FIT] 01. Ukuran pilih - BACK & CLOSE");
        }}
        handleNext={(updatedCurrentFlowData: any) => {
          delayedDispatch(UniversalSizeActiveScreen.SizeAdjust);
          flowStateCallback.setCurrentFlowData({
            ...flowStateData.currentFlowData,
            ...updatedCurrentFlowData,
          });
          sendWebsiteEventMessage("[PERSONAL FIT] 01. Ukuran pilih - COMPLETE");
        }}
      />);
      break;
    case UniversalSizeActiveScreen.SizeAdjust:
      children.push(<PersonalSizeSizeAdjust
        currentFlowData={flowStateData.currentFlowData}
        handleBack={() => {
          delayedDispatch(UniversalSizeActiveScreen.SizeSelect);
          sendWebsiteEventMessage("[PERSONAL FIT] 02. Ukuran adjust - BACK");
        }}
        handleNext={(updatedCurrentFlowData: any) => {
          delayedDispatch(UniversalSizeActiveScreen.ResultLoading);
          flowStateCallback.setCurrentFlowData({
            ...flowStateData.currentFlowData,
            ...updatedCurrentFlowData,
            bodyMeasValue: ExtractBodyMeasurementsFromClothBodyMeasurements(updatedCurrentFlowData.clothMeasValue)
          });
          sendWebsiteEventMessage("[PERSONAL FIT] 02. Ukuran adjust - COMPLETE");
        }}
      />);
      break;
    case UniversalSizeActiveScreen.ResultLoading:
      children.push(<PersonalSizeResultLoading
        onFetchMeasEstimateSuccess={() => {
          delayedDispatch(UniversalSizeActiveScreen.Result);
          sendWebsiteEventMessage("[PERSONAL FIT] 03. Result loading - COMPLETE");
        }}/>);
      break;
    case UniversalSizeActiveScreen.Result:
    children.push(<PersonalSizeResult
      name={flowStateData.currentUserBodyMeasurements?.nama ? flowStateData.currentUserBodyMeasurements.nama : 'User'}
      bodyMeasurements={flowStateData.currentFlowData?.bodyMeasValue ?? {}}
      handleBack={() => {
        delayedDispatch(UniversalSizeActiveScreen.SizeSelect);
        sendWebsiteEventMessage("[PERSONAL FIT] 03. Result shown - BACK");
      }}
      handleSubmit={() => {   
        const userMeasurements: UserBodyMeasurements = {
          "adjustedMeasurements": flowStateData.currentFlowData.bodyMeasValue,
          "bodyOrClothingMeasurements": "BODY",
          "bodyType": "rectangle",
          "creationMethod": "online-body",
          "generatedMeasurements": {
          },
          "generatedMeasurementsRange": {
          },
          "generatedMeasurementsSafe": {
          },
          "height": 175,
          "sizeType": SizeType.personal,
          "userSelfInputMeasurements": {
          },
          "weight": 75
        };
        dispatch(inputBodyMeasurementsForm(userMeasurements));
        
        dispatch(postBodyMeasurement(flowStateData.productDetail?.clothingType ? {
          clothingType: flowStateData.productDetail?.clothingType as ClothingType,
          fitType: flowStateData.productDetail?.defaultFitType as FitType,
          productDetail: flowStateData.productDetail,
        } : ''));

        dispatch(closeOverlayAndResetWithCleanup());
        // delayedDispatch(UniversalSizeActiveScreen.BenefitEnd);
      }}
    />);
      break;
    // case UniversalSizeActiveScreen.BenefitEnd:
    //   children.push(<PersonalSizeBenefitEnd
    //     name={flowStateData.currentUserBodyMeasurements.nama ? flowStateData.currentUserBodyMeasurements.nama : 'User'}
    //     bodyMeasurements={currentFlowData?.clothMeasValue ?? {}}
    //     handleBack={() => {
    //       dispatch(closeOverlayAndResetWithCleanup());
    //       sendWebsiteEventMessage("[PERSONAL FIT] 00. Benefit start - BACK & CLOSE");
    //     }}
    //     handleSubmit={() => {
    //       setCurrentFlowData({});
    //       dispatch(closeOverlayAndResetWithCleanup());
    //       sendWebsiteEventMessage("[PERSONAL FIT] 00. Benefit start - COMPLETE");
    //     }}
    //   />);
    //   break;
  }

  if (flowStateData.titleNumber != titleNumber) {
    flowStateCallback.setTitleNumber(titleNumber);
  }

  return children;
}