import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import React from 'react';
import { SizeType } from 'common-ts/dist/models/Cart';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import OverlayComponentBaseContainerWidth from './OverlayComponentBaseContainerWidth';
import OverlayComponentButton from './OverlayComponentButton';
import OverlayComponentTwoChoices from './OverlayComponentChoiceTwo';
import OverlayComponentForm from './OverlayComponentForm';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  optionGridItemSpacer: {
    minHeight: '80px',
    margin: '0px 16px 20px 0px',
  },
  optionGridItemContainer: {
    '-webkit-tap-highlight-color': 'transparent',
    border: '1px solid #CBCBCB',
    borderRadius: '40px',
    minHeight: '165px',
    padding: '30px 30px 40px 40px',
    cursor: 'pointer',
  },
  optionBox: {
    marginTop: '6px',
    minHeight: '14px',
    width: '14px',
    backgroundColor: '#E4E4E4',
  },
  optionBoxSelected: {
    marginTop: '6px',
    minHeight: '14px',
    width: '14px',
    backgroundColor: '#232323',
  },
  optionHeader: {
    width: 'fit-content',
    borderRadius: '50px',
    fontFamily: 'OakesGrotesk',
    fontWeight: 500,
    backgroundColor: '#232323',
    color: '#ffffff',
    marginBottom: '10px',
    fontSize: '6.5px',
    letterSpacing: '0.9px',
    padding: '5px 9px 2px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.7vw',
      letterSpacing: '0.17vw',
      padding: '1.3vw 2.4vw 0.8vw',
    },
  },
  optionTitle: {
    fontFamily: 'OakesGrotesk',
    fontWeight: 500,
    color: '#343434',
    fontSize: '14px',
    marginBottom: '6px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '4.3vw',
    },
  },
  optionSubtitle: {
    fontFamily: 'Raleway',
    fontWeight: 300,
    color: '#454545',
    fontSize: '9px',
    lineHeight: '16px',
    marginBottom: '-12px',
    paddingRight: '16px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.3vw',
      lineHeight: '3.7vw',
      marginBottom: '3.2vw',
      letterSpacing: '0.1vw',
      paddingRight: '4.2vw',
    },
  },
  optionSubtitleHighlight: {
    fontFamily: 'Raleway',
    fontWeight: 500,
    color: '#000000',
    fontSize: '9px',
    lineHeight: '14px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2.3vw',
      lineHeight: '3.7vw',
    },
  },
  optionSubtitleUnderline: {
    fontFamily: 'Raleway',
    fontWeight: 500,
    color: '#000000',
    fontSize: '11px',
    lineHeight: '18px',
  },
  optionSubtitleGratis: {
    fontFamily: 'Raleway',
    fontWeight: 500,
    color: '#FF821C',
    fontSize: '11px',
    marginTop: '10px',
    lineHeight: '18px',
  },
  title: {
    fontFamily: 'OakesGrotesk',
    fontWeight: 500,
    color: '#121212',
    fontSize: '34px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '27px',
    },
    marginBottom: '10px',
  },
  titleEmoji: {
    fontFamily: 'OakesGrotesk',
    fontWeight: 500,
    color: '#121212',
    fontSize: '32px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '28px',
    },
    marginBottom: '18px',
    marginLeft: '2px',
  },
  subtitle: {
    fontFamily: 'Raleway',
    fontWeight: 300,
    color: '#000000',
    fontSize: '13.71px',
    lineHeight: '20.57px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '11px',
    },
    marginBottom: '42px',
  }
}),
);

interface Props {
  handleSubmit: (choosePersonalSize: boolean) => void
  isMinimized?: boolean
}

export default function Page({ handleSubmit, isMinimized }: Props) {
  const theme = useTheme();
  const classes = useStyles();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [selected, setSelected] = React.useState<"dibuatkan" | "buatsendiri" | undefined>(undefined);

  function handleSubmitFunction(e: any) {
    e.preventDefault();
    handleSubmit(selected === "dibuatkan");
  }

  let children: JSX.Element[] = [];
  let submitChildren: JSX.Element[] = [];

  children.push(
    <OverlayComponentBaseContainerHorizontal>
      <OverlayComponentBaseContainerWidth
        widthMdUp="100%"
        widthSmDown="100%">
          {
            isMinimized ? (
              <div style={{
                paddingLeft: isMdOrUp ? '42.5px' : '10vw',
              }}>
                <Typography className={classes.title}
                  style={{
                    fontWeight: 600,
                    fontSize: '16.5px',
                    [theme.breakpoints.only('xs')]: {
                      fontSize: '12.5px',
                    },
                  }}
                >
                Buat Ukuran, <u>Tanpa Perlu</u> Ukur Badan
                </Typography>
                <Typography className={classes.subtitle} style={{marginBottom: '22px', lineHeight: '15px'}}>
                  Buat sendiri atau dibuatkan expert, <br></br>
                  dua2 nya cuma <u>5 menit</u>.
                </Typography>
              </div>
            ) : (
              <div>
                <Typography style={{
                  fontFamily: 'Raleway',
                  fontWeight: 300,
                  color: '#000000',
                  fontSize: '11px',
                  lineHeight: '20.57px',
                  marginBottom: '12px',
                }}>
                  UKURAN CUSTOM
                </Typography>
                <Typography className={classes.title}>
                  Pilih Cara Buat
                </Typography>
                <Typography className={classes.subtitle}>
                  Tim expert kami bisa membuatkan ukuran custom, <br></br>
                  atau kamu bisa buat size kamu sendiri.
                </Typography>
              </div>
            )
          }
        
        <Box style={{
          width: isMdOrUp ? "380px" : '100vw',
          height: isMdOrUp ? "630px" : '111vw',
          // marginLeft: '-10%',
          position: 'relative',
        }}>
          <Grid container style={{
            left: '0vw',
            width: isMdOrUp ? "185px" : '47vw',
            height: isMdOrUp ? "405px" :'108vw',
            position: 'absolute',
            borderRadius: isMdOrUp ? '0px 22.5px 22.5px 0px' : '0vw 6vw 6vw 0vw',
            boxShadow: 'rgba(0, 0, 0, 0.20) 0px 0px 11px 1px',
            opacity: selected === "buatsendiri" ? 0.35 : 1,
          }} onClick={() => {
            handleSubmit(true)
            // setSelected("dibuatkan");
          }}>
            <Grid item xs={12} style={{
              height: isMdOrUp ? "255px" : '70vw',
            }}>
              <img src={'https://studiosclo.uk/general/cara-buat-sizeteam.jpg'} style={{
                width: '100%',
                borderRadius: isMdOrUp ? '0px 22.5px 0px 0px' :'0vw 6vw 0vw 0vw',
              }}></img>
            </Grid>
            <Grid item xs={12} style={{
              paddingLeft: isMdOrUp ? '11.5px' : '3vw',
              textAlign: 'left',
            }}>
              <Typography className={classes.optionHeader}>
                DIBUATKAN EXPERT
              </Typography>
              <Typography className={classes.optionTitle}>
                Instant Size Team<sup>TM</sup>
              </Typography>
              <Typography className={classes.optionSubtitle}>
                Tim expert kami siap bantu 24 jam.
                Chat sekarang 5 menit, dan ukuran hasil 
                <span className={classes.optionSubtitleHighlight}> langsung bisa dipakai</span> checkout.
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{
            right: '0vw',
            width: isMdOrUp ? "185px" : '47vw',
            height: isMdOrUp ? "405px" :'108vw',
            position: 'absolute',
            borderRadius: isMdOrUp ? '22.5px 0px 0px 22.5px' : '6vw 0vw 0vw 6vw',
            boxShadow: 'rgba(0, 0, 0, 0.20) 0px 0px 11px 1px',
            opacity: selected === "dibuatkan" ? 0.35 : 1,
          }} onClick={() => {
            handleSubmit(false)
            // setSelected("buatsendiri");
          }}>
            <Grid item xs={12} style={{
              height: isMdOrUp ? "255px" : '70vw',
            }}>
              <img src={'https://studiosclo.uk/general/cara-buat-sizecreate-2.jpg'} style={{
                width: '100%',
                borderRadius: isMdOrUp ? '22.5px 0px 0px 0px' : '6vw 0vw 0vw 0vw',
              }}></img>
            </Grid>
            <Grid item xs={12} style={{
              paddingLeft: isMdOrUp ? '16.5px' : '4.3vw',
            }}>
              <Typography className={classes.optionHeader}>
                BUAT SIZE SENDIRI
              </Typography>
              <Typography className={classes.optionTitle}>
                Web Size Creator
              </Typography>
              <Typography className={classes.optionSubtitle}>
                Pakai web tool kami untuk membuat
                size kamu sendiri, <span className={classes.optionSubtitleHighlight}>tanpa perlu ukur
                dan dalam waktu 5 menit.</span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </OverlayComponentBaseContainerWidth>
    </OverlayComponentBaseContainerHorizontal>
  );
  submitChildren.push(
    <OverlayComponentBaseContainerHorizontal
    alignItems='flex-end' >
      <Box>
        {
          selected !== undefined ? 
          <OverlayComponentButton
            formButtonText="PILIH"
            isTypeSubmit={true}
            buttonMarginTopBottom="10px"
            buttonMarginLeftRight="0px"/> :
          null
        }
      </Box>
    </OverlayComponentBaseContainerHorizontal>
  )

  return (
    <OverlayComponentForm
      formTitle=""
      formSubmitBody={submitChildren}
      formFormBody={children}
      formHandleSubmit={handleSubmitFunction}
      titleWidthMdUp='380px'
      titleWidthSmDown='100%'
      formWidthMdUp='380px'
      formWidthSmDown='100%'
      formJustifyContent='space-evenly'
      formAlignItems='center'
    />
  );
}