import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, Link } from '@material-ui/core';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { ProductOnUser } from 'common-ts/dist/models/Product';
import { Link as RouterLink } from "react-router-dom";
import React from 'react';
import { useEffect } from 'react';
import PreloadImage from './ImagePreloader';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactHtmlParser from 'react-html-parser';
import { Mixpanel } from '../mixpanel';
import PageElementContainer from './PageComponentElementContainer';
import PageElementSvg from './PageComponentElementSvg';
import PageElementContainerList from './PageComponentElementContainerList';
import PageElementProduct, { MultipleImgCarsl } from './PageComponentElementProduct';
import PageItemBreakpoint from './PageComponentElementItemBreakpointSize';
import PageElementCarousel from './PageComponentElementCarousel';
import PageElementInstantSizeTeam from './PageComponentElementInstantSizeTeam';

// This file includes the "block templates" for presentation.
// It does not code its own css and only functions as a compiler
// and an "example / template" builder for the API / components.
// It functions as a catalogue, and each code of UI block element
// from the backend corresponds to functions in this file.

// ALL THE BLOCK TEMPLATES HAS *NO STATE*, THEY ARE FULLY 
// MADE USING *PROPS* AND *REDUX STATE VARIABLES*.

interface BlockProps {
  elementContentJson: any
}

/*
GENERATOR CODE. Use in Chrome browser to generate string.
a = (JSON.stringify({
  svgUrl: '.com',
})) 
*/
export interface BlockSvgBannerData {
  svgUrl: string
  linkTo?: string
}

export function BlockSvgBanner({elementContentJson} : BlockProps) {
  let elementContent: BlockSvgBannerData = elementContentJson;

  return (
    <PageElementContainer
      margin={"0px"}
      content={[<PageElementSvg svg_url={elementContent.svgUrl} 
        linkTo={elementContent.linkTo ? elementContent.linkTo : undefined}/>]}
    />
  );
}

export interface BlockInstantSizeTeamBannerData {
  svgUrl: string
}

export function BlockInstantSizeTeamBanner({elementContentJson} : BlockProps) {
  let elementContent: BlockInstantSizeTeamBannerData = elementContentJson;

  return (
    <PageElementContainer
      margin={"-20px 0px 60px 0px"}
      content={[<PageElementInstantSizeTeam svg_url={elementContent.svgUrl} />]}
    />
  );
}

/*
GENERATOR CODE. Use in Chrome browser to generate string.
a = (JSON.stringify({
  image_urls: ['.com', '.com'],
})) 
*/
export interface CarouselItemData {
  contentUrl: string
  contentType: "image" | "video-no-sound" | "video-with-sound"
  videoPoster?: string
  linkTo?: string
}
export interface BlockCarouselData {
  carouselItemDataArray: CarouselItemData[]
  linkTo?: string
}

export function BlockCarousel({elementContentJson} : BlockProps) {
  let elementContent: BlockCarouselData = elementContentJson;

  return (
    <PageElementContainer
      margin={"0px"}
      content={[<PageElementCarousel 
        carouselItemDataArray={elementContent.carouselItemDataArray} 
        linkTo={elementContent.linkTo ? elementContent.linkTo : undefined}
      />]}
    />
  );
}

/*
GENERATOR CODE. Use in Chrome browser to generate string.
a = (JSON.stringify({
  productOneId: 'asd',
  productTwoId: 'fads',

  containerWidthXs: 'dfsaaf',
  containerWidthSm: 'fasdsf',
  containerWidthMd: '100%',
  containerWidthLg: '100%',
  productWidthXs: '100%',
  productWidthSm: '100%',
  productWidthMd: '100%',
  productWidthLg: '100%',

  containerMargin: '0px',
  productMargin: '0px',
  productPadding: '0px',
})) 
*/
export interface BlockTwoProductsCenterData {
  productOneId: string
  productOneImageUrl?: string
  productOneImageHoverUrl?: string
  productOneAdditionalImage?: string
  productTwoId: string
  productTwoImageUrl?: string
  productTwoImageHoverUrl?: string
  productTwoAdditionalImage?: string

  containerWidthXs: string
  containerWidthSm: string
  containerWidthMd: string
  containerWidthLg: string
  productWidthXs: string
  productWidthSm: string
  productWidthMd: string
  productWidthLg: string

  containerMargin?: string
  productMargin?: string
  productPadding?: string
}

export function BlockTwoProductsCenter({elementContentJson} : BlockProps) {
  let elementContent: BlockTwoProductsCenterData = elementContentJson;

  let products = useAppSelector(state => state.productsList.productsDictionary);
  let productOne: ProductOnUser = products[elementContent.productOneId];
  let productTwo: ProductOnUser = products[elementContent.productTwoId];

  const productSizeAvailabilityDict = useAppSelector(state => state.productsList.productSizeAvailabilityDict);
  const numberOfItemsRemainingOne = productSizeAvailabilityDict?.[productOne.productId]?.numberOfItemsRemaining;
  const isSoldOutOne = productOne.isSoldOut || numberOfItemsRemainingOne === 0;
  const numberOfItemsRemainingTwo = productSizeAvailabilityDict?.[productTwo.productId]?.numberOfItemsRemaining;
  const isSoldOutTwo = productTwo.isSoldOut || numberOfItemsRemainingTwo === 0;

  if (isSoldOutOne && isSoldOutTwo) {
    return null;
  }

  return (
    <PageElementContainer
      widthXs={elementContent.containerWidthXs}
      widthSm={elementContent.containerWidthSm}
      widthMd={elementContent.containerWidthMd}
      widthLg={elementContent.containerWidthLg}
      margin={elementContent.containerMargin ? elementContent.containerMargin : undefined}
      content={[
        <PageElementContainerList
        justifyContent={'space-evenly'} // Products will be at very edge of inner container
        content={[
        <PageItemBreakpoint
          widthXs={elementContent.productWidthXs}
          widthSm={elementContent.productWidthSm}
          widthMd={elementContent.productWidthMd}
          widthLg={elementContent.productWidthLg}
          content={
            <PageElementProduct 
              product_id={productOne.productId}
              image_url={elementContent.productOneImageUrl ? elementContent.productOneImageUrl : productOne.displayImage} 
              image_url_hover={elementContent.productOneImageHoverUrl ? elementContent.productOneImageHoverUrl : productOne.displayImageHover} 
              title={productOne.name}
              discountedPrice={productOne.discountedPrice}
              originalPrice={productOne.originalPrice}
              isSoldOut={productOne.isSoldOut}
              isComingAgainSoon={productOne.isComingAgainSoon}
              product_url={`/shop/product/` + productOne.productId} 
              margin={elementContent.productMargin ? elementContent.productMargin : undefined}
              padding={elementContent.productPadding ? elementContent.productPadding : undefined}
              marketplaceLinkData={productOne.marketplaceLinkData}
              image_additional_url={elementContent.productOneAdditionalImage}
            />
          }
          />,
        <PageItemBreakpoint
          widthXs={elementContent.productWidthXs}
          widthSm={elementContent.productWidthSm}
          widthMd={elementContent.productWidthMd}
          widthLg={elementContent.productWidthLg}
          content={
            <PageElementProduct 
              product_id={productTwo.productId}
              image_url={elementContent.productTwoImageUrl ? elementContent.productTwoImageUrl : productTwo.displayImage} 
              image_url_hover={elementContent.productTwoImageHoverUrl ? elementContent.productTwoImageHoverUrl : productTwo.displayImageHover} 
              title={productTwo.name}
              discountedPrice={productTwo.discountedPrice}
              originalPrice={productTwo.originalPrice}
              isSoldOut={productTwo.isSoldOut}
              isComingAgainSoon={productTwo.isComingAgainSoon}
              product_url={`/shop/product/` + productTwo.productId} 
              margin={elementContent.productMargin ? elementContent.productMargin : undefined}
              padding={elementContent.productPadding ? elementContent.productPadding : undefined}
              marketplaceLinkData={productTwo.marketplaceLinkData}
              image_additional_url={elementContent.productTwoAdditionalImage}
            />
          }
          />,
        ]}/>
      ]}
    />
  );
}

/*
GENERATOR CODE. Use in Chrome browser to generate string.
a = (JSON.stringify({
  productOneId: 'asd',
  productTwoId: 'fads',
  productThreeId: 'fads',

  containerWidthXs: 'dfsaaf',
  containerWidthSm: 'fasdsf',
  containerWidthMd: '100%',
  containerWidthLg: '100%',
  productWidthXs: '100%',
  productWidthSm: '100%',
  productWidthMd: '100%',
  productWidthLg: '100%',

  containerMargin: '0px',
  productMargin: '0px',
  productPadding: '0px',
})) 
*/
export interface BlockThreeProductsCenterData {
  productOneId: string
  productOneImageUrl?: string
  productOneImageHoverUrl?: string
  productTwoId: string
  productTwoImageUrl?: string
  productTwoImageHoverUrl?: string
  productThreeId: string
  productThreeImageUrl?: string
  productThreeImageHoverUrl?: string

  containerWidthXs: string
  containerWidthSm: string
  containerWidthMd: string
  containerWidthLg: string
  productWidthXs: string
  productWidthSm: string
  productWidthMd: string
  productWidthLg: string

  containerMargin?: string
  productMargin?: string
  productPadding?: string
}

export function BlockThreeProductsCenter({elementContentJson} : BlockProps) {
  let elementContent: BlockThreeProductsCenterData = elementContentJson;

  let products = useAppSelector(state => state.productsList.productsDictionary);
  let productOne: ProductOnUser = products[elementContent.productOneId];
  let productTwo: ProductOnUser = products[elementContent.productTwoId];
  let productThree: ProductOnUser = products[elementContent.productThreeId];

  return (
    <PageElementContainer
      widthXs={elementContent.containerWidthXs}
      widthSm={elementContent.containerWidthSm}
      widthMd={elementContent.containerWidthMd}
      widthLg={elementContent.containerWidthLg}
      margin={elementContent.containerMargin ? elementContent.containerMargin : undefined}
      content={[
        <PageElementContainerList
        justifyContent={'space-between'} // Products will be at very edge of inner container
        content={[
        <PageItemBreakpoint
          widthXs={elementContent.productWidthXs}
          widthSm={elementContent.productWidthSm}
          widthMd={elementContent.productWidthMd}
          widthLg={elementContent.productWidthLg}
          content={
            <PageElementProduct 
              product_id={productOne.productId}
              image_url={elementContent.productOneImageUrl ? elementContent.productOneImageUrl : productOne.displayImage} 
              image_url_hover={elementContent.productOneImageHoverUrl ? elementContent.productOneImageHoverUrl : productOne.displayImageHover} 
              title={productOne.name}
              discountedPrice={productOne.discountedPrice}
              originalPrice={productOne.originalPrice}
              isSoldOut={productOne.isSoldOut}
              isComingAgainSoon={productOne.isComingAgainSoon}
              product_url={`/shop/product/` + productOne.productId} 
              margin={elementContent.productMargin ? elementContent.productMargin : undefined}
              padding={elementContent.productPadding ? elementContent.productPadding : undefined}
              marketplaceLinkData={productOne.marketplaceLinkData}
            />
          }
          />,
        <PageItemBreakpoint
          widthXs={elementContent.productWidthXs}
          widthSm={elementContent.productWidthSm}
          widthMd={elementContent.productWidthMd}
          widthLg={elementContent.productWidthLg}
          content={
            <PageElementProduct 
              product_id={productTwo.productId}
              image_url={elementContent.productTwoImageUrl ? elementContent.productTwoImageUrl : productTwo.displayImage} 
              image_url_hover={elementContent.productTwoImageHoverUrl ? elementContent.productTwoImageHoverUrl : productTwo.displayImageHover} 
              title={productTwo.name}
              discountedPrice={productTwo.discountedPrice}
              originalPrice={productTwo.originalPrice}
              isSoldOut={productTwo.isSoldOut}
              isComingAgainSoon={productTwo.isComingAgainSoon}
              product_url={`/shop/product/` + productTwo.productId} 
              margin={elementContent.productMargin ? elementContent.productMargin : undefined}
              padding={elementContent.productPadding ? elementContent.productPadding : undefined}
              marketplaceLinkData={productTwo.marketplaceLinkData}
            />
          }
          />,
        <PageItemBreakpoint
          widthXs={elementContent.productWidthXs}
          widthSm={elementContent.productWidthSm}
          widthMd={elementContent.productWidthMd}
          widthLg={elementContent.productWidthLg}
          content={
            <PageElementProduct 
              product_id={productThree.productId}
              image_url={elementContent.productThreeImageUrl ? elementContent.productThreeImageUrl : productThree.displayImage} 
              image_url_hover={elementContent.productThreeImageHoverUrl ? elementContent.productThreeImageHoverUrl : productThree.displayImageHover} 
              title={productThree.name}
              discountedPrice={productThree.discountedPrice}
              originalPrice={productThree.originalPrice}
              isSoldOut={productThree.isSoldOut}
              isComingAgainSoon={productThree.isComingAgainSoon}
              product_url={`/shop/product/` + productThree.productId} 
              margin={elementContent.productMargin ? elementContent.productMargin : undefined}
              padding={elementContent.productPadding ? elementContent.productPadding : undefined}
              marketplaceLinkData={productThree.marketplaceLinkData}
            />
          }
          />,
        ]}/>
      ]}
    />
  );
}

/*
GENERATOR CODE. Use in Chrome browser to generate string.
a = (JSON.stringify({
  productOneId: 'asd',
  productTwoId: 'fads',
  productThreeId: 'fads',
  productFourId: 'fads',

  containerWidthXs: 'dfsaaf',
  containerWidthSm: 'fasdsf',
  containerWidthMd: '100%',
  containerWidthLg: '100%',
  productWidthXs: '100%',
  productWidthSm: '100%',
  productWidthMd: '100%',
  productWidthLg: '100%',

  containerMargin: '0px',
  productMargin: '0px',
  productPadding: '0px',
})) 
*/
export interface BlockFourProductsCenterData {
  productOneId: string
  productOneImageUrl?: string
  productOneImageHoverUrl?: string
  productTwoId: string
  productTwoImageUrl?: string
  productTwoImageHoverUrl?: string
  productThreeId: string
  productThreeImageUrl?: string
  productThreeImageHoverUrl?: string
  productFourId: string
  productFourImageUrl?: string
  productFourImageHoverUrl?: string

  containerWidthXs: string
  containerWidthSm: string
  containerWidthMd: string
  containerWidthLg: string
  productWidthXs: string
  productWidthSm: string
  productWidthMd: string
  productWidthLg: string

  containerMargin?: string
  productMargin?: string
  productPadding?: string
}

export function BlockFourProductsCenter({elementContentJson} : BlockProps) {
  let elementContent: BlockFourProductsCenterData = elementContentJson;

  let products = useAppSelector(state => state.productsList.productsDictionary);
  let productOne: ProductOnUser = products[elementContent.productOneId];
  let productTwo: ProductOnUser = products[elementContent.productTwoId];
  let productThree: ProductOnUser = products[elementContent.productThreeId];
  let productFour: ProductOnUser = products[elementContent.productFourId];

  return (
    <PageElementContainer
      widthXs={elementContent.containerWidthXs}
      widthSm={elementContent.containerWidthSm}
      widthMd={elementContent.containerWidthMd}
      widthLg={elementContent.containerWidthLg}
      margin={elementContent.containerMargin ? elementContent.containerMargin : undefined}
      content={[
        <PageElementContainerList
        justifyContent={'space-between'} // Products will be at very edge of inner container
        content={[
        <PageItemBreakpoint
          widthXs={elementContent.productWidthXs}
          widthSm={elementContent.productWidthSm}
          widthMd={elementContent.productWidthMd}
          widthLg={elementContent.productWidthLg}
          content={
            <PageElementProduct 
              product_id={productOne.productId}
              image_url={elementContent.productOneImageUrl ? elementContent.productOneImageUrl : productOne.displayImage} 
              image_url_hover={elementContent.productOneImageHoverUrl ? elementContent.productOneImageHoverUrl : productOne.displayImageHover} 
              title={productOne.name}
              discountedPrice={productOne.discountedPrice}
              originalPrice={productOne.originalPrice}
              isSoldOut={productOne.isSoldOut}
              isComingAgainSoon={productOne.isComingAgainSoon}
              product_url={`/shop/product/` + productOne.productId} 
              margin={elementContent.productMargin ? elementContent.productMargin : undefined}
              padding={elementContent.productPadding ? elementContent.productPadding : undefined}
              marketplaceLinkData={productOne.marketplaceLinkData}
            />
          }
          />,
        <PageItemBreakpoint
          widthXs={elementContent.productWidthXs}
          widthSm={elementContent.productWidthSm}
          widthMd={elementContent.productWidthMd}
          widthLg={elementContent.productWidthLg}
          content={
            <PageElementProduct 
              product_id={productTwo.productId}
              image_url={elementContent.productTwoImageUrl ? elementContent.productTwoImageUrl : productTwo.displayImage} 
              image_url_hover={elementContent.productTwoImageHoverUrl ? elementContent.productTwoImageHoverUrl : productTwo.displayImageHover} 
              title={productTwo.name}
              discountedPrice={productTwo.discountedPrice}
              originalPrice={productTwo.originalPrice}
              isSoldOut={productTwo.isSoldOut}
              isComingAgainSoon={productTwo.isComingAgainSoon}
              product_url={`/shop/product/` + productTwo.productId} 
              margin={elementContent.productMargin ? elementContent.productMargin : undefined}
              padding={elementContent.productPadding ? elementContent.productPadding : undefined}
              marketplaceLinkData={productTwo.marketplaceLinkData}
            />
          }
          />,
        <PageItemBreakpoint
          widthXs={elementContent.productWidthXs}
          widthSm={elementContent.productWidthSm}
          widthMd={elementContent.productWidthMd}
          widthLg={elementContent.productWidthLg}
          content={
            <PageElementProduct 
              product_id={productThree.productId}
              image_url={elementContent.productThreeImageUrl ? elementContent.productThreeImageUrl : productThree.displayImage} 
              image_url_hover={elementContent.productThreeImageHoverUrl ? elementContent.productThreeImageHoverUrl : productThree.displayImageHover} 
              title={productThree.name}
              discountedPrice={productThree.discountedPrice}
              originalPrice={productThree.originalPrice}
              isSoldOut={productThree.isSoldOut}
              isComingAgainSoon={productThree.isComingAgainSoon}
              product_url={`/shop/product/` + productThree.productId} 
              margin={elementContent.productMargin ? elementContent.productMargin : undefined}
              padding={elementContent.productPadding ? elementContent.productPadding : undefined}
              marketplaceLinkData={productThree.marketplaceLinkData}
            />
          }
          />,
        <PageItemBreakpoint
          widthXs={elementContent.productWidthXs}
          widthSm={elementContent.productWidthSm}
          widthMd={elementContent.productWidthMd}
          widthLg={elementContent.productWidthLg}
          content={
            <PageElementProduct 
              product_id={productFour.productId}
              image_url={elementContent.productFourImageUrl ? elementContent.productFourImageUrl : productFour.displayImage} 
              image_url_hover={elementContent.productFourImageHoverUrl ? elementContent.productFourImageHoverUrl : productFour.displayImageHover} 
              title={productFour.name}
              discountedPrice={productFour.discountedPrice}
              originalPrice={productFour.originalPrice}
              isSoldOut={productFour.isSoldOut}
              isComingAgainSoon={productFour.isComingAgainSoon}
              product_url={`/shop/product/` + productFour.productId} 
              margin={elementContent.productMargin ? elementContent.productMargin : undefined}
              padding={elementContent.productPadding ? elementContent.productPadding : undefined}
              marketplaceLinkData={productFour.marketplaceLinkData}
            />
          }
          />,
        ]}/>
      ]}
    />
  );
}

/*
GENERATOR CODE. Use in Chrome browser to generate string.
a = (JSON.stringify({
  productIds: ['asd','bcd'],

  containerWidthXs: 'dfsaaf',
  containerWidthSm: 'fasdsf',
  containerWidthMd: '100%',
  containerWidthLg: '100%',
  productWidthXs: '100%',
  productWidthSm: '100%',
  productWidthMd: '100%',
  productWidthLg: '100%',

  containerMargin: '0px',
  productMargin: '0px',
  productPadding: '0px',
})) 
*/
export interface BlockProductArrayCenterData {
  productIds: string[]

  containerWidthXs: string
  containerWidthSm: string
  containerWidthMd: string
  containerWidthLg: string
  productWidthXs: string
  productWidthSm: string
  productWidthMd: string
  productWidthLg: string

  containerMargin?: string
  productMargin?: string
  productPadding?: string
}

export function BlockProductArrayCenter({elementContentJson} : BlockProps) {
  let elementContent: BlockProductArrayCenterData = elementContentJson;

  let products = useAppSelector(state => state.productsList.productsDictionary);

  let contentArray: JSX.Element[] = [];
  for (let i = 0; i < elementContent.productIds.length; i++) {
    let product = products[elementContent.productIds[i]];
    contentArray.push(
      <PageItemBreakpoint
      widthXs={elementContent.productWidthXs}
      widthSm={elementContent.productWidthSm}
      widthMd={elementContent.productWidthMd}
      widthLg={elementContent.productWidthLg}
      content={
        <PageElementProduct 
          product_id={product.productId}
          image_url={product.displayImage} 
          image_url_hover={product.displayImageHover} 
          title={product.name}
          discountedPrice={product.discountedPrice}
          originalPrice={product.originalPrice}
          isSoldOut={product.isSoldOut}
          isComingAgainSoon={product.isComingAgainSoon}
          product_url={`/shop/product/` + product.productId} 
          margin={elementContent.productMargin ? elementContent.productMargin : undefined}
          padding={elementContent.productPadding ? elementContent.productPadding : undefined}
          marketplaceLinkData={product.marketplaceLinkData}
        />
      }
      />
    );
  }

  return (
    <PageElementContainer
      widthXs={elementContent.containerWidthXs}
      widthSm={elementContent.containerWidthSm}
      widthMd={elementContent.containerWidthMd}
      widthLg={elementContent.containerWidthLg}
      margin={elementContent.containerMargin ? elementContent.containerMargin : undefined}
      content={[<PageElementContainerList
        justifyContent='space-between'
        content={contentArray}/>]}/>
  );
}

/*
GENERATOR CODE. Use in Chrome browser to generate string.
a = (JSON.stringify({
  productOneId: 'asd',

  containerWidthXs: 'dfsaaf',
  containerWidthSm: 'fasdsf',
  containerWidthMd: '100%',
  containerWidthLg: '100%',
  productWidthXs: '100%',
  productWidthSm: '100%',
  productWidthMd: '100%',
  productWidthLg: '100%',

  containerMargin: '0px',
  productMargin: '0px',
  productPadding: '0px',
})) 
*/
export interface BlockOneProductCenterData {
  productOneId: string
  productOneImageUrl?: string
  productOneImageHoverUrl?: string
  productOneAdditionalImage?: string

  productWidthXs: string
  productWidthSm: string
  productWidthMd: string
  productWidthLg: string

  containerMargin?: string
  productMargin?: string
  productPadding?: string
}

export function BlockOneProductCenter({elementContentJson} : BlockProps) {
  let elementContent: BlockOneProductCenterData = elementContentJson;

  let products = useAppSelector(state => state.productsList.productsDictionary);
  let productOne: ProductOnUser = products[elementContent.productOneId];

  const productSizeAvailabilityDict = useAppSelector(state => state.productsList.productSizeAvailabilityDict);
  const numberOfItemsRemainingOne = productSizeAvailabilityDict?.[productOne.productId]?.numberOfItemsRemaining;
  const isSoldOutOne = productOne.isSoldOut || numberOfItemsRemainingOne === 0;

  if (isSoldOutOne) {
    return null;
  }

  return (
    <PageElementContainer
      margin={elementContent.containerMargin ? elementContent.containerMargin : undefined}
      content={[
        <PageItemBreakpoint
          widthXs={elementContent.productWidthXs}
          widthSm={elementContent.productWidthSm}
          widthMd={elementContent.productWidthMd}
          widthLg={elementContent.productWidthLg}
          content={
            <PageElementProduct 
              product_id={productOne.productId}
              image_url={elementContent.productOneImageUrl ? elementContent.productOneImageUrl : productOne.displayImage} 
              image_url_hover={elementContent.productOneImageHoverUrl ? elementContent.productOneImageHoverUrl : productOne.displayImageHover} 
              title={productOne.name}
              discountedPrice={productOne.discountedPrice}
              originalPrice={productOne.originalPrice}
              isSoldOut={productOne.isSoldOut}
              isComingAgainSoon={productOne.isComingAgainSoon}
              product_url={`/shop/product/` + productOne.productId} 
              margin={elementContent.productMargin ? elementContent.productMargin : undefined}
              padding={elementContent.productPadding ? elementContent.productPadding : undefined}
              marketplaceLinkData={productOne.marketplaceLinkData}
              image_additional_url={elementContent.productOneAdditionalImage}
            />
          }
          />
        ]}
    />
  );
}

//
// RENDERER
// This is the core renderer function to get a JSX element from
// a code and content json.
//
export interface RenderBlockElementProps {
  code: string
  contentJson: any
}

export function renderBlockElement({code, contentJson}: RenderBlockElementProps) {
  var contentJsonParsed;
  if (typeof contentJson === 'string' || contentJson instanceof String) {
    contentJsonParsed = JSON.parse(contentJson.toString());
  } else {
    contentJsonParsed = contentJson;
  }

  switch (code) {
    case 'BlockSvgBanner':
      return (
        <BlockSvgBanner elementContentJson={contentJsonParsed} />
      )
      break;
    case 'BlockInstantSizeTeamBanner':
    return (
      <BlockInstantSizeTeamBanner elementContentJson={contentJsonParsed} />
    )
    break;
    case 'BlockCarousel':
      return (
        <BlockCarousel elementContentJson={contentJsonParsed} />
      )
      break;
    case 'BlockTwoProductsCenter':
      return (
        <BlockTwoProductsCenter elementContentJson={contentJsonParsed} />
      )
      break;
    case 'BlockThreeProductsCenter':
      return (
        <BlockThreeProductsCenter elementContentJson={contentJsonParsed} />
      )
      break;
    case 'BlockFourProductsCenter':
      return (
        <BlockFourProductsCenter elementContentJson={contentJsonParsed} />
      )
      break;
    case 'BlockOneProductCenter':
      return (
        <BlockOneProductCenter elementContentJson={contentJsonParsed} />
      )
      break;
    case 'BlockProductArrayCenter':
      return (
        <BlockProductArrayCenter elementContentJson={contentJsonParsed} />
      )
      break;
  }
  return (<Box></Box>);
}
