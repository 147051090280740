import { Box, Typography, Dialog, Slide, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { closeOverlayAndResetPromo } from '../redux/UIOverlayPersonalSizeRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { openOverlayCreateMeasWithInitialization } from '../redux/UIOverlayMeasurementMethodSelectionViewRedux';
import { openOverlayCreateMeasWithInitialization as openUniversalSizeOverlay } from '../redux/UIOverlayUniversalSizeRedux';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import OverlayComponentButton from './OverlayComponentButton';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 1) + " !important") as any,
      '& .MuiPaper-root': {
        width: "80vw",
        borderRadius: "5.4vw",
        // backgroundColor: "#090F17"
      },
      '& .MuiBackdrop-root': {
        backgroundColor: "rgba(0, 0, 0, 0.7)"
      } 
    },
    dialogContentContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px 0px'
      // textAlign: 'center',
    },
    dialogDivImage: {
      width: "100%",
      // height: "124vw",
      overflow: "hidden",
      position: "relative",
      // backgroundColor: "#090F17",
    },
    backButton: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(2),
      backgroundColor: 'black',
      borderRadius: '50%',
      padding: theme.spacing(1),
    },
    backButtonIcon: {
      padding: '0',
      color: 'white',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflowY: 'hidden',
    },
    pageTitleHeader: {
      fontSize: '0.3em',
      letterSpacing: '0.05em',
      color: '#565656',
      fontWeight: 300,
    },
    pageTitle: {
      marginTop: '10px',
      fontSize: '1.2em',
      // width: '100%',
      fontWeight: 550,
    },
    pageItem: {
      display: 'grid',
      gridTemplateColumns: '1fr 8fr',
      marginTop: '1em',
    },
    pageItemIcon: {
      marginRight: '0.2em',
    },
    pageItemTitle: {
      paddingTop: '0.2em',
      fontSize: '0.8em',
      fontWeight: 500,
    },
    pageItemDescription: {
      gridColumn: '2',
      paddingTop: '0.2em',
      paddingRight: '0.5em',
      fontSize: '0.5em',
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});
  

interface Props {
}

export default function OverlayView({}: Props) {
  const overlayView = useAppSelector(state => state.overlayPersonalSize);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  function handleClose() {
    dispatch(closeOverlayAndResetPromo());
    dispatch(openOverlayCreateMeasWithInitialization(overlayView.payloadPromo.sizeData));
    sendWebsiteEventMessage("[PERSONAL FIT] User closed personal size promo popup");
  }
  
  return (
  <Dialog
    open={overlayView.statusPromo === UIOverlayViewStatus.On}
    TransitionComponent={Transition}
    keepMounted
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    onClose={() => handleClose()}
    className={classes.dialogContainer}>
      <div className={classes.dialogContentContainer}>
        <div className={classes.dialogDivImage}>
          <Box className={classes.dialogContent}>
            <Typography className={classes.pageTitleHeader} style={{ padding: '0px 18px' }}>
              PAKAI FIT CONVERTOR
            </Typography>
            <Typography className={classes.pageTitle} style={{ padding: '0px 18px' }}>
              Se-<i>Pas</i> Pembelian Sebelumnya.
            </Typography>
            <Typography className={classes.pageItemDescription} style={{margin: '5px 0px 23px', color: '#535353', fontWeight: 300, letterSpacing: '0.1vw', padding: '0px 18px' }}>
              Dibuat untuk menyesuaikan ukuran yang kamu sudah suka untuk kategori lainnya, Fit Convertor menyesuaikan ukuran favorit kamu untuk semua produk lainnya.
            </Typography>
            <img src={'https://studiosclo.uk/general/label-nama-closeup-ayah.jpg'}
            style={{
              width: '100%'
            }}/>
            <Box style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
              <Box style={{width: '30%', paddingBottom: '0px', paddingTop: '10px'}}>
                <OverlayComponentButton
                  handleClick={() => {
                    dispatch(closeOverlayAndResetPromo());
                    dispatch(openUniversalSizeOverlay(overlayView.payloadPromo.productDetail));
                    sendWebsiteEventMessage("[PERSONAL FIT] User click mulai");
                  }}
                  isTypeSubmit={false}
                  formButtonText="MULAI"
                  buttonMarginTopBottom="0px"
                  buttonMarginLeftRight="0px"
                />
              </Box>
            </Box>
          </Box>
      </div>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => handleClose()}
          style={{ position: 'absolute', top: '1px', right: '12px' }}
        >
          <Close />
        </IconButton>
    </div>
  </Dialog>);
}