import { Box, Button, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { RegularSizeValue, RegularSizeValueShortName } from 'common-ts/dist/models/Cart';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import OverlayComponentBaseContainerWidth from './OverlayComponentBaseContainerWidth';
import OverlayComponentButton, { FareastButton, FareastButtonGrey } from './OverlayComponentButton';
import OverlayComponentChoice from './OverlayComponentChoice';
import OverlayComponentForm from './OverlayComponentForm';
import OverlayComponentBaseTemplate from './OverlayComponentBaseTemplate';
import OverlayComponentTitle from './OverlayComponentTitle';
import OverlayComponentCanvas, { DrawGuidelinesData } from './OverlayComponentCanvasFitAnimation';
import PageProductOverlayMeasureInstructions from './OverlayViewGenerateMeasureMethod3Favorite1ModifySizePopup';
import { ClothingMeasurementAdjustmentLevel, ClothingMeasurementDetailList, ClothingMeasurementOrdering, 
  ClothingMeasurements, ClothingMeasurementsKey, ClothingType, 
  BodyMeasurements, FitType, CalculateClothingMeasurementsFromBodyMeasurements, RoundClothingMeasurementsToHalfPoint, ClothingMeasurementAdjustmentDisplayRange, GetKey2CategoryClothingSizeMeasurementValues, BodyMeasurementsKey, BodyMeasurementsForSize, 
  DefaultFollowingMeasurementsForClothingTypeAndFitType,
  ClothBodyToDisplayedClothMeasForClothingTypeAndFitType, 
  CalculateClothBodyMeasurementsFromBodyMeasurements,
  ClothingTypeDisplayString} from 'common-ts/dist/models/UserMeasurements';
import { openOverlay } from '../redux/UIOverlayMeasureInstructionsRedux';
import { getRequestDataProducts, retryGetRequest } from '../redux/CartRedux';
import { AxiosRequestConfig } from 'axios';
import hash from 'object-hash';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import RestoreIcon from '@mui/icons-material/RestartAlt';
import Tooltip from '@material-ui/core/Tooltip';
import { formatTimestampSize } from './PageProductDetailSizeSelection';

interface stylesProps {
  isMinimized: boolean 
}

const useStyles = (({ isMinimized }: stylesProps) => makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '300px',
      [theme.breakpoints.only('xs')]: {
        width: '240px',
      }
    },
    gridContainer: {
      width: isMinimized ? '90%' : '100%',
      [theme.breakpoints.up('md')]: {
        paddingTop: isMinimized ? '0px' : '0px',
      },
    },
    form: {
      height: 'auto',
      padding: '0px 24px 0px 24px',
      [theme.breakpoints.only('xs')]: {
        padding: '0px 0px 0px 0px',
      }
    },
    formRow: {
      paddingBottom: '30px',
    },
    subFormTitle: {
    },
    toggleSignUpSignInContainer: {
      paddingTop: '30px',
      paddingBottom: '30px',
      // paddingLeft: '10px',
      paddingRight: '10px',
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "baseline",
      justifyContent: isMinimized ? 'space-between' : 'flex-end',
      width: '100%',
      paddingBottom: '12px',
      [theme.breakpoints.only('xs')]: {
        paddingBottom: '6px',
      }
    },
    canvasContainer: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.only('xs')]: {
        height: '75vw',
      },
      position: 'relative',
    },
    measurementValueContainer: {
      width: '100%',
      height: isMinimized ? '35px' : '40px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: "baseline",
    },
    measurementNameText: {
      fontSize: isMinimized ? '10.5px' : '12px',
      fontWeight: isMinimized ? 400 : 300,
  
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',

      cursor: "pointer",
      '-webkit-tap-highlight-color': 'transparent',
    },
    measurementNameTextIIcon: {
      fontSize: isMinimized ? '9.5px' : '11px',
      marginLeft: "3px",
    },
    measurementValueTextContainer: {
      width: "50%",
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    measurementValueText: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: isMinimized ? '10.5px' : '12px',
      fontWeight: 500,
      marginBottom: '0px',
    },
    measurementValueTextSub: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: isMinimized ? '8px' : '10px',
      fontWeight: isMinimized ? 400 : 300,
      letterSpacing: '0.7px',
      color: '#3e3e3e',
      textWrap: 'nowrap',
      width: '200%'
    },
    measurementChangeValueButton: {
      width: isMinimized ? "20%" : '25%',
      minWidth: "4px",
      padding: "0px",
      '& .MuiButton-label': {
        fontSize: isMinimized ? '15.5px' : '18px',
        fontWeight: 400,
      }
    },
    ukuranCustomAvailText: {
      fontWeight: 500,
      fontSize: isMinimized ? '8.5px' : '11px',
      letterSpacing: '0.05px',
      marginTop: '7px',
      marginBottom: isMinimized ? '-6px' : '-20px',
    }
  }),
));

export function getBodyMeasurementsSubtext(bodyMeasurements: BodyMeasurements) {

  // KEY2TODO-CB Add new CB
  const results: {[key in BodyMeasurementsKey]: string} = {
    panjangBaju: '',
    lingkarDada: '',
    lingkarPerut: '',
    lingkarPinggul: '',
    lingkarLeher: '',
    lebarBahu: '',
    lingkarLenganBisep: '',
    panjangLenganPanjang: '',
    lingkarLenganPergelangan: '',

    // CB
    lebarLenganBawahTigaEmpat: '',
    lebarLenganPendek: '',
    panjangLenganPendek: '',
  }

  const regularSizeNames: RegularSizeValue[] = [
    RegularSizeValue.XXS,
    RegularSizeValue.XS,
    RegularSizeValue.S,
    RegularSizeValue.M,
    RegularSizeValue.L,
    RegularSizeValue.XL,
    RegularSizeValue.XXL,
  ];
  if (false) { // TODO Set to true after extra sizes are figured out
    regularSizeNames.push(RegularSizeValue.XXXL);
    regularSizeNames.push(RegularSizeValue.XXXXL);
    regularSizeNames.push(RegularSizeValue.XXXXXL);
  }

  const sizeMeasNames = regularSizeNames;
  const sizeMeasValues = regularSizeNames.map((keyValue: RegularSizeValue) => BodyMeasurementsForSize[keyValue]);

  for (let bodyMeasName of Object.keys(bodyMeasurements)) {
    const currentBodyMeasValue = bodyMeasurements[bodyMeasName as keyof BodyMeasurements];
    for (var i = 0; i < sizeMeasValues.length; i++) {
      const sizeClothMeasValue = sizeMeasValues[i]?.[bodyMeasName as keyof BodyMeasurements];
      const sizeClothMeasName = sizeMeasNames[i];
      if (!sizeClothMeasValue || !currentBodyMeasValue || !sizeClothMeasName) {
        return results;
      }
      if (currentBodyMeasValue < sizeClothMeasValue) {
        if (i === 0) {
          results[bodyMeasName as keyof BodyMeasurements] = 'Ukuran Baju Dibawah XXS';
        } else {
          results[bodyMeasName as keyof BodyMeasurements] = 'Antara ' + 
            RegularSizeValueShortName[sizeMeasNames[i - 1]] + ' dan ' +
            RegularSizeValueShortName[sizeClothMeasName];
        }
        if (bodyMeasName === 'lingkarLeher') {
          if (RegularSizeValueShortName[sizeClothMeasName] === 'XXS' || 
            RegularSizeValueShortName[sizeClothMeasName] === 'XS' || 
            RegularSizeValueShortName[sizeClothMeasName] === 'S') {
            results[bodyMeasName as keyof BodyMeasurements] = 'Ukuran Kecil (S - M)'
          } else if (RegularSizeValueShortName[sizeClothMeasName] === 'M' ||
            RegularSizeValueShortName[sizeClothMeasName] === 'L') {
            results[bodyMeasName as keyof BodyMeasurements] = 'Ukuran Sedang (M - L)'
          } else if (RegularSizeValueShortName[sizeClothMeasName] === 'XL' ||
            RegularSizeValueShortName[sizeClothMeasName] === 'XXL') {
            results[bodyMeasName as keyof BodyMeasurements] = 'Ukuran Besar (L - XL)'
          }
        }
        break;
      } else if (currentBodyMeasValue === sizeClothMeasValue) {
        results[bodyMeasName as keyof BodyMeasurements] = 'Ukuran Baju ' +
          RegularSizeValueShortName[sizeClothMeasName];
        if (bodyMeasName === 'lingkarLeher') {
          if (RegularSizeValueShortName[sizeClothMeasName] === 'XXS' || 
            RegularSizeValueShortName[sizeClothMeasName] === 'XS' || 
            RegularSizeValueShortName[sizeClothMeasName] === 'S') {
            results[bodyMeasName as keyof BodyMeasurements] = 'Ukuran Kecil (S - M)'
          } else if (RegularSizeValueShortName[sizeClothMeasName] === 'M' ||
            RegularSizeValueShortName[sizeClothMeasName] === 'L') {
            results[bodyMeasName as keyof BodyMeasurements] = 'Ukuran Sedang (M - L)'
          } else if (RegularSizeValueShortName[sizeClothMeasName] === 'XL' ||
            RegularSizeValueShortName[sizeClothMeasName] === 'XXL') {
            results[bodyMeasName as keyof BodyMeasurements] = 'Ukuran Besar (L - XL)'
          }
        }
        break;
      }
      if (i === sizeMeasValues.length - 1) {
        results[bodyMeasName as keyof BodyMeasurements] = 'Ukuran Baju Diatas ' + 
        RegularSizeValueShortName[sizeClothMeasName];
      }
    }
  }

  return results;
}

// KEY2TODO
export function getClothingMeasurementsSubtext(clothingType: string, key2ClothSizeValues: {
  regularSizeNames: RegularSizeValue[];
  regularSizeClothMeasValues: ClothingMeasurements[];
  regularSizeClothBodyMeasValues: BodyMeasurements[];
}, clothingMeasurements: ClothingMeasurements) {
  const results: {[key in ClothingMeasurementsKey]: string} = {
    panjangBaju: '',
    lebarDadaBaju: '',
    lebarPerutBaju: '',
    lingkarLeherBaju: '',
    lebarBahuBaju: '',
    panjangLenganPendek: '',
    panjangLenganPanjang: '',
    lebarLenganBaju: '',
    lebarLenganAtasBaju: '',
  }

  if (key2ClothSizeValues.regularSizeClothMeasValues.length !== key2ClothSizeValues.regularSizeNames.length) {
    return results;
  }

  const sizeMeasNames = key2ClothSizeValues.regularSizeNames;
  const sizeMeasValues = key2ClothSizeValues.regularSizeClothMeasValues;

  for (let clothMeasName of Object.keys(clothingMeasurements)) {
    const currentClothMeasValue = clothingMeasurements[clothMeasName as keyof ClothingMeasurements];
    for (var i = 0; i < sizeMeasValues.length; i++) {
      const sizeClothMeasValue = sizeMeasValues[i]?.[clothMeasName as keyof ClothingMeasurements];
      const sizeClothMeasName = sizeMeasNames[i];
      if (!sizeClothMeasValue || !currentClothMeasValue || !sizeClothMeasName) {
        return results;
      }
      if (currentClothMeasValue < sizeClothMeasValue) {
        if (i === 0) {
          results[clothMeasName as keyof ClothingMeasurements] = 'Dibawah XXS';
        } else {
          results[clothMeasName as keyof ClothingMeasurements] = 'Antara ' + 
            RegularSizeValueShortName[sizeMeasNames[i - 1]] + ' dan ' +
            RegularSizeValueShortName[sizeClothMeasName];
        }
        break;
      } else if (currentClothMeasValue === sizeClothMeasValue) {
        results[clothMeasName as keyof ClothingMeasurements] = 'Ukuran ' +
          RegularSizeValueShortName[sizeClothMeasName];
        if (clothMeasName === 'lingkarLeherBaju') {
          if (RegularSizeValueShortName[sizeClothMeasName] === 'XXS') {
            results[clothMeasName as keyof ClothingMeasurements] = 'Kecil (S - M)'
          } else if (RegularSizeValueShortName[sizeClothMeasName] === 'M') {
            results[clothMeasName as keyof ClothingMeasurements] = 'Sedang (M - L)'
          } else if (RegularSizeValueShortName[sizeClothMeasName] === 'XL') {
            results[clothMeasName as keyof ClothingMeasurements] = 'Besar (L - XL)'
          }
        }
        if (clothingType === "KemejaPanjang" && clothMeasName === 'lebarLenganBaju') {
          if (RegularSizeValueShortName[sizeClothMeasName] === 'XXS') {
            results[clothMeasName as keyof ClothingMeasurements] = 'Kecil (S - M)'
          } else if (RegularSizeValueShortName[sizeClothMeasName] === 'M') {
            results[clothMeasName as keyof ClothingMeasurements] = 'Sedang (M - L)'
          } else if (RegularSizeValueShortName[sizeClothMeasName] === 'XL') {
            results[clothMeasName as keyof ClothingMeasurements] = 'Besar (L - XL)'
          }
        }
        break;
      }
      if (i === sizeMeasValues.length - 1) {
        results[clothMeasName as keyof ClothingMeasurements] = 'Diatas ' + 
        RegularSizeValueShortName[sizeClothMeasName];
      }
    }
  }

  return results;
}

export interface ClothingMeasurementsDetailPanelProps {
  clothingType: ClothingType
  fitType: FitType
  allowEditing: boolean
  currentClothingMeas: ClothingMeasurements
  currentClothingMeasSubtextDict: {[key:string]: string}
  currentlyFetchingAvailability: boolean
  clothMeasActionsDict?: {
    [key: string]: {
      clothMeasInfoFunction: () => void
      minusDetail: {
        onClickFunction: () => void
        isEnabled: boolean
        needsMod: boolean
      }
      plusDetail: {
        onClickFunction: () => void
        isEnabled: boolean
        needsMod: boolean
      }
    }
  }
  isMinimized?: boolean
}

export function ClothingMeasurementDetailPanel({ clothingType, fitType,
  allowEditing, currentClothingMeas, currentClothingMeasSubtextDict,
  currentlyFetchingAvailability, clothMeasActionsDict, isMinimized=false }: ClothingMeasurementsDetailPanelProps) {
  const classes = useStyles({isMinimized: isMinimized})();

  const clothingMeasurementsDisplayed = Object.values(ClothBodyToDisplayedClothMeasForClothingTypeAndFitType[clothingType]);

  return (
    <Grid container className={classes.gridContainer} 
    direction="row" justifyContent="flex-start">
    {
      ClothingMeasurementOrdering.map((itemKey) => {
        if (!clothingMeasurementsDisplayed.includes(itemKey as ClothingMeasurementsKey)) {
          return null;
        }
        if (currentClothingMeas[itemKey as ClothingMeasurementsKey] === undefined) {
          return null;
        }
        if (ClothingMeasurementDetailList[itemKey as ClothingMeasurementsKey] === undefined
          || ClothingMeasurementAdjustmentLevel[itemKey as ClothingMeasurementsKey] === undefined
          || ClothingMeasurementAdjustmentLevel[itemKey as ClothingMeasurementsKey] === "unavailable") {
          return null;
        }

        const measurementName = ClothingMeasurementDetailList[itemKey as ClothingMeasurementsKey].clothingMeasurementName;
        const measurementValue = currentClothingMeas[itemKey as keyof ClothingMeasurements] as number;
        return (
          <Grid item xs={12} >
            <Grid container className={classes.measurementValueContainer}>
              <Grid item xs={7}>
                <Typography className={classes.measurementNameText}
                onClick={clothMeasActionsDict?.[itemKey].clothMeasInfoFunction}>
                  {measurementName + " "}<span className={classes.measurementNameTextIIcon}>&#x24D8;</span>
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Box className={classes.buttonContainer}>
                  <Button onClick={clothMeasActionsDict?.[itemKey].minusDetail.onClickFunction}
                  disabled={!allowEditing || currentlyFetchingAvailability || !clothMeasActionsDict?.[itemKey].minusDetail.isEnabled}
                  style={{
                    color: 
                    allowEditing && clothMeasActionsDict?.[itemKey].minusDetail.isEnabled ? 
                    '#232323': 
                    '#efefef',
                    position: 'relative'
                  }}
                  className={classes.measurementChangeValueButton}>
                    -
                    {clothMeasActionsDict?.[itemKey].minusDetail.needsMod ? 
                      <img src={'https://studiosclo.uk/general/icon-uk-favorit-mod.png'}
                      style={{
                        width: '0.5rem',
                        position: 'absolute',
                        left: '1.2rem',
                        top: '0.1rem',
                      }}
                      alt={'uk-favorit-mod-min'}
                      /> : null}
                  </Button>
                  <Box className={classes.measurementValueTextContainer}>
                    <Typography className={classes.measurementValueText}>
                      {measurementValue} cm
                    </Typography>
                    <Typography className={classes.measurementValueTextSub}>
                      {currentClothingMeasSubtextDict?.[itemKey as ClothingMeasurementsKey]}
                    </Typography>
                  </Box>
                  <Button onClick={clothMeasActionsDict?.[itemKey].plusDetail.onClickFunction}
                  disabled={!allowEditing || currentlyFetchingAvailability || !clothMeasActionsDict?.[itemKey].plusDetail.isEnabled}
                  style={{
                    color: 
                    allowEditing && clothMeasActionsDict?.[itemKey].plusDetail.isEnabled ? 
                    '#232323' : 
                    '#efefef',
                    position: 'relative'
                  }}
                  className={classes.measurementChangeValueButton}>
                    +
                    {clothMeasActionsDict?.[itemKey].plusDetail.needsMod ? 
                      <img src={'https://studiosclo.uk/general/icon-uk-favorit-mod.png'}
                      style={{
                        width: '0.5rem',
                        position: 'absolute',
                        left: '1.2rem',
                        top: '0.1rem',
                      }}
                      alt={'uk-favorit-mod-plus'}
                      /> : null}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )
      })
    }
    {
      (allowEditing && !isMinimized) ? 
        <Grid item xs={12} style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: '26px'
        }}>
          <img src={'https://studiosclo.uk/general/uk-favorit-keterangan.png'}
          alt={'uk-favorit-keterangan'}
          style={{ width: '90%' }}/> 
        </Grid>:
        null
    }
  </Grid>
  );
}

export interface Props {
  startingClothingMeasurements: ClothingMeasurements
  startingClothingBodyMeasurements: BodyMeasurements
  editedClothingMeasurements?: ClothingMeasurements
  editedClothingBodyMeasurements?: BodyMeasurements
  clothingType: ClothingType
  fitType: FitType
  key2: string
  handleSubmit: (value: BodyMeasurements, valueClothing: ClothingMeasurements) => void
  productId?: string
  productDetail?: any
  isMinimized?: boolean
  showNumberOfSimiliarProduct?: boolean
  numberOfSimiliarProduct?: number
  onlyUseServiceability?: boolean
  allowSubmitWithoutChange?: boolean
}

export default function RegularModifySize({startingClothingMeasurements, startingClothingBodyMeasurements, editedClothingMeasurements, editedClothingBodyMeasurements, clothingType, fitType, key2, handleSubmit, productId, productDetail, isMinimized=false, showNumberOfSimiliarProduct, numberOfSimiliarProduct, onlyUseServiceability, allowSubmitWithoutChange}: Props) {
  const dispatch = useAppDispatch();
  const classes = useStyles({isMinimized: isMinimized})();
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  // console.log({startingClothingMeasurements, startingClothingBodyMeasurements, editedClothingMeasurements, editedClothingBodyMeasurements, clothingType, fitType, key2, handleSubmit, productId, productDetail, isMinimized, showNumberOfSimiliarProduct, numberOfSimiliarProduct})

  const [currentClothingMeas, setCurrentClothingMeas] = React.useState<ClothingMeasurements>(startingClothingMeasurements);
  const [currentClothingBodyMeas, setCurrentClothingBodyMeas] = React.useState<BodyMeasurements>(startingClothingBodyMeasurements);
  const [followingClothingBodyMeas, setFollowingClothingBodyMeas] = React.useState<BodyMeasurementsKey[]>(DefaultFollowingMeasurementsForClothingTypeAndFitType[key2]);

  const [drawGuidelinesData, setDrawGuidelinesData] = React.useState<DrawGuidelinesData | undefined>(undefined);

  const [currentlyFetchingAvail, setCurrentlyFetchingAvail] = React.useState<boolean>(false);
  const [lastFetchStatus, setLastFetchStatus] = React.useState<string>('TOFETCH');
  const [nextMeasDict, setNextMeasDict] = React.useState<any>({});

  const cartProductList = useAppSelector(state => state.cart.cartProductList);
  const userBodyMeasurementsDict = useAppSelector(state => state.userMeasurementsList.userBodyMeasurementsDictionary);
  const userClothingMeasurementsDict =  useAppSelector(state => state.userMeasurementsList.userClothingMeasurementsDictionary);

  // Change Feature
  const [changesDetected, setChangesDetected] = React.useState(false);

  const handleReset = () => {
    setCurrentClothingMeas(startingClothingMeasurements);
    setCurrentClothingBodyMeas(startingClothingBodyMeasurements);
    setFollowingClothingBodyMeas(DefaultFollowingMeasurementsForClothingTypeAndFitType[key2]);
    setChangesDetected(false);
    setDrawGuidelinesData(undefined);

    updateClothingMeasurement(startingClothingBodyMeasurements, DefaultFollowingMeasurementsForClothingTypeAndFitType[key2], '', undefined);
  };

  useEffect(() => {
    const hasChanges = 
      JSON.stringify(currentClothingMeas) !== JSON.stringify(startingClothingMeasurements) ||
      JSON.stringify(currentClothingBodyMeas) !== JSON.stringify(startingClothingBodyMeasurements);
    setChangesDetected(hasChanges);
  }, [currentClothingMeas, currentClothingBodyMeas]);


  function updateClothingMeasurement(
    newClothingBodyMeasurements: BodyMeasurements, 
    lastFollowingList: BodyMeasurementsKey[],
    originalHash: string,
    completeAdjustment: any,
  ) {
    // If there's no original hash, we assume this is a "forced" update and 
    if (!originalHash) {
      setNextMeasDict({}); // Since the original is very "far" from the current measurement, we disable the measurements first.
      getCurrentProposedSizeAvailability(newClothingBodyMeasurements, lastFollowingList);
      return;
    }

    if (hash(currentClothingBodyMeas) === originalHash) {
      getCurrentProposedSizeAvailability(newClothingBodyMeasurements, lastFollowingList);
    } else {
      // NOTE 
      // Effectively removes the "switching" bug, since the new adjustment is combined with the previous (fetching)
      // adjustment. If there is slow / no connectivity, however, this would only work "once" per variable until the 
      // request returns, since the plus / minus adjustments are NOT updated until the response arrives from the server.
      // There is an inherent tradeoff: If each step is "provided" by the server, we reach better continuity / consistency,
      // but if the internet is slow there will be some "lag". If we do local, there is risk, but much faster. 
      getCurrentProposedSizeAvailability({
        ...currentClothingBodyMeas,
        ...completeAdjustment
      }, lastFollowingList);
    }
  }

  useEffect(() => {
    getCurrentProposedSizeAvailability(startingClothingBodyMeasurements, DefaultFollowingMeasurementsForClothingTypeAndFitType[key2]);
    if (editedClothingMeasurements) {
      setCurrentClothingMeas(editedClothingMeasurements)
    }
    if (editedClothingBodyMeasurements) {
      setCurrentClothingBodyMeas(editedClothingBodyMeasurements)
    }
  }, []);

  async function getCurrentProposedSizeAvailability(proposedClothBodyMeasurements: BodyMeasurements, lastFollowingList: BodyMeasurementsKey[]) {
    if (proposedClothBodyMeasurements) {
      // setCurrentlyFetchingAvail(true);
  
      let measurementsForItem: BodyMeasurements = proposedClothBodyMeasurements;
      let clothMeasurementsForItem: ClothingMeasurements = CalculateClothingMeasurementsFromBodyMeasurements(clothingType, fitType, proposedClothBodyMeasurements, RegularSizeValue.L);
      setCurrentClothingMeas(RoundClothingMeasurementsToHalfPoint(clothMeasurementsForItem));
      setCurrentClothingBodyMeas(measurementsForItem);
  
      const measPolicy: any = {};
      for (let measKey of Object.keys(measurementsForItem)) {
        measPolicy[measKey] = "EXACT";
      }

      let config: AxiosRequestConfig = { }

      if (productId) {

        var requestDataProducts: any = getRequestDataProducts(cartProductList, userBodyMeasurementsDict, userClothingMeasurementsDict);
        requestDataProducts.push({
          resource: "PRODUCT",
          resourceId: productId, 
          provisionStrategy: "PRODUCT",
          provisionStrategyMetadata: {
            productId: productId,
            measurements: measurementsForItem,
            measurementsPolicy: measPolicy,
          }
        });
  
        config = {
          method: 'post',
          url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/availability',
          data: JSON.stringify(
            {
              "requestData": requestDataProducts,
            })
        };
      } else {

        config = {
          method: 'post',
          url: 'https://i3fxe6nvj7.execute-api.ap-southeast-1.amazonaws.com/prod/availability/meas-serviceable',
          data: JSON.stringify(
            {
              clothingType: clothingType,
              fitType: fitType,
              measurements: measurementsForItem,
              measurementsPolicy: measPolicy
            })
        };
      }

      retryGetRequest(config, 8).then((response) => {
        setCurrentlyFetchingAvail(false);
        if (response?.data?.provisionStatus) {
          setLastFetchStatus(response?.data?.provisionStatus);
        }
        if (response?.data?.additionalRequestResultData?.changeMeasurementsDict
          && response?.data?.additionalRequestResultData?.measurementsResult) {
          const nextMeasDict: any = {};

          const changeMeasurementsDict: any = response.data.
            additionalRequestResultData.changeMeasurementsDict; // This is in BodyMeas space
          const originalClothBodyMeas: any = response.data.
            additionalRequestResultData.measurements;
          const resultClothBodyMeas: any = response.data.
            additionalRequestResultData.measurementsResult;
          const hashResultClothBodyMeas: string = hash(resultClothBodyMeas);

          if (hash(resultClothBodyMeas) !== hash(originalClothBodyMeas)) {
            updateClothingMeasurement(resultClothBodyMeas, lastFollowingList, '', undefined);
            setLastFetchStatus("ENOUGH");
            return;
          }

          for (let bodyMeasName of Object.keys(changeMeasurementsDict)) {
            const bodyMainAdj = Object.keys(changeMeasurementsDict[bodyMeasName].mainAdjustment)[0];
            const bodyMainAdjValueOriginal = resultClothBodyMeas[bodyMainAdj];
            const bodyMainAdjValue = changeMeasurementsDict[bodyMeasName].mainAdjustment[bodyMainAdj];
            const isServiceable = changeMeasurementsDict[bodyMeasName].isServiceable;
            const modAdjustment = changeMeasurementsDict[bodyMeasName].modAdjustment;

            const displayClothChangeKey = ClothBodyToDisplayedClothMeasForClothingTypeAndFitType[clothingType]?.[bodyMainAdj as BodyMeasurementsKey];

            if (displayClothChangeKey === undefined) {
              continue;
            }

            const changeIsPlus = bodyMainAdjValue > bodyMainAdjValueOriginal;

            const completeAdjustment = {
              [bodyMainAdj]: bodyMainAdjValue,
            }

            const newClothBodyMeas = {
              ...resultClothBodyMeas,
              [bodyMainAdj]: bodyMainAdjValue
            }
            if (modAdjustment) {
              for (let measKey of Object.keys(modAdjustment)) {
                newClothBodyMeas[measKey] = modAdjustment[measKey]
                completeAdjustment[measKey] = modAdjustment[measKey]
              }
            }

            // We remove the current changed main adjustment from the "following" list
            const newFollowingList = lastFollowingList.filter((value) => value.toString() !== bodyMainAdj);

            const newClothBodyMeasAfterFollowing = CalculateClothBodyMeasurementsFromBodyMeasurements(clothingType, fitType, 
              newClothBodyMeas, RegularSizeValue.L, newFollowingList);

            // We need is serviceable, if there is a mod adjustable needed, and the next clothing measurements
            nextMeasDict[displayClothChangeKey + (changeIsPlus ? "Plus" : "Minus")] = {
              isServiceable: isServiceable,
              modAdjustment: modAdjustment,
              needsMod: modAdjustment ? true : false,
              nextClothingBodyMeas: newClothBodyMeasAfterFollowing,
              nextFollowingClothingBodyMeas: newFollowingList,
              completeAdjustment: completeAdjustment,
              hashOriginalClothingBodyMeas: hashResultClothBodyMeas,
            };
          }

          setNextMeasDict(nextMeasDict);
        }
      });
    }
  }

  const key2ClothSizeValues = GetKey2CategoryClothingSizeMeasurementValues(clothingType, fitType, false);
  const subtextDict = getClothingMeasurementsSubtext(clothingType, key2ClothSizeValues, currentClothingMeas);

  const inputClothMeasActionsDict: {[key:string]: {
    clothMeasInfoFunction: () => void
    minusDetail: {
      onClickFunction: () => void
      isEnabled: boolean
      needsMod: boolean
    }
    plusDetail: {
      onClickFunction: () => void
      isEnabled: boolean
      needsMod: boolean
    }
  }} = {};
  const clothingMeasurementsDisplayed = Object.values(ClothBodyToDisplayedClothMeasForClothingTypeAndFitType[clothingType]);
  for (let i = 0; i < ClothingMeasurementOrdering.length; i++) {
    const itemKey = ClothingMeasurementOrdering[i];
    if (!clothingMeasurementsDisplayed.includes(itemKey as ClothingMeasurementsKey)) {
      continue;
    }
    if (currentClothingMeas[itemKey as ClothingMeasurementsKey] === undefined) {
      continue;
    }
    if (ClothingMeasurementDetailList[itemKey as ClothingMeasurementsKey] === undefined
      || ClothingMeasurementAdjustmentLevel[itemKey as ClothingMeasurementsKey] === undefined
      || ClothingMeasurementAdjustmentLevel[itemKey as ClothingMeasurementsKey] === "unavailable") {
      continue;
    }

    const measurementName = ClothingMeasurementDetailList[itemKey as ClothingMeasurementsKey].clothingMeasurementName;

    const itemObject: {
      clothMeasInfoFunction: () => void
      minusDetail: {
        onClickFunction: () => void
        isEnabled: boolean
        needsMod: boolean
      }
      plusDetail: {
        onClickFunction: () => void
        isEnabled: boolean
        needsMod: boolean
      }
    } = {
      clothMeasInfoFunction: () => {dispatch(openOverlay(itemKey as ClothingMeasurementsKey))},
      minusDetail: {
        onClickFunction: () => {
          if (nextMeasDict?.[itemKey + "Minus"]?.isServiceable) {
            setFollowingClothingBodyMeas(nextMeasDict[itemKey + "Minus"].nextFollowingClothingBodyMeas);
            updateClothingMeasurement(nextMeasDict[itemKey + "Minus"].nextClothingBodyMeas, nextMeasDict[itemKey + "Minus"].nextFollowingClothingBodyMeas, nextMeasDict[itemKey + "Minus"].hashOriginalClothingBodyMeas, nextMeasDict[itemKey + "Minus"].completeAdjustment);

            // UPDATE GUIDELINES DATA
            const newDrawGuidelinesData: DrawGuidelinesData = {
              lastChangedMeasurement: itemKey,
              lastChangedMeasurementName: measurementName,
              guidelineDisplayRange: ClothingMeasurementAdjustmentDisplayRange[itemKey as ClothingMeasurementsKey],
              sizeMeasurementValues: key2ClothSizeValues.regularSizeClothMeasValues,
              sizeMeasurementNames: key2ClothSizeValues.regularSizeNames,
            }
            setDrawGuidelinesData(newDrawGuidelinesData);
          }
        },
        isEnabled: nextMeasDict?.[itemKey + "Minus"]?.isServiceable,
        needsMod: nextMeasDict?.[itemKey + "Minus"]?.needsMod,
      },
      plusDetail: {
        onClickFunction: () => {
          if (nextMeasDict?.[itemKey + "Plus"]?.isServiceable) {
            setFollowingClothingBodyMeas(nextMeasDict[itemKey + "Plus"].nextFollowingClothingBodyMeas);
            updateClothingMeasurement(nextMeasDict[itemKey + "Plus"].nextClothingBodyMeas, nextMeasDict[itemKey + "Plus"].nextFollowingClothingBodyMeas, nextMeasDict[itemKey + "Plus"].hashOriginalClothingBodyMeas, nextMeasDict[itemKey + "Plus"].completeAdjustment);

            // UPDATE GUIDELINES DATA
            const newDrawGuidelinesData: DrawGuidelinesData = {
              lastChangedMeasurement: itemKey,
              lastChangedMeasurementName: measurementName,
              guidelineDisplayRange: ClothingMeasurementAdjustmentDisplayRange[itemKey as ClothingMeasurementsKey],
              sizeMeasurementValues: key2ClothSizeValues.regularSizeClothMeasValues,
              sizeMeasurementNames: key2ClothSizeValues.regularSizeNames,
            }
            setDrawGuidelinesData(newDrawGuidelinesData);
          }
        },
        isEnabled: nextMeasDict?.[itemKey + "Plus"]?.isServiceable,
        needsMod: nextMeasDict?.[itemKey + "Plus"]?.needsMod,
      }
    };

    inputClothMeasActionsDict[itemKey] = itemObject;
  }


  let children: JSX.Element[] = [];
  let submitChildren: JSX.Element[] = [];

  children.push(
    <OverlayComponentBaseContainerHorizontal>
      <OverlayComponentBaseContainerWidth
        widthMdUp="100%"
        widthSmDown="100%">
        <Grid container>
          <Grid item xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
          }}>
            {
              isMinimized && productDetail?.name ? 
              <Typography className={classes.ukuranCustomAvailText} style={{
                fontWeight: 600,
                fontSize: '14px',
                // letterSpacing: '0.7px',
                marginBottom: '-10px',
                marginTop: '40px'
              }}>
                Produk {productDetail?.name}
              </Typography>:
              undefined
            }
          </Grid>
          {
            showNumberOfSimiliarProduct && numberOfSimiliarProduct && numberOfSimiliarProduct > 0 ? (
              <Grid container style={{
                width: '95%',
                padding: '7px 15px 5px 15px',
                borderRadius: '6vw',
                marginLeft: '10px',
                marginTop: '150px',
                marginBottom: '5px',
                border: '1px solid #eaeaea'
              }} justifyContent='space-between' alignItems='center'>
                <Typography
                  style={{
                    fontSize: '13px',
                    letterSpacing: '0.2px',
                    fontWeight: 500
                  }}
                >
                  {ClothingTypeDisplayString[clothingType as ClothingType].toUpperCase()}
                </Typography>
                <Typography
                  style={{
                    fontSize: '11px',
                    fontWeight: 600,
                    letterSpacing: '0.5px',
                    color: '#B0B0B0'
                  }}
                >
                  {numberOfSimiliarProduct} PRODUK
                </Typography>
    
              </Grid>
            ) : (
              null
            )
          }
          <Grid item xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
              marginBottom: isMdOrUp ? '40px' : '20px',
            }}>
            {
              onlyUseServiceability ? 
              undefined :
              currentlyFetchingAvail ?
              <Typography className={classes.ukuranCustomAvailText}
              style={{
                color: '#33ae33'
              }}>
                Bahan Tersedia Untuk Ukuran Ini
              </Typography>:
              lastFetchStatus === "ENOUGH" ?
              <Typography className={classes.ukuranCustomAvailText}
              style={{
                color: '#33ae33'
              }}>
                Bahan Tersedia Untuk Ukuran Ini
              </Typography>:
              lastFetchStatus === "NOTENOUGH" ?
              <Typography className={classes.ukuranCustomAvailText}
              style={{
                color: 'orange'
              }}>
                Bahan Tidak Cukup Untuk Ukuran Ini
              </Typography>:
              productId ? 
              <Typography className={classes.ukuranCustomAvailText}
              style={{
                color: '#454545'
              }}>
                Loading...
              </Typography>:
              undefined
            }
          </Grid>
          <Grid item xs={(isMdOrUp && !isMinimized) ? 6 : 12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: isMdOrUp ? '100px' : undefined
            }}
          >
            <Box className={classes.canvasContainer} style={isMinimized ? {height: '100%'} : {height: '250px', maxWidth: '250px'}}>
              <OverlayComponentCanvas
                clothingType={clothingType}
                fitType={fitType}
                putAtTop={false}
                scaleRatio={isMinimized ? 0.8 : 0.75}
                incrementTogether={true}
                goalFit={currentClothingMeas}
                drawGuidelinesData={drawGuidelinesData}
              />
              <IconButton
              style={{
                visibility: drawGuidelinesData ? 'visible' : 'hidden',
                backgroundColor: '#232323',
                color: '#ffffff',
                position: 'absolute',
                right: '0px',
                bottom: '20px',
                padding: '7px'
              }}
              onClick={() => {
                setDrawGuidelinesData(undefined);
              }} aria-label="zoom-out">
                <ZoomOutIcon />
              </IconButton>
              {
                isMdOrUp && (
                  <Grid item xs={12} style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: '30px'}}>
                    <Button 
                      disabled={!changesDetected}>
                      <Box 
                        onClick={handleReset} 
                        style={{
                          border: '1px solid #717171',
                          borderColor: changesDetected ? 'black' : 'lightgrey',
                          borderRadius: '20px',
                          marginTop: '5px',
                          padding: '4px 13px 4px 13px',
                          color: changesDetected ? 'black' : 'lightgrey',
                          display: "flex", 
                          justifyContent: "center", 
                          alignItems: "center",
                          gap: '10px'
                      }}>
                        <RestoreIcon 
                          style={{
                            fontSize: '20px'
                          }}
                        />
                        <Typography
                            style={{
                              color: changesDetected ? '#262626' : 'lightgrey',
                              cursor: changesDetected ? 'pointer' : 'default',
                              fontSize: '10px',
                              letterSpacing: '0.5px',
                              fontWeight: 600,
                              paddingTop: '4px'
                            }}
                          >
                            KEMBALI KE UKURAN AWAL
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                )
              }
            </Box>
          </Grid>
          <Grid item xs={(isMdOrUp && !isMinimized) ? 6 : 12} style={{
            paddingBottom: '20px',
          }}> 
            <Grid container>
              {
                !isMdOrUp && (
                  <Grid item xs={12} style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: '10px'}}>
                    <Button 
                      disabled={!changesDetected}>
                      <Box 
                        onClick={handleReset} 
                        style={{
                          border: '1px solid #717171',
                          borderColor: changesDetected ? 'black' : 'lightgrey',
                          borderRadius: '20px',
                          marginTop: '5px',
                          padding: '4px 13px 4px 13px',
                          color: changesDetected ? 'black' : 'lightgrey',
                          display: "flex", 
                          justifyContent: "center", 
                          alignItems: "center",
                          gap: '10px'
                      }}>
                        <RestoreIcon 
                          style={{
                            fontSize: '20px'
                          }}
                        />
                        <Typography
                            style={{
                              color: changesDetected ? '#262626' : 'lightgrey',
                              cursor: changesDetected ? 'pointer' : 'default',
                              fontSize: '10px',
                              letterSpacing: '0.5px',
                              fontWeight: 600,
                              paddingTop: '4px'
                            }}
                          >
                            KEMBALI KE UKURAN AWAL
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                )
              }
              <Grid item xs={12} 
              style={{
                display: isMdOrUp && !isMinimized? 'block' : 'flex',
                justifyContent: isMdOrUp && !isMinimized? 'flex-start' : 'center',
              }}>
                <ClothingMeasurementDetailPanel
                  clothingType={clothingType}
                  fitType={fitType}
                  allowEditing={true}
                  currentClothingMeas={currentClothingMeas}
                  currentClothingMeasSubtextDict={subtextDict}
                  currentlyFetchingAvailability={currentlyFetchingAvail}
                  clothMeasActionsDict={inputClothMeasActionsDict}
                  isMinimized={isMinimized}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OverlayComponentBaseContainerWidth>
    </OverlayComponentBaseContainerHorizontal>
  );
  submitChildren.push(
    <OverlayComponentBaseContainerHorizontal
    alignItems='flex-end' >
      <Box>
        <Button
          onClick={() => {
            handleSubmit(currentClothingBodyMeas, currentClothingMeas);
          }}
          disabled={allowSubmitWithoutChange !== true && !changesDetected}
          style={{
            padding: '7px 25px 4.5px 25px',
            borderRadius: '20px',
            fontSize: '10.5px',
            fontWeight: 600,
            color: 'white',
            letterSpacing: '0.75px',
            backgroundColor: changesDetected || allowSubmitWithoutChange === true ? 'black' : 'lightgrey',
            margin: '10px 15px 20px 0px'
          }}
        >
          SIMPAN
        </Button>
      </Box>
    </OverlayComponentBaseContainerHorizontal>
  );

  return (
    <OverlayComponentBaseTemplate alignItems="flex-start">
      <Box
        style={isMinimized ?
          {
            width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
          } : {

          }}
      >
        {/* <OverlayComponentTitle text="Sesuaikan ukuran dengan baju favorit kamu!" /> */}
        <Box>
          {
            children
          }
        </Box>
      </Box>
      <Box style={{ width: '100%' }}>
        {
          submitChildren
        }
      </Box>
      <PageProductOverlayMeasureInstructions />
    </OverlayComponentBaseTemplate>
  );
}