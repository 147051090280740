import { AppBar, Collapse, Divider, Grid, Link, Toolbar, Typography, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { categoryMap } from 'common-ts/dist/models/CategoryList';
import {
  Route, Switch, useRouteMatch, Link as RouterLink, useParams, useHistory,
} from "react-router-dom";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import CartForOrderContent from './CartForOrderContent';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useAppSelector } from '../reduxhooks';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { CartState, CartStateItem } from 'common-ts/dist/models/Cart';
import PageCheckoutPayment from './PageCheckoutPayment';
import PageCheckoutFormsComponent from './PageCheckoutPaymentToOrder';
import Countdown from 'react-countdown';
import { ProductsList } from 'common-ts/dist/models/Product';
import { FareastButton } from './OverlayComponentButton';
import DoneIcon from '@material-ui/icons/Done';
import { PageProductSuggestions } from './PageProductSuggestions';

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import axios, { AxiosRequestConfig } from 'axios';

import { Player, Controls } from '@lottiefiles/react-lottie-player';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  fullHeight: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
  },
  titleLogoContainer: {
    height: '42px',
    marginTop: '34px',
    marginBottom: '10px',
  },
  titleLogo: {
    height: '24px',
    display: 'block', // We use block so that the img is unique horizontally in the div
    marginLeft: '40px',
  },
  checkoutFormContainer: {
    height: 'auto',
    width: '100%',
  },
  sideCartList: {
    display: 'none',
    height: '100vh',
    background: '#fafafa',
    width: '480px',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      height: 'auto',
    },
  },
  sideCartListInner: {
    background: '#fafafa',
    width: '432px',
    padding: '24px',
  },
  cartMainSegment: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 480px)',
    },
  },
  cartMobile: {
    width: 'calc(100%-48px)',
    padding: '16px 24px 26px 24px',
    backgroundColor: '#fafafa',
  },
  cartMobileAccordion: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px',
    backgroundColor: '#fafafa',
    '&:active': {
      backgroundColor: '#eaeaea',
    }
  },
  cartMobileAccordionIcon: {
    marginRight: '12px',
    color: '#222',
  },
  cartMobileAccordionText: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#222',
  },
  cartMobileAccordionIconOpenClose: {
    marginLeft: '4px',
    color: '#222',
  },
  cartMobileAccordionPrice: {
    color: '#222',
    fontSize: '14px',
    fontFamily: 'Open Sans, sans-serif',
  },
  cartMobileText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cartMobileContainer: {
    width: '100%',
  },
  nextStepButtonContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginTop: '16px',
  },
  nextStepButton: {
    marginRight: '20px',
    marginLeft: 'auto',
  },
  orderSpacer: {
    [theme.breakpoints.down('sm')]: {
      width: "100px",
      height: "30px",
    },
  },
  countdownComponent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  countdownMainText: {
    fontSize: "20px",
    fontWeight: 500,
    paddingTop: "8px",
    paddingBottom: "10px",
  },
  countdownMainTextTimeout: {
    fontSize: "24px",
    fontWeight: 500,
    paddingTop: "8px",
    paddingBottom: "16px",
  },
  countdownNumberText: {
    fontSize: "22px",
    fontWeight: 300,
    letterSpacing: "1.2px",
    fontFamily: 'Open Sans, sans-serif',
    paddingBottom: "22px",
    color: "#343434",
  },
  countdownSubTextHighlight: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#000000",
  },
  countdownSubText: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#343434",
    paddingBottom: "42px",
    textAlign: "center",
  },
  countdownSubTextTimeout: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#343434",
    paddingBottom: "4px",
    paddingTop: "4px",
    textAlign: "center",
  },
  countdownButtonText: {
    fontSize: "12px",
    letterSpacing: "0.7px",
    fontWeight: 500,
  },
  orderTrackerContainer: {
    padding: "0px 32px",
  },
  orderTrackerMainText: {
    fontSize: "20px",
    fontWeight: 500,
    paddingBottom: "2px",
  },
  orderTrackerSubText: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#343434",
    letterSpacing: "0.6px",
  },
  orderTrackerImageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingRight: "5px",
  },
  orderTrackerImage: {
    height: "100px",
    paddingTop: "15px",
    paddingBottom: "12px",
  },
  orderTrackerImageText: {
    fontSize: "18px",
    fontWeight: 600,
    paddingBottom: "21px",
  },
  estimasiPengirimanMainText: {
    fontSize: "12px",
    fontWeight: 500,
    paddingBottom: "2px",
    letterSpacing: "0.4px",
  },
  estimasiPengirimanSubText: {
    fontSize: "10px",
    fontWeight: 300,
    paddingBottom: "22px",
    letterSpacing: "0.7px",
  },
  orderProgressBarContainer: {
    marginTop: "30px",
    marginBottom: "20px",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "50px",
    paddingBottom: "80px",
  },
  orderProgressBarPointGreened: {
    width: "35px",
    height: "35px",
    borderRadius: "104px",
    backgroundColor: "#30ac0a",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  orderProgressBarPointGreyed: {
    width: "35px",
    height: "35px",
    borderRadius: "104px",
    backgroundColor: "#B9B9B9",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  orderProgressBarPoint: {
    width: "35px",
    height: "35px",
    borderRadius: "104px",
    backgroundColor: "#1c1c1c",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  orderProgressBarText: {
    textAlign: "center",
    width: "35px",
    paddingTop: "8.5px",
    fontSize: "13px",
    fontWeight: 400,
    color: "#ffffff"
  },
  orderProgressBarDoneIcon: {
    color: "#ffffff",
    paddingTop: "6px",
    width: "22px",
    height: "22px"
  },
  orderProgressPointImageIcon: {
    position: "absolute",
    top: "-60px",
  },
  orderProgressPointDescription: {
    fontSize: "10px",
    fontWeight: 300,
    textAlign: "center",
    position: "absolute",
    top: "46px",
  },
  pengirimanTrackerMainText: {
    fontSize: "20px",
    fontWeight: 600,
    letterSpacing: "0.8px",
    paddingBottom: "2px",
    marginTop: "14px",
  },
  pengirimanTrackerSubBoldText: {
    fontSize: "13px",
    fontWeight: 600,
    letterSpacing: "0.5px",
    color: "#232323",
    marginTop: "16px",
  },
  pengirimanTrackerSubLightText: {
    fontSize: "12px",
    letterSpacing: "0.3px",
    fontWeight: 300,
    color: "#454545",
    marginTop: "3px"
  },
  pengirimanTrackerHistory: {
    fontSize: "19px",
    fontWeight: 500,
    letterSpacing: "0.6px",
    paddingBottom: "2px",
    marginTop: "27px",
    marginBottom: "9px",
  },
  pengirimanTrackerHistorySubText: {
    fontSize: "13px",
    letterSpacing: "0.3px",
    fontWeight: 500,
    color: "#343434",
    paddingTop: "5.5px",
    marginLeft: "12px"
  },
  pengirimanTrackerHistorySubTextTime: {
    fontSize: "12px",
    letterSpacing: "0.3px",
    fontWeight: 300,
    color: "#676767",
    paddingTop: "3.5px",
    marginLeft: "12px"
  }
}),
);

const imageLottieArray = [
  "https://studiosclo.uk/general/Lottie_Cutting.json",
  "https://studiosclo.uk/general/Lottie_Jahit.json",
  "https://studiosclo.uk/general/Lottie_Delivery.json",
  "https://studiosclo.uk/general/Lottie_Done_Loop.json",
  "https://studiosclo.uk/general/Lottie_Stock.json"
];
const imageLottieArrayStyle = [
  { // Cutting
    height: '204px',
    marginTop: "18px",
    marginBottom: "18px"
  },
  { // Jahit
    height: '212px',
    marginTop: "14px",
    marginBottom: "14px"
  },
  { // Delivery
    height: '232px',
    marginTop: "4px",
    marginBottom: "4px"
  },
  { // Done
    height: '174px', 
    marginTop: "33px",
    marginBottom: "33px"
  },
  { // Stock
    height: '200px',
    marginTop: "20px",
    marginBottom: "20px"
  }
];

const imageSrcArray = [
  "https://studiosclo.uk/general/Thumb_Cutting.png",
  "https://studiosclo.uk/general/Thumb_Jahit.png",
  "https://studiosclo.uk/general/Thumb_Delivery.png",
  "https://studiosclo.uk/general/Thumb_Done.png" 
];
const imageSrcArrayStyles = [
  { // Cutting
    width: "54px",
  },
  { // Jahit
    width: "56px",
  },
  { // Delivery
    marginTop: "-2px",
    width: "66px",
  },
  { // Done
    marginTop: "2px",
    width: "54px",
  }
];

const nameSrcArray = [
  "Proses Potong",
  "Proses Jahit",
  "Sedang Dikirim",
  "Sudah Sampai" 
];

function cleanUpHistories(histories: any[]) {
  const repeatedTimeDict: {[key:string]: boolean} = {};
  const repeatedTextDict: {[key:string]: boolean} = {};

  const filteredArray: any[] = [];

  for (var i = 0; i < histories.length; i++) {
    if (histories[i].status === "Pricing Updated") {
      continue;
    }
    if (!repeatedTextDict[histories[i].status]) {
      filteredArray.push(histories[i]);
    }

    repeatedTimeDict[histories[i].created_at] = true;
    repeatedTextDict[histories[i].status] = true;
  }

  return filteredArray;
}

export function getHash(input: string){
  var hash = 0, len = input.length;
  for (var i = 0; i < len; i++) {
    hash  = ((hash << 5) - hash) + input.charCodeAt(i);
    hash |= 0; // to 32bit integer
  }
  return hash;
}

export default function PageOrder() {
  let { orderId } = useParams<{ orderId: string }>();
  const [orderObject, setOrderObject] = React.useState<any>(null);
  const [shipmentTrackingObject, setShipmentTrackingObject] = React.useState<any>(null);
  const classes = useStyles();
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("md"));
  const history = useHistory();
  const [cartSegmentOpen, setCartSegmentOpen] = React.useState<boolean>(true);
  const [paymentStep, setPaymentStep] = React.useState<"waitingForPayment" | "unsubmitted" | "verifyPaymentWithServer" | "success" | "paymentFailed" | "rejected">("waitingForPayment");

  const [isTimedOut, setIsTimedOut] = React.useState<boolean>(false);

  const fetchOrderPublic = (orderId: string) => {
    let config : AxiosRequestConfig = {
      method: 'get',
      url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/order-public',
      params: {
        orderId: orderId
      }
    };

    axios(config).then((response) => {
      if (response.data?.orderId) {
        setOrderObject(response.data);

        let configTrack : AxiosRequestConfig = {
          method: 'post',
          url: 'https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/kirimin-aja-proxy',
          data: JSON.stringify({
            "axiosConfig": {
              "method": "post",
              "url": "https://client.kiriminaja.com/api/mitra/tracking",
              "data": {
                "order_id" : response.data?.courierOrderId
              }
            }
          })
        };
  
        axios(configTrack).then((response2) => {
          if (response2.data?.returnBody) {
            setShipmentTrackingObject(response2.data.returnBody);
          }
        });
      }
    });
  }

  useEffect(() => {
    fetchOrderPublic(orderId);
  }, [orderId]);

  if (orderObject?.orderId !== orderId) {
    return (
      <Box className={classes.fullHeight}>
        <Box className={classes.cartMainSegment}>
          <Box justifyContent="center" className={classes.titleLogoContainer} >
            <Link component={RouterLink} to={categoryMap["HOME"]} >
              <img className={classes.titleLogo} src={process.env.PUBLIC_URL + '/studios-wordmark-final.svg'} alt="maja logo"/>
            </Link>
          </Box>
        </Box>
      </Box>);
  }

  const isPaid = orderObject.paymentStatus === "capture" 
    || orderObject.paymentStatus === "capture_stockonly" 
    || orderObject.paymentStatus === "settlement";
  const shipmentStatus = orderObject.shipmentStatus;
  const shipmentStepNumber = 
    shipmentStatus === "BELUM MULAI" ? 0 :
    shipmentStatus === "STOK SUDAH DIAMBIL" ? 0 :
    shipmentStatus === "SUDAH DIPOTONG" ? 1 :
    (shipmentStatus === "SHIPPED" && shipmentTrackingObject?.status_code === 200) ? 3 : 2;

  const productsList: ProductsList = {
    collectionsDictionary: {},
    productsDictionary: {},
  
    discoveryCollectionsOrder: [],
    efficientCollectionsOrder: [],
    efficientProductsOrder: [],
    discoveryCollectionsOrderDict: {},
  
    productViewEventTimerDict: {},
  
    status: APIRequestStatus.Idle,
    error: null,
  
    productSizeAvailabilityDict: {},
  
    statusSizeAvailability: APIRequestStatus.Idle,
  }
  if (orderObject?.cartProductList?.length) {
    for (var i = 0; i < orderObject.cartProductList.length; i++) {
      var cartProductItem = orderObject.cartProductList[i];
      productsList.productsDictionary[cartProductItem.productId] = cartProductItem.productDetail as any;
    }
  }
  
  // We give an expiry time of 60 minutes
  var orderTime = new Date(orderObject.creationDateTime);
  var expireTime = new Date(orderObject.creationDateTime).setTime(orderTime.getTime() + (60 * 60 * 1000));

  const countdownRenderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return (
      <Box className={classes.countdownComponent}>
        <Typography className={classes.countdownMainTextTimeout} >Order Telah Dibatalkan</Typography>
        <Typography className={classes.countdownSubTextTimeout} 
        >Stok <span className={classes.countdownSubTextHighlight}>telah kami kembalikan</span> ke sistem.
        <br/>Buat order baru untuk membeli produk.</Typography>
        <FareastButton
          aria-label="balik"
          onClick={() => {
            history.push("/shop");
          }}
          buttonMarginLeftRight='0px'
          buttonMarginTopBottom='20px'
          style={{
            marginBottom: "48px",
          }}
        >
          <Typography className={classes.countdownButtonText}>
            LIHAT SEMUA PRODUK
          </Typography>
        </FareastButton>
      </Box>);
    } else {
      // Render a countdown
      return (
      <Box className={classes.countdownComponent}>
        <Typography className={classes.countdownMainText} >Selesaikan Pembayaran</Typography>
        <span className={classes.countdownNumberText}>{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</span>
        <Typography className={classes.countdownSubText} 
        >Kami <span className={classes.countdownSubTextHighlight}>menjaga stok pesanan kamu</span>
        <br/>selama waktu pembayaran</Typography>
      </Box>)
    }
  };

  const OrderProgressBar = ({stepNumber}: any) => {
    return (
      <Box className={classes.orderProgressBarContainer}>
        <ProgressBar percent={stepNumber * 100 / 3} height={3} filledBackground="#1c1c1c" unfilledBackground="#B9B9B9">
          <Step transition="scale">
            {({ accomplished }) => (
              <Box className={accomplished ? classes.orderProgressBarPoint : classes.orderProgressBarPointGreyed}>
              <img 
              src={imageSrcArray[0]} 
              alt="point-0"
              style={imageSrcArrayStyles[0]} 
              className={classes.orderProgressPointImageIcon}/>
                <Typography className={classes.orderProgressBarText}>1</Typography>
                <Typography className={classes.orderProgressPointDescription}>{nameSrcArray[0]}</Typography>
              </Box>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Box className={accomplished ? classes.orderProgressBarPoint : classes.orderProgressBarPointGreyed}>
              <img 
              src={imageSrcArray[1]} 
              alt="point-1"
              style={imageSrcArrayStyles[1]} 
              className={classes.orderProgressPointImageIcon}/>
                <Typography className={classes.orderProgressBarText}>2</Typography>
                <Typography className={classes.orderProgressPointDescription}>{nameSrcArray[1]}</Typography>
              </Box>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Box className={accomplished ? classes.orderProgressBarPoint : classes.orderProgressBarPointGreyed}>
              <img 
              src={imageSrcArray[2]} 
              alt="point-2"
              style={imageSrcArrayStyles[2]} 
              className={classes.orderProgressPointImageIcon}/>
                <Typography className={classes.orderProgressBarText}>3</Typography>
                <Typography className={classes.orderProgressPointDescription}>{nameSrcArray[2]}</Typography>
              </Box>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Box className={accomplished ? classes.orderProgressBarPointGreened : classes.orderProgressBarPointGreyed}>
                <img 
                src={imageSrcArray[3]} 
                alt="point-3"
                style={imageSrcArrayStyles[3]} 
                className={classes.orderProgressPointImageIcon}/>
                <DoneIcon className={classes.orderProgressBarDoneIcon} />
                <Typography className={classes.orderProgressPointDescription}>{nameSrcArray[3]}</Typography>
              </Box>
            )}
          </Step>
        </ProgressBar>
      </Box>
    )
  }
  
  return (
    <Box className={classes.fullHeight}>
      <Box className={classes.cartMainSegment}>
        <Box justifyContent="center" className={classes.titleLogoContainer} >
          <Link component={RouterLink} to={categoryMap["HOME"]} >
            <img className={classes.titleLogo} src={process.env.PUBLIC_URL + '/studios-wordmark-final.svg'} alt="maja logo"/>
          </Link>
        </Box>
        <Box className={classes.checkoutFormContainer}>
          {
            isPaid ? 
            <Box className={classes.orderTrackerContainer}>
              <Typography className={classes.orderTrackerMainText}>Track Order</Typography>
              <Typography className={classes.orderTrackerSubText}>ID {orderId.toUpperCase()}</Typography>
              <Box className={classes.orderTrackerImageContainer}>
                {
                  shipmentStepNumber < 3 ?
                  <Player
                    key='regular-animation'
                    autoplay
                    loop
                    src={imageLottieArray[shipmentStepNumber]}
                    style={imageLottieArrayStyle[shipmentStepNumber]}
                  >
                  </Player>:
                  <Player
                    key='done-animation'
                    autoplay
                    loop
                    src={imageLottieArray[3]}
                    style={imageLottieArrayStyle[3]}
                  >
                  </Player>
                }
                <Typography className={classes.orderTrackerImageText}>{nameSrcArray[shipmentStepNumber]}</Typography>
                {
                  shipmentStepNumber < 2 ? 
                  <>
                    <Typography className={classes.estimasiPengirimanMainText}>Estimasi Pengiriman</Typography>
                    <Typography className={classes.estimasiPengirimanSubText}>1 - 2 Hari Kerja</Typography>
                  </>
                  : null
                }
                {
                  shipmentStepNumber === 2 && orderObject.courierSelectionObject?.service_name && orderObject.courierAwb ? 
                  <>
                    <Typography className={classes.estimasiPengirimanMainText}>{orderObject.courierSelectionObject.service_name}</Typography>
                    <Typography className={classes.estimasiPengirimanSubText}>{"Resi " + orderObject.courierAwb}</Typography>
                  </>
                  : null
                }
              </Box>
              <OrderProgressBar stepNumber={shipmentStepNumber} />
              {
                (shipmentStepNumber === 2 || shipmentStepNumber === 3) && orderObject.courierSelectionObject?.service_name && orderObject.courierAwb ? 
                <>
                  <Typography className={classes.pengirimanTrackerMainText}>Detail Pengiriman</Typography>
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography className={classes.pengirimanTrackerSubBoldText}>Pilihan Kurir</Typography>
                      <Typography className={classes.pengirimanTrackerSubLightText}>{orderObject.courierSelectionObject.service_name}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography className={classes.pengirimanTrackerSubBoldText}>No. Resi</Typography>
                      <Typography className={classes.pengirimanTrackerSubLightText}>{orderObject.courierAwb}</Typography>
                    </Grid>
                  </Grid>
                  <Typography className={classes.pengirimanTrackerSubBoldText}>Alamat Penerima</Typography>
                  <Typography className={classes.pengirimanTrackerSubLightText}>
                    {orderObject.address + " " + orderObject.kotaKecamatanText + " " + orderObject.kodePos
                    + (orderObject?.keterangan ? (" (" + orderObject.keterangan + ")"): "")}
                  </Typography>
                  <Typography className={classes.pengirimanTrackerSubLightText}>
                    {orderObject.phoneNumber.substr(0, 4)
                    + '-' + orderObject.phoneNumber.substr(4, 4)
                    + (orderObject.phoneNumber.length > 8 ? ('-' + orderObject.phoneNumber.substr(8)) : "")}
                  </Typography>
                  <Typography className={classes.pengirimanTrackerSubBoldText}>Estimasi Kedatangan</Typography>
                  <Typography className={classes.pengirimanTrackerSubLightText}>{orderObject.courierSelectionObject.etd + " Hari Kerja"}</Typography>
                  <Typography className={classes.pengirimanTrackerHistory}>Histori Pengiriman</Typography>

                  <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", paddingBottom: "40px" }}>
                    {
                      shipmentTrackingObject?.histories ? 
                      cleanUpHistories(shipmentTrackingObject?.histories)?.map((item: any, index: number, array: any) => {
                        return (
                          <div>
                            <div style={{
                              display: 'flex',
                              position: 'relative',
                              listStyle: 'none',
                              minHeight: '56px',
                            }}>
                              <div style={{display: "flex", alignItems: "center", flexDirection: "column", paddingLeft: "6px"}}>
                                <span style={{
                                  display: "flex",
                                  padding: '4px',
                                  alignSelf: 'baseline',
                                  marginTop: '8px',
                                  borderStyle: 'solid',
                                  borderWidth: '2px',
                                  borderRadius: '50%',
                                  marginBottom: '8px',
                                  color: '#343434',
                                  borderColor: 'transparent',
                                  backgroundColor: '#343434'
                                }}></span>
                                {
                                  index === (array.length - 1) ?
                                  null:
                                  <span style={{
                                    width: '1px',
                                    flexGrow: '1',
                                    backgroundColor: '#343434',
                                    listStyle: 'none'
                                  }}></span>
                                }
                              </div>
                              <div style={{display: "flex", alignItems: "start", flexDirection: "column"}}>
                                <Typography className={classes.pengirimanTrackerHistorySubText}>{item.status}</Typography>
                                <Typography className={classes.pengirimanTrackerHistorySubTextTime}>
                                  {item.created_at.substring(0, 10) + " " + item.created_at.substring(11,16) + " WIB"}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        );
                      }):
                      null
                    }
                  </div>
                </>:
                null
              }
            </Box>:
            null
          }
          {
            !isPaid ? 
            <Countdown
              date={ orderObject.paymentStatus === "abandoned" ? orderTime : expireTime }
              renderer={countdownRenderer}
              onComplete={(timeDelta, completedOnStart) => {
                setIsTimedOut(true);
              }}
            />:
            null
          }
          {
            isMdOrUp ?
            null :
            <Box className={classes.cartMobileContainer}>
              <Divider/>
              <Box className={classes.cartMobileAccordion} onClick={() => {}}>
                <Box className={classes.cartMobileText}>
                  <ShoppingCartOutlinedIcon className={classes.cartMobileAccordionIcon} />
                  <Typography className={classes.cartMobileAccordionText}>
                    {
                      cartSegmentOpen ?
                      "Tutup Detail Pembelian" :
                      "Buka Detail Pembelian"
                    }
                  </Typography>
                  {
                    cartSegmentOpen ?
                    <ExpandLess fontSize="small" className={classes.cartMobileAccordionIconOpenClose}/> :
                    <ExpandMore fontSize="small" className={classes.cartMobileAccordionIconOpenClose}/>
                  }
                </Box>
                <Box>
                  <Typography className={classes.cartMobileAccordionPrice}>
                    {"Rp " + orderObject?.totalPaymentAmount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                </Box>
              </Box>
              <Divider/>
              <Collapse in={cartSegmentOpen}>
                <Box className={classes.cartMobile}>
                  <CartForOrderContent orderObject={orderObject} 
                    productsList={productsList}
                    isModifiable={false} 
                    withPromoField={false}
                    showWarning
                    />
                </Box> 
                <Divider/>
              </Collapse>
            </Box>
          }
          <Box className={classes.orderSpacer}></Box>
          {
            !isPaid && !isTimedOut ? 
            <PageCheckoutFormsComponent 
              orderId={orderObject.orderId} 
              totalPayment={orderObject.totalPaymentAmount} 
              paymentStep={paymentStep}
              onSnapPaymentSuccess={() => {
                // Re-fetch server to get current order status (reload) after 3 s
                // TODO FETCH SERVER
                setTimeout(() => {
                  fetchOrderPublic(orderId);
                }, 3000);
              }}
              onOtherPaymentConfirmation={() => {
                // Do nothing, wait for user to send confirmation to us
              }}
              /> :
              <PageProductSuggestions
                seedNumber={getHash(orderObject.orderId + (new Date().toISOString()).substring(0, 15))} 
                titleText='Lihat Produk Lainnya'
                marginTop='90px'
                marginBottom='0px' />
          }
        </Box>
      </Box>
      <Box className={classes.sideCartList} >
        <Box className={classes.sideCartListInner}>
          <CartForOrderContent orderObject={orderObject} 
            productsList={productsList} 
            isModifiable={false} 
            withPromoField={false}
            showWarning
            />
        </Box>
      </Box>
    </Box>
  );
}