import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { useAppSelector } from '../reduxhooks';
import OverlayComponentChoice from './OverlayComponentChoice';
import OverlayComponentTitle from './OverlayComponentTitle';
import { useEffect } from 'react';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
}),
);

interface Props {
  onFetchMeasEstimateSuccess: () => void
}

export default function PageCheckoutMeasurements({ onFetchMeasEstimateSuccess }: Props) {
  const classes = useStyles();

  const [finishedLoading, setFinishedLoading] = React.useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setFinishedLoading(true);
    }, 3000)
  }, []);

  useEffect(() => {
    if (finishedLoading) {
      setTimeout(() => {
        onFetchMeasEstimateSuccess();
      }, 400);
    }
  }, [finishedLoading]);

  return (
    <>
      <Box style={{
        padding: "0px 20px",
        marginTop: '5em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Typography style={{ marginBottom: "60px" }}>
          Generating Your Measurements...
        </Typography>
        <CircularProgress/>
      </Box>
    </>
  );
}