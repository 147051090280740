import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import {
  Box, createStyles, Dialog, IconButton, makeStyles, Theme, Typography
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import {
  UniversalSizeActiveScreen, updateCurrentScreen
} from '../redux/UIOverlayUniversalSizeRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { FareastButton } from './OverlayComponentButton';
import OverlayViewFavoriteModifySize from './OverlayViewGenerateMeasureMethod3Favorite1ModifySize';
import { CalculateClothBodyMeasurementsFromBodyMeasurements, CalculateClothingMeasurementsFromBodyMeasurements, ClothingType, FitType, RoundClothingMeasurementsToHalfPoint } from 'common-ts/dist/models/UserMeasurements';
import { RegularSizeValue } from 'common-ts/dist/models/Cart';
import { PreviousSizeSuggestionElement } from './OverlayViewUniversalSize1SizeSelect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 1) + " !important") as any,
    },
    backButton: {
      position: 'absolute',
      top: theme.spacing(3),
      left: theme.spacing(2),
      borderRadius: '50%',
      padding: theme.spacing(1),
    },
    backButtonIcon: {
      padding: '0',
      color: 'black',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '1em 1.5em'
    },
    pageItemsContainer: {
      display: 'grid',
      margin: '2em 1em',
      gridTemplateColumns: '1fr 10fr',
      rowGap: '3em',
    },
    pageTitle: {
      fontSize: '1.1em',
      width: '100%',
      fontWeight: 550,
      marginTop: '5em',
    },
    pageItemIcon: {
      paddingTop: '0.5em',
    },
    pageItemTextBox: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    pageItemTitle: {
      paddingTop: '0.2em',
      fontSize: '0.8em',
      fontWeight: 500,
      gridRow: '1',
    },
    pageItemDescription: {
      gridColumn: '2',
      paddingTop: '0.2em',
      paddingRight: '0.5em',
      fontSize: '0.6em',
      gridRow: '2',
    },
    buatUkuranButton: {
      marginTop: '1em',
      marginLeft: 'auto',
    },
  }),
);

interface Props {
  handleBack: () => void
  handleNext: (updatedCurrentFlowData: any) => void
  currentFlowData: any
}

export default function PersonalSizeCaraBuat({ handleBack, handleNext, currentFlowData }: Props) {
  const classes = useStyles()

  if (!currentFlowData?.clothingType) {
    return null;
  }

  return <>
    <Box
      className={classes.backButton}
      onClick={handleBack}
    >
      <IconButton className={classes.backButtonIcon}>
        <ArrowBack />
      </IconButton>
    </Box>
    <Box className={classes.dialogContent}>
      <Typography className={classes.pageTitle} style={{marginTop: '70px'}}>
        Ada yang mau diubah?
      </Typography>
      <Typography className={classes.pageItemDescription} style={{margin: '5px 0px 23px', color: '#535353', fontWeight: 300, letterSpacing: '0.1vw'}}>
        Sesuaikan ukuran kamu yang terakhir.
      </Typography>
      <PreviousSizeSuggestionElement
        srcImageUrl={currentFlowData.productDisplayImage}
        productName={currentFlowData.productName}
        measurementName={currentFlowData.measurementName}
        purchaseDateTime={currentFlowData.purchaseDateString}
        isSelected={false}
        purchaseCount={currentFlowData.purchaseCount}
        clothingType={currentFlowData.clothingType}
        fitType={currentFlowData.fitType}
        userMeasurementsForItem={undefined}
        handleClick={() => {
        }}
      />
      <OverlayViewFavoriteModifySize
        showNumberOfSimiliarProduct={false}
        numberOfSimiliarProduct={2}
        startingClothingBodyMeasurements={
          CalculateClothBodyMeasurementsFromBodyMeasurements(currentFlowData.clothingType as ClothingType, 
            currentFlowData.fitType as FitType, 
            currentFlowData.clothMeasValue as any, RegularSizeValue.L, [])}
        startingClothingMeasurements={
          RoundClothingMeasurementsToHalfPoint(
          CalculateClothingMeasurementsFromBodyMeasurements(currentFlowData.clothingType as ClothingType, 
            currentFlowData.fitType as FitType, 
            currentFlowData.clothMeasValue as any, RegularSizeValue.L))}
        clothingType={currentFlowData.clothingType as ClothingType}
        fitType={currentFlowData.fitType as FitType}
        productId={currentFlowData?.productId}
        productDetail={{}}
        key2={currentFlowData.clothingType + "-" + currentFlowData.fitType}
        onlyUseServiceability={true}
        allowSubmitWithoutChange={true}
        handleSubmit={(value, valueClothing) => {
          handleNext({
            clothMeasValue: value
          });
        }}
        isMinimized={true}
      />
    </Box>
  </>
}