import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Slide,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TransitionProps } from "@material-ui/core/transitions";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { UIOverlayViewStatus } from "common-ts/dist/models/UIOverlayViewStatus";
import {
  BodyMeasurementsForSize,
  CalculateClothingMeasurementsFromBodyMeasurements,
  ClothingType,
  ClothingTypeDisplayString,
  FitType,
  FitTypeDisplayString,
  GetKey2CategoryClothingSizeMeasurementValues,
  RoundClothingMeasurementsToHalfPoint,
  UserClothTypeSpecificBodyMeasurements,
} from "common-ts/dist/models/UserMeasurements";
import {
  inputMeasurementsForm,
  postClothingMeasurement,
  postClothingMeasurementPreference,
  resetUserMeasurementDraft,
  saveLocallyClothingMeasurementPreference,
} from "../redux/MeasurementsRedux";
import { useAppDispatch, useAppSelector } from "../reduxhooks";
import OverlayComponentCanvas from "./OverlayComponentCanvasFitAnimation";
import { RegularSizeValue } from "common-ts/dist/models/Cart";
import {
  ClothingMeasurementDetailPanel,
  getClothingMeasurementsSubtext,
} from "./OverlayViewGenerateMeasureMethod3Favorite1ModifySize";
import { VarietySliderComponent } from "./OverlayViewUniversalSize4Result";
import { getSortKey } from "./OverlayViewPersonalSize";
import { Close } from "@material-ui/icons";
import {
  closeOverlayAndReset,
  setValues,
} from "../redux/UIOverlayQRResultRedux";
import { FareastButton, FareastButtonWhite } from "./OverlayComponentButton";
import { setPilihUkuranDataValues } from "../redux/UIOverlayMeasurementSelectionViewRedux";
import { formatTimestampSize } from "./PageProductDetailSizeSelection";
import { DialogContent } from "@mui/material";

interface stylesProps {
  isFullScreen: boolean;
}

const useStyles = ({ isFullScreen }: stylesProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      dialogContainer: {
        zIndex: (theme.zIndex.modal + 2 + " !important") as any, // LEVEL 2 IN Z INDEX
        "& .MuiPaper-root": {
          borderRadius: isFullScreen ? "0px" : "30px",
          margin: isFullScreen ? "0px" : "0px 16px",
          maxHeight: "calc(100% - 16px)",
          maxWidth: isFullScreen ? "" : "380px",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      },
      dialogContentContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      },
      toggleSignUpSignInContainer: {
        paddingTop: "15px",
        paddingBottom: "30px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
      titleLogoContainer: {
        paddingTop: "30px",
        paddingBottom: "20px",
        height: "42px",
      },
      titleLogoDesktop: {
        height: "24px",
      },
      canvasContainer: {
        width: "100%",
        height: "100%",
        [theme.breakpoints.only("xs")]: {
          height: "40vw",
        },
        position: "relative",
      },
      popupTitle: {
        padding: "0.5em 0.5em 0 0.5em",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      popupTitlePilihUkuranText: {
        fontSize: "12px",
        color: "#D1D1D1",
        fontWeight: 300,
        padding: "16px 0px 0px 18px",
      },
      popupCloseButton: {
        marginRight: "3px",
      },
    })
  );

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayView() {
  const overlayView = useAppSelector((state) => state.overlayQRResultView);
  const dispatch = useAppDispatch();
  const classes = useStyles({ isFullScreen: false })();

  const clothingType = overlayView.clothingType;
  const fitType = overlayView.fitType;
  const measurementName = overlayView.measurementName;
  const numberOfSimiliarProducts = overlayView.numberOfSimiliarProducts;

  function closeOverlayAndResetPopup() {
    dispatch(closeOverlayAndReset());
  }

  return (
    <Dialog
      maxWidth="lg"
      fullScreen={false}
      open={overlayView.status === UIOverlayViewStatus.On}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeOverlayAndResetPopup}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialogContainer}
    >
      <DialogTitle style={{ padding: "0px 0px" }}>
        <Box className={classes.popupTitle}>
          <Typography className={classes.popupTitlePilihUkuranText}>
            HASIL PERUBAHAN UKURAN
          </Typography>
          <IconButton
            className={classes.popupCloseButton}
            edge="end"
            color="inherit"
            onClick={closeOverlayAndResetPopup}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        style={{
          paddingBottom: "0px",
          overflowY: 'hidden'
        }}
      >
        <Box className={classes.dialogContentContainer}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "12.5px",
              marginBottom: "25px",
            }}
          >
            <Typography
              style={{
                fontSize: "21px",
                letterSpacing: "0.1px",
                fontWeight: 600,
              }}
            >
              Ukuranmu Sudah Disimpan !
            </Typography>
            <div>
              <Typography
                style={{
                  fontSize: "9px",
                  letterSpacing: "0.25px",
                  fontWeight: 300,
                  color: "#989898",
                }}
              >
                NAMA UKURAN
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  letterSpacing: "0.25px",
                  fontWeight: 300,
                  color: "#989898",
                }}
              >
                {measurementName}
              </Typography>
            </div>
            <Typography
              style={{
                fontSize: "12px",
              }}
            >
              Gunakan ukuran terbaru kamu untuk {numberOfSimiliarProducts}{" "}
              Produk <br /> di kategori{" "}
              {ClothingTypeDisplayString[clothingType as ClothingType]}{" "}
              {FitTypeDisplayString[fitType as FitType]}
            </Typography>
          </div>

          <Box
            onClick={closeOverlayAndResetPopup}
            style={{
              width: "90%",
              textAlign: "center",
              backgroundColor: "#232323",
              borderRadius: 20,
              padding: "6px 15px 3px 15px",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                letterSpacing: "0.25px",
                fontWeight: 400,
                color: "#fefefe",
              }}
            >
              LIHAT PRODUK SEKARANG
            </Typography>
          </Box>
          <div
            style={{
              width: "100%",
              marginBottom: "-5px",
            }}
          >
            <img
              src="https://studiosclo.uk/general/QR_RESULT_IMAGE.png"
              style={{
                width: "100%",
              }}
            />
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
