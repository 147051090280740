import { Box, Button, Typography } from '@material-ui/core';
import { Mixpanel } from '../mixpanel';
import { RegularSizeValue, SizeType } from 'common-ts/dist/models/Cart';
import { BodyMeasurements, CalculateClothBodyMeasurementsFromBodyMeasurements, CalculateClothingMeasurementsFromBodyMeasurements, ClothingMeasurements, ClothingType, FitType, RoundClothingMeasurementsToHalfPoint, UserBodyMeasurements, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { inputBodyMeasurementsForm, inputMeasurementsForm, postBodyMeasurement, postClothingMeasurement, resetUserMeasurementDraft } from '../redux/MeasurementsRedux';
import { closeOverlayAndReset, setMeasurementObjectDraftValue } from '../redux/UIOverlayUniversalSizeCreateMeasRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import PersonalSizeUkurTiapBagianV2 from './OverlayViewUniversalSizeCreateMeas1UkurTiapBagian';
import OverlayViewSaveMeasName from './OverlayViewUniversalSizeCreateMeas2SaveScreen';
import OverlayViewBackToShop from './OverlayViewUniversalSizeCreateMeas3BackToShopScreen';
import OverlayViewFavoriteModifySize from './OverlayViewGenerateMeasureMethod3Favorite1ModifySize';
import { useState } from 'react';
import { openOverlay as openOverlayQRResult, setValues as setValuesQRResult } from '../redux/UIOverlayQRResultRedux';
import ProductOnUser from 'common-ts/dist/models/Product';
import { closeOverlayAndReset as closeOverlayAndResetSelection } from '../redux/UIOverlayMeasurementSelectionViewRedux';
import { UkuranBadanSegment } from './PageProductDetailSizeOptions';
import PersonalSizeNeedToCreate from './OverlayViewUniversalSizeCreateMeas0NeedToComplete';
import { setValues as setValuesUnivSizeCreateView, openOverlay as openOverlayUnivSizeCreateView } from '../redux/UIOverlayUniversalSizeCreateMeasRedux';

// Determines which flow is used based on starting parameters
export interface FlowSelectionData {
  editedSourceUserMeasurement: UserClothTypeSpecificBodyMeasurements | undefined
  sourceUserMeasurement: UserBodyMeasurements | UserClothTypeSpecificBodyMeasurements | undefined
  clothingType: ClothingType
  fitType: FitType
  productDetail?: ProductOnUser
  useDialog: boolean
  origin: 'default' | 'order' | 'qr',
  displayImagesArray: string[],
  missingMeas: string[],
}

// State data for flow
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateData {
  currentScreen: string
  titleNumber: number
  currentUserMeasurement: UserClothTypeSpecificBodyMeasurements
  currentUserBodyMeasurement: UserBodyMeasurements
}

// Callback to change state data for flow. 
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateCallback {
  delayedDispatch: (currentScreen: string) => void
  setTitleNumber: (titleNumber: number) => void
}

//
// RENDERER 
// Master function that is used to select and pass data to the block 
// selected using the flow selection data.
//
export function FlowComponentMasterPersonalSize(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  
  let children: JSX.Element[] = [];
  children = FlowComponentMensTops(flowSelectionData, flowStateData, flowStateCallback);
  
  // We choose the right block to use with the flow selection data.
  return (
    <Box>
      {
        children
      }
    </Box>
  );
}

function FlowComponentMensTops(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  const dispatch = useAppDispatch();

  let titleNumber = -1;

  let delayedDispatch = flowStateCallback.delayedDispatch;

  let children: JSX.Element[] = [];

  const [measurementName, setMeasurementName] = useState(flowSelectionData.sourceUserMeasurement?.measurementName || 'Ukuran Custom')

  if (flowSelectionData.sourceUserMeasurement === undefined) {
    return children;
  }

  switch (flowStateData.currentScreen) {
    case "startComplete":
      children.push(
        <PersonalSizeNeedToCreate
          missingMeas={flowSelectionData.missingMeas}
          handleNext={() => {
            const updateDateTimeString = new Date().toISOString();
            const inputBodyMeasurementsFormDraft: UserBodyMeasurements = {
              ...flowSelectionData.sourceUserMeasurement,
              updateDateTime: updateDateTimeString,
              bodyOrClothingMeasurements: "BODY",
              creationMethod: 'online-body',
              userSelfInputMeasurements: {},
              key3: updateDateTimeString,
              key23: flowSelectionData.sourceUserMeasurement?.key2 + '-' + updateDateTimeString,
              key123: flowSelectionData.sourceUserMeasurement?.key12 + '-' + updateDateTimeString,
            };
            dispatch(inputBodyMeasurementsForm(inputBodyMeasurementsFormDraft));
            delayedDispatch("ukurTiapBagian");
          }}
        />
      )
      break;
    case "ukurTiapBagian":
      children.push(<PersonalSizeUkurTiapBagianV2
        issues={flowSelectionData.missingMeas}
        clothingType={flowSelectionData.clothingType as ClothingType}
        fitType={flowSelectionData.fitType as FitType}
        userSelfInputMeasurements={{}}
        generatedMeasurements={{}}
        generatedMeasurementsRange={{}}
        generatedMeasurementsSafe={{}}
        handleBack={() => {
          delayedDispatch("startComplete");
        }}
        handleEachBack={(issues, newIssueIndex) => {
          if (issues === undefined) {
            return;
          }
          let payloadBody: UserBodyMeasurements = {
          }
          payloadBody.userSelfInputMeasurements = {};
          for (let i = 0; i < newIssueIndex; i++) {
            if (!flowStateData.currentUserBodyMeasurement.userSelfInputMeasurements) {
              break;
            }
            payloadBody.userSelfInputMeasurements[issues[i] as keyof BodyMeasurements] = flowStateData.currentUserBodyMeasurement.userSelfInputMeasurements[issues[i] as keyof BodyMeasurements] as any;
          }
          dispatch(inputBodyMeasurementsForm(payloadBody));
        }}
        handleEachSubmit={(issues, newIssueIndex, currentValue) => {
          if (issues === undefined) {
            return;
          }
          let payloadBody: UserBodyMeasurements = {
          }
          payloadBody.userSelfInputMeasurements = {};
          for (let i = 0; i < newIssueIndex - 1; i++) {
            if (!flowStateData.currentUserBodyMeasurement.userSelfInputMeasurements) {
              break;
            }
            payloadBody.userSelfInputMeasurements[issues[i] as keyof BodyMeasurements] = flowStateData.currentUserBodyMeasurement.userSelfInputMeasurements[issues[i] as keyof BodyMeasurements] as any;
          }
          payloadBody.userSelfInputMeasurements[issues[newIssueIndex - 1] as keyof BodyMeasurements] = currentValue;

          if (issues.length === newIssueIndex) {
            const adjustedMeasurements: BodyMeasurements = {...flowStateData.currentUserBodyMeasurement.adjustedMeasurements};
  
            const selfInputMeasurements = payloadBody.userSelfInputMeasurements;
            for (var i = 0; i < issues.length; i += 1) {
              const selfInputMeasurementsKey = issues[i] as keyof BodyMeasurements;
              adjustedMeasurements[selfInputMeasurementsKey] = selfInputMeasurements[selfInputMeasurementsKey];
            }
  
            // NORMALIZE LINGKAR PERUT (MAKE BIGGER)
            if (payloadBody?.["adjustedMeasurements"]?.["lingkarPerut"]) {
              payloadBody["adjustedMeasurements"]["lingkarPerut"] = Math.round(payloadBody["adjustedMeasurements"]["lingkarPerut"] / 0.916);
            }

            payloadBody.adjustedMeasurements = adjustedMeasurements;
          }

          dispatch(inputBodyMeasurementsForm(payloadBody));
        }}
        handleFinish={() => {
          dispatch(postBodyMeasurement({
            clothingType: flowSelectionData.clothingType,
            fitType: flowSelectionData.fitType,
            productDetail: flowSelectionData.productDetail
          }));
          dispatch(closeOverlayAndReset());
        }}/>);
      break;
    case "editingScreen":
      children.push(
        <>
          <Typography style={{margin: '0px 0px 10px 4px', fontWeight: 600}}>
            Pakai Profil Personal Fit Kamu
          </Typography>
        </>
      )
      children.push(<UkuranBadanSegment
        clothingType={flowSelectionData.clothingType as ClothingType}
        fitType={flowSelectionData.fitType as FitType}
        productDetail={flowSelectionData?.productDetail}
        disableActions={true}
        singleSelectedBodyMeasurement={flowSelectionData.sourceUserMeasurement}
        singleSelectedBodyMeasurementCreationDateTime={flowSelectionData.sourceUserMeasurement?.creationDateTime ?? ''}
      />);
      children.push(<OverlayViewFavoriteModifySize
        showNumberOfSimiliarProduct={flowSelectionData.origin === 'qr'}
        numberOfSimiliarProduct={flowSelectionData.displayImagesArray.length}
        startingClothingBodyMeasurements={
          CalculateClothBodyMeasurementsFromBodyMeasurements(flowSelectionData.clothingType as ClothingType, 
            flowSelectionData.fitType as FitType, 
            flowSelectionData.sourceUserMeasurement?.adjustedMeasurements as any, RegularSizeValue.L, [])}
        startingClothingMeasurements={
          RoundClothingMeasurementsToHalfPoint(
          CalculateClothingMeasurementsFromBodyMeasurements(flowSelectionData.clothingType as ClothingType, 
            flowSelectionData.fitType as FitType, 
            flowSelectionData.sourceUserMeasurement?.adjustedMeasurements as any, RegularSizeValue.L))}
        editedClothingBodyMeasurements={
          flowSelectionData.editedSourceUserMeasurement ?
          CalculateClothBodyMeasurementsFromBodyMeasurements(flowSelectionData.clothingType as ClothingType, 
            flowSelectionData.fitType as FitType, 
            flowSelectionData.editedSourceUserMeasurement?.adjustedMeasurements as any, RegularSizeValue.L, [])
          :
          undefined
        }
        editedClothingMeasurements={
          flowSelectionData.editedSourceUserMeasurement ?
          RoundClothingMeasurementsToHalfPoint(
          CalculateClothingMeasurementsFromBodyMeasurements(flowSelectionData.clothingType as ClothingType, 
            flowSelectionData.fitType as FitType, 
            flowSelectionData.editedSourceUserMeasurement?.adjustedMeasurements as any, RegularSizeValue.L))
          :
          undefined
        }
        clothingType={flowSelectionData.clothingType as ClothingType}
        fitType={flowSelectionData.fitType as FitType}
        productId={ flowSelectionData?.productDetail?.productId }
        productDetail={ flowSelectionData?.productDetail }
        key2={flowSelectionData.clothingType + "-" + flowSelectionData.fitType}
        handleSubmit={(value, valueClothing) => {
          const currentTime = new Date().toISOString();

          let clothingBodyMeas: BodyMeasurements = value;
          let clothingMeas: ClothingMeasurements = valueClothing;
          const userMeasurementsInput: UserClothTypeSpecificBodyMeasurements = { 
            ...flowSelectionData.sourceUserMeasurement,
            bodyOrClothingMeasurements: "CLOTHING",
            sizeType: SizeType.personal,
            clothingType: flowSelectionData.clothingType as ClothingType,
            fitType: flowSelectionData.fitType as FitType,
            creationMethod: 'online-edit',
            caraBuatString: "Ubah Ukuran (Custom)",
            creationDateTime: currentTime,
            updateDateTime: currentTime,
            key1: undefined,
            key2: undefined,
            key3: undefined,
            key4: undefined,
            key12: undefined,
            key123: undefined,
            key234: undefined,
            key34: undefined,
            key1234: undefined,
          };

          userMeasurementsInput["clothingMeasurements"] = clothingMeas;
          userMeasurementsInput["generatedMeasurements"] = clothingBodyMeas;
          userMeasurementsInput["adjustedMeasurements"] = clothingBodyMeas;

          dispatch(inputMeasurementsForm(userMeasurementsInput));

          dispatch(setMeasurementObjectDraftValue({measurementObjectDraft: userMeasurementsInput}));

          delayedDispatch("nameScreen");
        }}
        allowSubmitWithoutChange={true}
        onlyUseServiceability={true}
        isMinimized={flowSelectionData.useDialog}
      />);
      titleNumber = 1;
      break;
    case "nameScreen":
      children.push(<OverlayViewSaveMeasName 
        showDisplayImagesArray={flowSelectionData.origin === 'order' || flowSelectionData.origin === 'qr'}
        displayImagesArray={flowSelectionData.displayImagesArray}
        clothingType={flowSelectionData.clothingType as ClothingType}
        fitType={flowSelectionData.fitType as FitType}
        key2={flowSelectionData.clothingType + "-" + flowSelectionData.fitType}
        clothingMeasurements={flowStateData.currentUserMeasurement.clothingMeasurements}
        startingName={flowSelectionData.sourceUserMeasurement?.measurementName ? flowSelectionData.sourceUserMeasurement.measurementName + '*' : 'Uk Custom'}
        handleSubmit={(measName) => {
          dispatch(inputMeasurementsForm({
            measurementName: measName
          }));

          setMeasurementName(measName)
          
          dispatch(postClothingMeasurement());

          switch (flowSelectionData.origin){
            case 'default':
              dispatch(closeOverlayAndReset());
              dispatch(closeOverlayAndResetSelection());
              delayedDispatch("editingScreen");
              break;
            case 'order':
              delayedDispatch("backToShopScreen");
              break;
            case 'qr':
              dispatch(setValuesQRResult({
                measurementName: measName,
                fitType: flowSelectionData.fitType as FitType,
                clothingType: flowSelectionData.clothingType as ClothingType,
                numberOfSimiliarProducts: flowSelectionData.displayImagesArray.length
              }))
              dispatch(openOverlayQRResult())
              dispatch(closeOverlayAndReset());
              break;
          }          
        }}
        handleBack={() => {
          delayedDispatch("editingScreen");
        }}
        isMinimized={flowSelectionData.useDialog}
        isAllowedBack={true}
      />);
      break;
    case "backToShopScreen":
      children.push(<OverlayViewBackToShop 
        clothingType={flowSelectionData.clothingType as ClothingType}
        fitType={flowSelectionData.fitType as FitType}
        key2={flowSelectionData.clothingType + "-" + flowSelectionData.fitType}
        clothingMeasurements={flowStateData.currentUserMeasurement.clothingMeasurements}
        measurementName={measurementName}
        handleSubmit={() => {
          dispatch(closeOverlayAndReset());
          delayedDispatch("editingScreen");
        }}
      />);
      break;
  }

  if (flowStateData.titleNumber != titleNumber) {
    flowStateCallback.setTitleNumber(titleNumber);
  }

  return children;
}