import React, { useCallback, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useInView } from 'react-intersection-observer';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Box, Link, useMediaQuery } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Mixpanel } from '../mixpanel';
import { attemptProductGlimpseEvent } from '../redux/ProductsListRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import { FareastButton, FareastButtonGrey } from './OverlayComponentButton';
import { countdownRenderer, determineMediaType } from './PageProductDetail';
import { VideoComponent } from './PageComponentElementCarousel';
import { postNewProductAnalyticsLog } from '../redux/ProductAnalyticsRedux';
import { getDiscountedPriceInProductSpotlightList, getTimestampInProductSpotlightList } from '../redux/CartRedux';
import Countdown from 'react-countdown';
import { HorizontalInfScrollImg, HorizontalInfScrollImgHalf } from './PageComponentElementHorizontalInfScroll';

interface stylesProps {
  image_url: string,
  image_url_hover: string,
  margin: string,
  padding: string
}

const useStyles = ({ image_url, image_url_hover, margin, padding }: stylesProps) => makeStyles((theme: Theme) =>
  createStyles({
    video: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    productListItem: {
      width: '100%',
      margin: margin,
      padding: padding,
      '&:hover': {
        zIndex: 100, // To ensure that the items come first
      },
    },
    paper: {
      position: "relative", // Needed for child ::before
      overflow: "visible !important", // Needed such that overflows are not clipped
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: '-12px',
        [theme.breakpoints.up('md')]: {
          inset: '-16px',
        },
        backgroundColor: "#ffffff",
        zIndex: -1,
        display: 'none',
      },
      [theme.breakpoints.up('sm')]: {
        '&:hover': {
          '&::before': {
            boxShadow: '0px 0px 18px 8px #c8c8c8',
            display: 'block',
            zIndex: 1,
          },
        },
      }
    },
    cardActionArea: {
      zIndex: 1,
      color: 'aliceblue',
    },
    media: {
      // paddingBottom: '150%', // We use 2 : 3 aspect ratio
      backgroundPosition: 'center top',
      backgroundImage: `url(${image_url})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      '&:hover': {
        backgroundImage: `url(${image_url_hover})`,
      },
    },
    imageContentContainer: {
      // paddingBottom: '150%', // We use 2 : 3 aspect ratio
      // height: '0px', // This is useful to make an aspect ratio of our liking 
      position: 'relative',
    },
    imageContent: {
      width: '100%',
    },
    hiddenElement: {
      display: 'none',
    },
    itemContent: {
      paddingTop: "14px",
      paddingLeft: "6px",
      paddingRight: "6px",
      paddingBottom: "6px",
      marginBottom: "0px",
    },
    itemHeader: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginBottom: "6px",
    },
    itemHeaderTitleText: {
      fontSize: '1.43vw',
      fontFamily: 'OakesGrotesk, sans-serif',
      fontWeight: 500,
      letterSpacing: '0.03em',
      color: '#232323',
      [theme.breakpoints.down('xs')]: {
        fontSize: '3.3vw',
      },
    },
    itemPriceText: {
      textAlign: 'right',
      fontSize: '1.25vw',
      fontWeight: 300,
      color: '#000000',
      fontFamily: 'Open sans, sans-serif',
      marginTop: '-0.1vw',
      [theme.breakpoints.down('xs')]: {
        fontSize: '3vw',
        marginTop: '0px',
      },
      whiteSpace: 'nowrap',
    },
    itemPriceTextDiscount: {
      textAlign: 'right',
      fontSize: '1vw',
      fontWeight: 500,
      color: 'red',
      fontFamily: 'Open sans, sans-serif',
      marginTop: '-0.1vw',
      marginRight: "1vw",
      [theme.breakpoints.down('xs')]: {
        fontSize: '3vw',
        marginTop: '0px',
        marginRight: "2.4vw",
      },
      whiteSpace: 'nowrap',
    },
    itemPriceTextDiscountStrikeThrough: {
      textAlign: 'right',
      fontSize: '1vw',
      fontWeight: 'lighter',
      color: '#4D4D4D',
      fontFamily: 'Open sans, sans-serif',
      marginTop: '-0.1vw',
      [theme.breakpoints.down('xs')]: {
        fontSize: '3vw',
        marginTop: '0px',
      },
      textDecoration: 'line-through',
      whiteSpace: 'nowrap',
    },
    priceBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: 'fit-content',
    },
    circularProgressContainer: {
      width: '100%',
      aspectRatio: '2/3', // We use 2 : 3 aspect ratio
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    soldOutOverlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255,255,255,0.1)',
      zIndex: 1,
    },
    sisaBoxBottomLeft: {
      backgroundColor: "#ff1e1e",
      textAlign: "center",
      position: "absolute",
      bottom: "1vw",
      left: "1vw",
      width: "6vw",
      borderRadius: "1.3vw",
      [theme.breakpoints.down('xs')]: {
        bottom: "2.4vw",
        left: "2.4vw",
        width: "14vw",
        borderRadius: "3vw",
      },
    },
    sisaBoxUpperLeft: {
      backgroundColor: "#ff1e1e",
      textAlign: "center",
      position: "absolute",
      top: "1vw",
      left: "1vw",
      width: "6vw",
      borderRadius: "1.3vw",
      [theme.breakpoints.down('xs')]: {
        top: "3vw",
        left: "2.4vw",
        width: "14vw",
        borderRadius: "3vw",
      },
    },
    sisaText: {
      color: '#ffffff',
      fontSize: "1.3vw",
      paddingTop: "0.3vw",
      [theme.breakpoints.down('xs')]: {
        fontSize: "3vw",
        paddingTop: "0.5vw",
      },
    },
    marketplaceBox: {
      display: 'flex',
      backgroundColor: "#ffffff",
      textAlign: "center",
      position: "absolute",
      bottom: "1vw",
      left: "50%",
      transform: "translateX(-50%)",
      width: "18vw",
      borderRadius: "1.3vw",
      padding: '0.2vw 0vw 0.6vw 0vw',
      [theme.breakpoints.down('xs')]: {
        bottom: "2.4vw",
        width: "40vw",
        borderRadius: "3vw",
        padding: '1vw 0vw 1.25vw 0vw',
      },
    },
    marketplaceText: {
      fontWeight: 500,
      letterSpacing: '0.5px',
      color: '#121212',
      fontSize: "0.75vw",
      paddingTop: "0.6vw",
      [theme.breakpoints.down('xs')]: {
        fontSize: "1.6vw",
        paddingTop: "1vw",
      },
    },
    marketplaceLogo: {
      width: '1.25vw',
      paddingTop: "0.4vw",
      [theme.breakpoints.down('xs')]: {
        width: '3.25vw',
        paddingTop: "0vw",
      },
    },
  }),
);

function AlternatingImg({
  displayImageList,
  startingIndex
}: {
  displayImageList: string[],
  startingIndex?: number
}) {
  const [currentImageIndex, setCurrentImageIndex] = React.useState<number>(startingIndex ?? 0);
  
  const imageSet = displayImageList.filter((item) => {
    // WE FILTER OUT VIDEO
    return determineMediaType(item) === "image";
  });

  useEffect(() => {
    setTimeout(() => {
      setCurrentImageIndex((currentImageIndex + 1) % (imageSet?.length ? imageSet.length : 1));
    }, 1350);
  }, [currentImageIndex]);

  return (
    <img style={{
      width: '100%',
    }} src={imageSet?.[currentImageIndex] ?? ''} alt={"img-hover-rotate"} key={"img-alt"} />
  )
}

function AlternatingImgCarsl({
  displayImageList,
  startingIndex
}: {
  displayImageList: string[],
  startingIndex?: number
}) {
  const [currentImageIndex, setCurrentImageIndex] = React.useState<number>(startingIndex ?? 0);

  const imageSet = displayImageList.filter((item) => {
    // WE FILTER OUT VIDEO
    return determineMediaType(item) === "image";
  });

  return (
    <Box style={{
      width: '100%',
      position: 'relative',
    }}>
      <Carousel
        selectedItem={currentImageIndex}
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false} 
        showStatus={false}
        showArrows={false}
        showIndicators={false} // WE USE SEPARATE INDICATORS
        onChange={ (index, item) => {
          if (currentImageIndex !== index) {
            setCurrentImageIndex((index) % (imageSet?.length ? imageSet.length : 1));
          }
        }}
        >
        {
          imageSet.map((item, i) => {
            let contentType = determineMediaType(item);

            if (contentType === "image") {
              return (
                <div 
                key={"img-product-" + i }>
                  <img src={item} alt={'car-mini-img'} style={{
                    width: '100%',
                    // marginBottom: '36px', // DEACTIVATED FOR MINI CAROUSEL
                  }} />
              </div>
              );
            } else if (contentType === "video") {
              return (
                <div 
                key={"img-product-" + i }>
                  <VideoComponent 
                    source={item} 
                    videoSoundOn={false}
                  />
                </div>
              );
            } else {
              return (
                <div 
                key={"img-product-" + i }>
                  <img src={item} alt={'car-mini-img'} style={{
                    width: '100%',
                    // marginBottom: '36px', // DEACTIVATED FOR MINI CAROUSEL
                  }} />
                </div>
              );
            }
          }).filter(Boolean)
        }
      </Carousel>
      <Box style={{
        position: 'absolute',
        bottom: '9px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}>
        <Box style={{
          display: 'flex',
          backgroundColor: '#ffffff',
          borderRadius: '30px',
          padding: '4px',
        }}>
          {
            imageSet.map((obj, index) => {
              return (
                <div
                style={{
                  background: currentImageIndex === index ? '#000' : '#999',
                  width: 7,
                  height: 7,
                  margin: '0px 2px',
                  borderRadius: '50%',
                }}
                />
              )
            })
          }
        </Box>
      </Box>
    </Box>
  )
}

export function MultipleImgCarsl({
  displayImageList,
  startingIndex = 0,
  splitInto,
  showIndicators = false,
  autoSlide = true,
}: {
  displayImageList: string[],
  startingIndex?: number
  splitInto?: number
  showIndicators?: boolean
  autoSlide?: boolean
}) {
  const [currentImageIndex, setCurrentImageIndex] = React.useState<number>(0);

  const imageSet = displayImageList.filter((item) => {
    // WE FILTER OUT VIDEO
    return determineMediaType(item) === "image";
  });
  const splitIntoSet = splitInto ?? 2;
  const widthSet = 
    splitIntoSet ===  2 ? '49%' : 
    splitIntoSet ===  3 ? '32%' : 
    splitIntoSet ===  4 ? '24%' : 
    '100%';

  const imageSetStartingImages = [];
  for (var i = startingIndex;  i < startingIndex + imageSet.length; i = i + splitIntoSet) {
    imageSetStartingImages.push({
      index: i % imageSet.length,
      contentImage: imageSet[i % imageSet.length]
    })
  }

  return (
    <Box style={{
      width: '100%',
      position: 'relative',
    }}>
      <Carousel
        selectedItem={currentImageIndex}
        autoPlay={autoSlide}
        infiniteLoop={true}
        interval={3500}
        showThumbs={false} 
        showStatus={false}
        showArrows={false}
        showIndicators={showIndicators}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{
                  display: 'inline-block',
                  background: '#000',
                  width: 8,
                  height: 8,
                  margin: '0px 4px',
                  borderRadius: '50%',
                }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              >

              </li>
            );
          }
          return (
            <li
              style={{
                display: 'inline-block',
                background: '#999',
                width: 8,
                height: 8,
                margin: '0px 4px',
                borderRadius: '50%',
              }}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            >
            </li>
          );
        }}
        onChange={ (index, item) => {
          if (currentImageIndex !== index) {
            setCurrentImageIndex((index) % (displayImageList?.length ? displayImageList.length : 1));
          }
        }}
        >
        {
          imageSetStartingImages.map((item, i) => {

            return (
              <div 
              key={"img-product-" + i } style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: showIndicators ? '32px' : '0px'
              }}>
                <img src={item.contentImage} alt={'car-mini-img'} style={{
                  width: widthSet,
                }} />
                {
                  splitIntoSet >= 2 ?
                  <img src={imageSet[(item.index + 1) % (imageSet.length ? imageSet.length : 1)]} alt={'car-mini-img'} style={{
                    width: widthSet,
                  }} />:
                  null
                }
                {
                  splitIntoSet >= 3 ?
                  <img src={imageSet[(item.index + 2) % (imageSet.length ? imageSet.length : 1)]} alt={'car-mini-img'} style={{
                    width: widthSet,
                  }} />:
                  null
                }
                {
                  splitIntoSet >= 4 ?
                  <img src={imageSet[(item.index + 3) % (imageSet.length ? imageSet.length : 1)]} alt={'car-mini-img'} style={{
                    width: widthSet,
                  }} />:
                  null
                }
              </div>
            );
          }).filter(Boolean)
        }
      </Carousel>
    </Box>
  )
}

interface PageProductItemProps {
  product_id: string,
  image_url: string, // If this is equal to 'animate-blink-{startIndex}' or 'animate-carsl-{startIndex}', we display differently
  image_url_hover: string,
  title: string,
  originalPrice: number,
  discountedPrice: number,
  image_additional_url?: string,
  isSoldOut?: boolean,
  isComingAgainSoon?: boolean,
  product_url: string,
  margin?: string,
  padding?: string,
  marketplaceLinkData?: {
    [key: string]: string;
  }
}

interface MarketplaceLinkData {
  [key: string]: string;
}
// const marketplaceLinkData: MarketplaceLinkData | null = {
  // shopee: "https://shopee.co.id",
  // tokopedia: "https://tokopedia.com",
  // tiktok: "https://tiktok.com"
// };


export const marketplaceLogoDictionary = {
  'shopee': 'https://studiosclo.uk/general/logo_shopee.png',
  'tokopedia': 'https://studiosclo.uk/general/logo_tokopedia.png',
  'tiktok': 'https://studiosclo.uk/general/logo_tiktokshop.png'
}

export default function PageElementProduct({ product_id, image_url, image_url_hover, title, originalPrice, discountedPrice: discountedPriceDef, isSoldOut,
  isComingAgainSoon, product_url, margin, padding, marketplaceLinkData, image_additional_url }: PageProductItemProps) {
  margin = margin ? margin : '0px 0px 0px 0px';
  padding = padding ? padding : '30px 9px 9px 9px';
  const productSizeAvailabilityDict = useAppSelector(state => state.productsList.productSizeAvailabilityDict);
  const productsList = useAppSelector((state) => state.productsList);

  // image_url = 'animate-carsl-1';
  // image_url = 'animate-blink-1';
  // image_additional_url = 'carsl-thr-2';
  // image_additional_url = 'inf-scroll';

  const productSpotlightList = useAppSelector(state => state.cart.productSpotlightList ?? []);

  let spotlightDiscPrice = getDiscountedPriceInProductSpotlightList(productSpotlightList, product_id);
  let discountedPrice = spotlightDiscPrice !== -1 ? spotlightDiscPrice : discountedPriceDef;

  let productSpotlightTime = getTimestampInProductSpotlightList(productSpotlightList, product_id);

  const classes = useStyles({ image_url, image_url_hover, margin, padding })();
  const dispatch = useAppDispatch();

  const [lastRenderTime, setLastRenderTime] = React.useState<string>('');

  // DETERMINE MEDIA TYPE
  let mediaType = image_url.startsWith('animate-blink-') ? 'animate-blink' : 
    image_url.startsWith('animate-carsl-') ? 'animate-carsl' : 
    determineMediaType(image_url);

  const numberOfItemsRemaining = productSizeAvailabilityDict?.[product_id]?.numberOfItemsRemaining;

  const productDetail = productsList.productsDictionary?.[product_id];

  isSoldOut = isSoldOut || numberOfItemsRemaining === 0;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  // We set additional image to carousel three for Desktop
  if (isMdOrUp) {
    image_additional_url = 'carsl-thr-2';
  }

  const [isHovered, setIsHovered] = React.useState(false);

  const [imgHover, setImgHover] = React.useState<string>('');
  const [imgNotHover, setImgNotHover] = React.useState<string>('');

  const [imgHoverContainer, setImgHoverContainer] = React.useState<string>('');
  const [imgNotHoverContainer, setImgNotHoverContainer] = React.useState<string>('');

  // Intersection Observer API for lazy loading
  const { ref, inView, entry } = useInView({
    threshold: [0.02, 0.05, 0.1, 0.2],
    triggerOnce: true,
    rootMargin: '400px',
  });

  // Intersection Observer API for viewing event
  const { ref: ref2, inView: inView2, entry: entry2 } = useInView({
    threshold: 0.7,
    triggerOnce: false,
    rootMargin: '10px',
  });

  useEffect(() => {
    if (inView && isMdOrUp) {
      const imgHoverNew = new Image();
      imgHoverNew.onload = () => {
        setImgHoverContainer(imgHoverNew.src);
      }
      imgHoverNew.src = image_url_hover;

      const imgNotHoverNew = new Image();
      imgNotHoverNew.onload = () => {
        setImgNotHoverContainer(imgNotHoverNew.src);
      }
      imgNotHoverNew.src = image_url;
    }
  }, [image_url, image_url_hover, inView]);

  useEffect(() => {
    if (inView2) {
      dispatch(attemptProductGlimpseEvent(product_id));
      dispatch(postNewProductAnalyticsLog({event: 'VIEW', productId: product_id, isMobileView: !isDesktop }))
    }
  }, [inView2]);

  // Use `useCallback` so we don't recreate the function on each render
  const setRefs = useCallback(
    (node) => {
      ref(node);
      ref2(node);
    },
    [ref, ref2],
  );

  if (imgHoverContainer !== "" && imgHoverContainer === image_url_hover && imgHoverContainer !== imgHover) {
    setImgHover(imgHoverContainer);
  }
  if (imgNotHoverContainer !== "" && imgNotHoverContainer === image_url && imgNotHoverContainer !== imgNotHover) {
    setImgNotHover(imgNotHoverContainer);
  }

  if (!isMdOrUp) {
    return (
        <div className={classes.productListItem} ref={setRefs}
          onClick={() => {

            sendWebsiteEventMessage("Ada yang buka product " + title + " dari x" + window.location.href);

            Mixpanel.track('navigateRoute', {
              'routePath': product_url,
              'routeSource': 'pageProductListItem',
            });
            Mixpanel.track('productClickToProductDetailPage', {
              'productId': "productId-" + product_id,
              'productUrl': product_url,
              'routeSource': 'pageProductListItem',
            });
            console.log("Tracking mixpanel productClickToProductDetailPage for product " + product_id);
          }}>
            <Link component={RouterLink} to={product_url} underline="none" style={{
              WebkitTapHighlightColor: 'transparent',
            }}>
              <div className={classes.imageContentContainer}>
                {
                  marketplaceLinkData && Object.keys(marketplaceLinkData).length > 0 ?
                  <Box className={classes.marketplaceBox}>
                    <Box
                      style={{
                        width: '40%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography className={classes.marketplaceText}>
                        TERSEDIA DI
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        width: '60%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '3.5vw'
                      }}
                    >
                      {
                        Object.keys(marketplaceLinkData).map((key) => (
                          <img
                            key={key} 
                            src={marketplaceLogoDictionary[key as keyof typeof marketplaceLogoDictionary]}
                            className={classes.marketplaceLogo}
                            alt={`${key} logo`}
                          />
                        ))
                      }
                    </Box>
                  </Box> :
                  null
                }
                {
                  isSoldOut ?
                  <Box className={classes.soldOutOverlay}>
                    <FareastButton
                      buttonMarginLeftRight='20px'
                      buttonMarginTopBottom='20px'>
                      <b>SOLD OUT</b>
                    </FareastButton>
                  </Box> :
                  isComingAgainSoon ?
                  <Box className={classes.soldOutOverlay}>
                    <FareastButtonGrey
                      buttonMarginLeftRight='20px'
                      buttonMarginTopBottom='20px'>
                      <b>COMING BACK SOON</b>
                    </FareastButtonGrey>
                  </Box> :
                  null
                }
                {
                  mediaType === 'video' && image_url ? 
                  (
                    <div key={"full-img-product-" + product_id }>
                      <VideoComponent 
                        source={image_url} 
                        videoHasSound={false} // All element product video has sound disabled
                      />
                    </div>
                  ) :
                  mediaType === 'animate-blink' && image_url ? 
                  (
                    <AlternatingImg startingIndex={Number(image_url.substring(14, 15))} displayImageList={productDetail?.productImages ?? []} />
                  ) : 
                  mediaType === 'animate-carsl' && image_url ? 
                  (
                    <AlternatingImgCarsl startingIndex={Number(image_url.substring(14, 15))} displayImageList={productDetail?.productImages ?? []} />
                  ) : 
                  (
                    <img className={classes.imageContent} src={image_url} alt={"img-hover-" + product_id} key={"img-hover-" + product_id} />
                  )
                }
                {
                  !isSoldOut && numberOfItemsRemaining && numberOfItemsRemaining < 14 ?
                    <Box className={((marketplaceLinkData && Object.keys(marketplaceLinkData).length > 0) ||  mediaType === 'animate-carsl') ? classes.sisaBoxUpperLeft : classes.sisaBoxBottomLeft}>
                      <Typography className={classes.sisaText}>
                        Sisa {numberOfItemsRemaining}
                      </Typography>
                    </Box> :
                    null
                }
              </div>
            </Link>
            <Link component={RouterLink} to={product_url} underline="none">
              {
                image_additional_url?.startsWith('carsl-two-') ?
                <MultipleImgCarsl 
                  startingIndex={Number(image_additional_url.substring(10, 11))}
                  displayImageList={productDetail?.productImages ?? []}
                  splitInto={2}
                />:
                image_additional_url?.startsWith('carsl-thr-') ?
                <MultipleImgCarsl
                  startingIndex={Number(image_additional_url.substring(10, 11))}
                  displayImageList={productDetail?.productImages ?? []}
                  splitInto={3}
                />:
                image_additional_url?.startsWith('carsl-for-') ?
                <MultipleImgCarsl
                  startingIndex={Number(image_additional_url.substring(10, 11))}
                  displayImageList={productDetail?.productImages ?? []}
                  splitInto={4}
                />:
                image_additional_url === 'inf-scroll' ?
                <HorizontalInfScrollImg 
                  displayImageList={productDetail?.productImages ?? []}
                />:
                image_additional_url === 'inf-scroll-half' ?
                <HorizontalInfScrollImgHalf
                  displayImageList={productDetail?.productImages ?? []}
                />:
                null
              }
            </Link>
            {
              (new Date().toISOString() < productSpotlightTime) &&
              <Box style={{margin: '13px 0px -2px 5px'}}>
                <Countdown
                  date={productSpotlightTime}
                  renderer={countdownRenderer}
                  onComplete={(timeDelta, completedOnStart) => {
                    setTimeout(() => {
                      setLastRenderTime(new Date().toISOString())
                    }, 400)
                  }}
                />
              </Box>
            } 
            <Box className={classes.itemContent}>
              <Link component={RouterLink} to={product_url} underline="none" style={{
                WebkitTapHighlightColor: 'transparent',
              }}>
              <Box className={classes.itemHeader}>
                <Typography className={classes.itemHeaderTitleText}>
                  {ReactHtmlParser(title)}
                </Typography>
                {
                  discountedPrice === -1 || isSoldOut === true || isComingAgainSoon === true ?
                    <Box className={classes.priceBox}>
                      <Typography className={classes.itemPriceText}>
                        {("Rp " + originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                      </Typography>
                    </Box> :
                    <Box className={classes.priceBox}>
                      <Typography className={classes.itemPriceTextDiscount}>
                        {("Rp " + discountedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                      </Typography>
                      <Typography className={classes.itemPriceTextDiscountStrikeThrough}>
                        {("Rp " + originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                      </Typography>
                    </Box>
                }
              </Box>
              </Link>
            </Box>
        </div>
    );
  }

  return (
    <div className={classes.productListItem} ref={setRefs}>
      <Card elevation={0} square={true} className={classes.paper}>
        <CardActionArea component={RouterLink} to={product_url} disableRipple
          onClick={() => {

            sendWebsiteEventMessage("Ada yang buka product " + title + " dari x" + window.location.href);

            (window as any).dataLayer.push({ 'event': 'navigateRoute', 'routePath': product_url, 'routeSource': 'pageProductListItem' });
            Mixpanel.track('navigateRoute', {
              'routePath': product_url,
              'routeSource': 'pageProductListItem',
            });
            Mixpanel.track('productClickToProductDetailPage', {
              'productId': "productId-" + product_id,
              'productUrl': product_url,
              'routeSource': 'pageProductListItem',
            });
            console.log("Tracking mixpanel productClickToProductDetailPage for product " + product_id);
          }} className={classes.cardActionArea}>
          {
            !imgHover || !imgNotHover || imgHover !== image_url_hover || imgNotHover !== image_url ?
              <div className={classes.circularProgressContainer}>
                <CircularProgress />
              </div> :
              <div className={classes.imageContentContainer}
                onMouseEnter={() => setIsHovered(isMdOrUp)}
                onMouseLeave={() => setIsHovered(false)}>
                {
                  marketplaceLinkData && Object.keys(marketplaceLinkData).length > 0 ?
                  <Box className={classes.marketplaceBox}>
                    <Box
                      style={{
                        width: '40%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography className={classes.marketplaceText}>
                        TERSEDIA DI
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        width: '60%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '1.75vw'
                      }}
                    >
                      {
                        Object.keys(marketplaceLinkData).map((key) => (
                          <img
                            key={key} 
                            src={marketplaceLogoDictionary[key as keyof typeof marketplaceLogoDictionary]}
                            className={classes.marketplaceLogo}
                            alt={`${key} logo`}
                          />
                        ))
                      }
                    </Box>
                  </Box> :
                  null
                }
                {
                  isSoldOut ?
                    <Box className={classes.soldOutOverlay}>
                      <FareastButton
                        buttonMarginLeftRight='20px'
                        buttonMarginTopBottom='20px'>
                        <b>SOLD OUT</b>
                      </FareastButton>
                    </Box> :
                    isComingAgainSoon ?
                      <Box className={classes.soldOutOverlay}>
                        <FareastButtonGrey
                          buttonMarginLeftRight='20px'
                          buttonMarginTopBottom='20px'>
                          <b>COMING BACK SOON</b>
                        </FareastButtonGrey>
                      </Box> :
                      null
                }
                {
                  isHovered && false ?
                  <img className={classes.imageContent} src={imgHover} alt={"img-" + product_id} key={"img-" + product_id} /> : (
                  <>
                  {
                    mediaType === 'video' && image_url ? 
                    (
                      <div key={"full-img-product-" + product_id }>
                        <VideoComponent 
                          source={image_url} 
                          videoHasSound={false} // All element product video has sound disabled
                        />
                      </div>
                    ) :
                    mediaType === 'animate-blink' && image_url ? 
                    (
                      <AlternatingImg startingIndex={Number(image_url.substring(14, 15))} displayImageList={productDetail?.productImages ?? []} />
                    ) : 
                    mediaType === 'animate-carsl' && image_url ? 
                    (
                      <AlternatingImgCarsl startingIndex={Number(image_url.substring(14, 15))} displayImageList={productDetail?.productImages ?? []} />
                    ) : 
                    (
                      <img className={classes.imageContent} src={imgNotHover} alt={"img-hover-" + product_id} key={"img-hover-" + product_id} />
                    )
                  }
                  </>)
                }
                {
                  !isSoldOut && numberOfItemsRemaining && numberOfItemsRemaining < 14 ?
                    <Box className={((marketplaceLinkData && Object.keys(marketplaceLinkData).length > 0) ||  mediaType === 'animate-carsl') ? classes.sisaBoxUpperLeft : classes.sisaBoxBottomLeft}>
                      <Typography className={classes.sisaText}>
                        Sisa {numberOfItemsRemaining}
                      </Typography>
                    </Box> :
                    null
                }
              </div>
          }
          <Link component={RouterLink} to={product_url} underline="none">
            {
              image_additional_url?.startsWith('carsl-two-') ?
              <MultipleImgCarsl 
                startingIndex={Number(image_additional_url.substring(10, 11))}
                displayImageList={productDetail?.productImages ?? []}
                splitInto={2}
              />:
              image_additional_url?.startsWith('carsl-thr-') ?
              <MultipleImgCarsl
                startingIndex={Number(image_additional_url.substring(10, 11))}
                displayImageList={productDetail?.productImages ?? []}
                splitInto={3}
              />:
              image_additional_url?.startsWith('carsl-for-') ?
              <MultipleImgCarsl
                startingIndex={Number(image_additional_url.substring(10, 11))}
                displayImageList={productDetail?.productImages ?? []}
                splitInto={4}
              />:
              image_additional_url === 'inf-scroll' ?
              <HorizontalInfScrollImg 
                displayImageList={productDetail?.productImages ?? []}
              />:
              image_additional_url === 'inf-scroll-half' ?
              <HorizontalInfScrollImgHalf
                displayImageList={productDetail?.productImages ?? []}
              />:
              null
            }
          </Link>
          {
            (new Date().toISOString() < productSpotlightTime) &&
            <Box style={{margin: '15px 0px 0px 5px'}}>
              <Countdown
                date={productSpotlightTime}
                renderer={countdownRenderer}
                onComplete={(timeDelta, completedOnStart) => {
                  setTimeout(() => {
                    setLastRenderTime(new Date().toISOString())
                  }, 400)
                }}
              />
            </Box>
          } 
          <Box className={classes.itemContent}>
            <Link component={RouterLink} to={product_url} underline="none">
              <Box className={classes.itemHeader}>
                <Typography className={classes.itemHeaderTitleText}>
                  {ReactHtmlParser(title)}
                </Typography>
                {
                  discountedPrice === -1 || isSoldOut === true || isComingAgainSoon === true ?
                    <Box className={classes.priceBox}>
                      <Typography className={classes.itemPriceText}>
                        {("Rp " + originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                      </Typography>
                    </Box> :
                    <Box className={classes.priceBox}>
                      <Typography className={classes.itemPriceTextDiscount}>
                        {("Rp " + discountedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                      </Typography>
                      <Typography className={classes.itemPriceTextDiscountStrikeThrough}>
                        {("Rp " + originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                      </Typography>
                    </Box>
                }
              </Box>
            </Link>
          </Box>
        </CardActionArea>
      </Card>
    </div>
  );
  
}