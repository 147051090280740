import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetUserMeasurementDraft } from './MeasurementsRedux';
import ProductOnUser from 'common-ts/dist/models/Product';

export enum UniversalSizeActiveScreen {
    BenefitStart,
    SizeSelect,
    SizeAdjust,
    ResultLoading,
    Result,
    BenefitEnd,
}

export interface UniversalSizeFlow {
    status: UIOverlayViewStatus,

    currentScreen: UniversalSizeActiveScreen,

    productDetail: ProductOnUser | undefined,
}

export const openOverlayCreateMeasWithInitialization = createAsyncThunk(
  'overlayUniversalSize/openOverlayCreateMeasWithInitialization', 
  async (payload: ProductOnUser | undefined, thunkAPI) => {

  thunkAPI.dispatch(resetUserMeasurementDraft());
  thunkAPI.dispatch(openOverlay(payload));

  return null;
});

export const closeOverlayAndResetWithCleanup = createAsyncThunk(
  'overlayUniversalSize/closeOverlayAndResetWithCleanup', 
  async (payload, thunkAPI) => {

  thunkAPI.dispatch(resetUserMeasurementDraft());
  thunkAPI.dispatch(closeOverlayAndReset());

  return null;
});

// Define the initial state using that type
const initialState: UniversalSizeFlow = {
    status: UIOverlayViewStatus.OffAndReady,

    currentScreen: UniversalSizeActiveScreen.SizeSelect,

    productDetail: undefined,
}

export const overlayUniversalSize = createSlice({
    name: 'overlayUniversalSize',
    initialState,
    reducers: {
        closeOverlayAndReset: (state) => {
            state.status = UIOverlayViewStatus.OffAndReady;
            state.currentScreen = UniversalSizeActiveScreen.SizeSelect;
        },

        openOverlay: (state, action: PayloadAction<ProductOnUser | undefined>) => {
            state.status = UIOverlayViewStatus.On;
            state.currentScreen = UniversalSizeActiveScreen.SizeSelect;
            state.productDetail = action.payload;
        },

        updateCurrentScreen: (state, action: PayloadAction<UniversalSizeActiveScreen>) => {
            state.currentScreen = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay, updateCurrentScreen } = overlayUniversalSize.actions

export default overlayUniversalSize.reducer