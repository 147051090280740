import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ProductOnUser from 'common-ts/dist/models/Product';

export interface PersonalSizeFlow {
    status: UIOverlayViewStatus,    
    statusPromo: UIOverlayViewStatus,

    payloadPromo: {
        sizeData: any,
        productDetail: ProductOnUser | undefined,
    },

    panelTabIndex: number,
    categoryTabIndex: number,

    currentViewedBodyMeasCreationDateTime: string,
}

// Define the initial state using that type
const initialState: PersonalSizeFlow = {
    status: UIOverlayViewStatus.OffAndReady,
    statusPromo: UIOverlayViewStatus.OffAndReady,

    payloadPromo: {
        sizeData: undefined,
        productDetail: undefined,
    },

    panelTabIndex: 0,
    categoryTabIndex: 0,

    currentViewedBodyMeasCreationDateTime: '',
}

export const overlayPersonalSizeSlice = createSlice({
    name: 'overlayPersonalSize',
    initialState,
    reducers: {
        closeOverlayAndReset: (state) => {
            state.status = UIOverlayViewStatus.OffAndReady;
        },

        closeOverlayAndResetPromo: (state) => {
            state.statusPromo = UIOverlayViewStatus.OffAndReady;
        },

        updatePanelIndex: (state, action: PayloadAction<number>) => {
            state.panelTabIndex = action.payload;
        },

        updateCategoryIndex: (state, action: PayloadAction<number>) => {
            state.categoryTabIndex = action.payload;
        },

        openOverlay: (state) => {
            state.status = UIOverlayViewStatus.On;
        },
        
        openOverlayPromo: (state, action: PayloadAction<{
            sizeData: any,
            productDetail: ProductOnUser | undefined,
        }>) => {
            state.statusPromo = UIOverlayViewStatus.On;
            state.payloadPromo = action.payload;
        },

        setViewedBodyMeas: (state, action: PayloadAction<string>) => {
            state.currentViewedBodyMeasCreationDateTime = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay, openOverlayPromo, updatePanelIndex, 
    updateCategoryIndex, closeOverlayAndResetPromo, setViewedBodyMeas } = overlayPersonalSizeSlice.actions

export default overlayPersonalSizeSlice.reducer