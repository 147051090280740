
let tg = {
    token: "5832447973:AAHr2mw2uvjcX7xrI4zFD9Ooj-xodxouSf4", // Your bot's token that got from @BotFather
    chat_id: -916481725 // The user's(that you want to send a message) telegram chat id
}

var userIdentifier = "";

export function setUserIdentifier(text: string) {
    userIdentifier = text;
}

export function sendWebsiteEventMessage(text: string)
{
    // We return if it is from dev
    if ((window.location.href + "").includes("localhost")
    || !(window.location.href + "").includes("studios.id")) {
        return;
    }

    const url = `https://api.telegram.org/bot${tg.token}/sendMessage` // The url to request

    const custName = (window as any).studio_s_customer_name;

    const obj = {
        chat_id: tg.chat_id, // Telegram chat id
        text: "[" + userIdentifier + (custName ? (" " + custName) : "") + "] " + text // The text to send
    };

    const xht = new XMLHttpRequest();
    xht.open("POST", url, true);
    xht.setRequestHeader("Content-type", "application/json; charset=UTF-8");
    xht.send(JSON.stringify(obj));
}

export function sendWebsiteLivestreamMessage(text: string)
{
    // We return if it is from dev
    if ((window.location.href + "").includes("localhost")
    || !(window.location.href + "").includes("studios.id")) {
        return;
    }

    const url = `https://api.telegram.org/bot${tg.token}/sendMessage` // The url to request

    const custName = (window as any).studio_s_customer_name;

    const obj = {
        chat_id: -4094030868, // Telegram chat id
        text: "[" + userIdentifier + (custName ? (" " + custName) : "") + "] " + text // The text to send
    };

    const xht = new XMLHttpRequest();
    xht.open("POST", url, true);
    xht.setRequestHeader("Content-type", "application/json; charset=UTF-8");
    xht.send(JSON.stringify(obj));
}
