import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClothingType, FitType, UserBodyMeasurements, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import ProductOnUser from 'common-ts/dist/models/Product';

export interface MeasureSignUpFlow {
  status: UIOverlayViewStatus,
  overlayType: 'view' | 'edit',
  clothingType: ClothingType,
  fitType: FitType,
  isFullScreen: boolean,
  canEdit: boolean,
  currentMeasurementObject: UserBodyMeasurements | UserClothTypeSpecificBodyMeasurements | undefined,
  measurementObjectDraft: UserClothTypeSpecificBodyMeasurements | undefined
  productDetail: ProductOnUser | undefined;

  // Untuk yang order ada screen tambahan di flow-nya, jadi harus tahu dari mana aksesnya
  origin: 'default' | 'order' | 'qr'
}

// Define the initial state using that type
const initialState: MeasureSignUpFlow = {
  status: UIOverlayViewStatus.OffAndReady,
  overlayType: 'view',
  clothingType: ClothingType.Kaos,
  fitType: FitType.SlimFit,
  isFullScreen: false,
  canEdit: true,
  currentMeasurementObject: undefined,
  measurementObjectDraft: undefined,
  productDetail: undefined,
  origin: 'default'
}

export const overlayMeasurementEditViewSlice = createSlice({
  name: 'overlayMeasurementEditView',
  initialState,
  reducers: {
    closeOverlayAndReset: (state) => {
      state.status = UIOverlayViewStatus.OffAndReady;
      state.overlayType = 'view';
      // state.clothingType = ClothingType.Kaos;
      // state.fitType = FitType.SlimFit;
      state.isFullScreen = false;
      state.currentMeasurementObject = undefined;
      state.measurementObjectDraft = undefined;
      state.productDetail = undefined;
      state.origin = 'default'
    },
    
    setValues: (state, action: PayloadAction<{
      overlayType: 'view' | 'edit'
      clothingType: ClothingType
      fitType: FitType
      isFullScreen: boolean
      currentMeasurementObject: UserBodyMeasurements | UserClothTypeSpecificBodyMeasurements
      canEdit?: boolean
      productDetail?: ProductOnUser
      origin?: 'default' | 'order' | 'qr'
    }>) => {
      state.overlayType = action.payload.overlayType;
      state.clothingType = action.payload.clothingType;
      state.fitType = action.payload.fitType;
      state.isFullScreen = action.payload.isFullScreen;
      state.canEdit = action.payload?.canEdit === false ? false : true;
      state.currentMeasurementObject = action.payload.currentMeasurementObject;
      if (action?.payload?.productDetail) {
        state.productDetail = action.payload.productDetail;
      }
      state.origin = action.payload.origin || 'default'
    },

    setMeasurementObjectDraftValue: (state, action: PayloadAction<{
      measurementObjectDraft: UserClothTypeSpecificBodyMeasurements
    }>) => {
      state.measurementObjectDraft = action.payload.measurementObjectDraft;
    },

    openOverlay: (state) => {
      state.status = UIOverlayViewStatus.On;
    },
  },
  extraReducers(builder) {
  }
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay, setValues, setMeasurementObjectDraftValue } = overlayMeasurementEditViewSlice.actions

export default overlayMeasurementEditViewSlice.reducer