
import {
  Box, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
  import { BodyMeasurementDetailList } from 'common-ts/dist/models/UserMeasurements';
import OverlayComponentButton from './OverlayComponentButton';
import { VarietySliderComponent } from './OverlayViewUniversalSize4Result';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 1) + " !important") as any,
    },
    backButton: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(2),
      backgroundColor: 'black',
      borderRadius: '50%',
      padding: theme.spacing(1),
    },
    backButtonIcon: {
      padding: '0',
      color: 'white',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflowY: 'hidden',
    },
    pageTitle: {
      fontSize: '1.2em',
      width: '100%',
      fontWeight: 550,
    },
    pageItem: {
      display: 'grid',
      gridTemplateColumns: '1fr 8fr',
      marginTop: '1em',
    },
    pageItemIcon: {
      marginRight: '0.2em',
    },
    pageItemTitle: {
      paddingTop: '0.2em',
      fontSize: '0.8em',
      fontWeight: 500,
    },
    pageItemDescription: {
      gridColumn: '2',
      paddingTop: '0.2em',
      paddingRight: '0.5em',
      fontSize: '0.7em',
    },
  }),
);

interface Props {
  missingMeas: string[]
  handleNext: () => void
}

export default function PersonalSizeBenefit({ missingMeas, handleNext }: Props) {
  const classes = useStyles()
  
  return (
  <>
    <Box className={classes.dialogContent}>
      <Typography className={classes.pageTitle} style={{}}>
        Lengkapi profil Personal Fit kamu untuk produk lainnya.
      </Typography>
      <Typography className={classes.pageItemDescription} style={{margin: '5px 0px 23px', color: '#535353', fontWeight: 300, letterSpacing: '0.1vw'}}>
        Dengan melengkapi profil kamu, kamu bisa pakai ukuran personal kamu untuk berbagai produk lainnya.
      </Typography>
      <VarietySliderComponent
        isLoading={false}
        title={' '}
        displayImagesArray={['https://studiosclo.uk/product-image/2024-09-KemejaPanjang-CheckeredShirtinGreyBlack/Kemeja-Hitam-Kotak-Mid-Shot-%284%29.jpg',
          'https://studiosclo.uk/product-image/2024-09-KemejaKantor-InterweavedShirtinDavyGrey/Kemeja-Abu-Medium-shot.jpg',
          'https://studiosclo.uk/product-image/2024-09-KemejaKantor-InterweavedShirtinClassicWhite/Kemeja-Putih-Medium-shot-%28FINAL%29.jpg',
          'https://studiosclo.uk/product-image/2024-03-KnitTeeCollection-WaffleTeeInCobaltMaroon/WaffleInCobaltMaroon-6.jpg',
          'https://studiosclo.uk/product-image/2024-04-FoundationTeeCollection-FoundationTeeinJetBlack/Katalog-Foundation-Tees-Black-1.jpg']}
      />
      <Box style={{
        margin: '10px 4px 4px',
        padding: '14px',
        border: '1px solid #dedede',
        borderRadius: '10px',
      }}>
        <Typography style={{
          fontSize: '11px',
          fontWeight: 500,
        }}>
          Ukuran yang butuh dilengkapi:
        </Typography>
        {
          missingMeas.map((obj, index) => {
            return (
              <Typography style={{ fontWeight: 300, fontSize: '10px', padding: '3px 0px 0px 4px', color: '#565656' }}>
                {BodyMeasurementDetailList[obj].bodyMeasurementName}
              </Typography>
            );
          })
        }
      </Box>
      <Box style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
        <Box style={{width: '30%', paddingBottom: '30px', paddingTop: '10px'}}>
          <OverlayComponentButton
            handleClick={() => handleNext()}
            isTypeSubmit={false}
            formButtonText="MULAI"
            buttonMarginTopBottom="0px"
            buttonMarginLeftRight="0px"
          />
        </Box>
      </Box>
    </Box>
  </>);
}