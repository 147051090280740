import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAppDispatch } from '../reduxhooks';
import React, { useEffect, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Box, IconButton, Typography } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useInView } from 'react-intersection-observer';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { CarouselItemData } from './PageComponentElementBlocks';
import { sendWebsiteEventMessage } from "../telegrambotevents";

interface stylesProps {
  width: string,
  margin: string,
}

const useStyles=({width,margin}: stylesProps) => makeStyles((theme: Theme) =>
createStyles({
  carouselContainerBox: {
    position: "relative",
  },
  statusHoverContainer: {
    position: "absolute",
    top: "16px",
    right: "16px",
    padding: "4px 10px",
    borderRadius: "14px",
    backgroundColor: "#232323",
    opacity: "60%",
    zIndex: 1,
  },
  statusHoverText: {
    fontFamily: "OakesGrotesk",
    fontSize: "12px",
    color: "#ffffff",
    paddingTop: "2px",
  },
  svgImage: {
    width: width,
    margin: margin,
  },
  circularProgressContainer: {
    width: width,
    aspectRatio: '2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: "300px 0px 300px 0px",
  },
  indicatorItemSelected: {
    display: 'inline-block',
    background: '#4c4c4c',
    width: 8,
    height: 8,
    margin: '0px 4px',
    borderRadius: '50%',
  },
  indicatorItem: {
    display: 'inline-block',
    background: '#D9D9D9',
    width: 8,
    height: 8,
    margin: '0px 4px',
    borderRadius: '50%',
  },
  cartCarouselImage: {
    width: '100%',
    paddingBottom: '36px',
  },
  cartCarouselVideoContainer: {
    position: "relative",
  },
  cartCarouselVideo: {
    width: '100%',
    // paddingBottom: '36px',
  },
  cartCarouselVideoSoundButton: {
    position: "absolute",
    bottom: "52px",
    right: "16px",
    color: "#ffffff",
    backgroundColor: "#232323",
    padding: "6px 6px 4px 6px",
    borderRadius: "18px",
  },
  arrowStylePrev: {
    position: 'absolute',
    zIndex: 2,
    bottom: '4px',
    left: "8px",
    width: '30px',
    height: '30px',
    cursor: 'pointer',
  },
  arrowStyleNext: {
    position: 'absolute',
    zIndex: 2,
    bottom: '4px',
    right: "8px",
    width: '30px',
    height: '30px',
    cursor: 'pointer',
  },
  arrowLeftIcon: {
    fontSize: "1.9rem",
    color: "#4c4c4c",
  },
  arrowLeftIconDisabled: {
    fontSize: "1.9rem",
    color: "#D9D9D9",
  },
  arrowRightIcon: {
    fontSize: "1.9rem",
    color: "#4c4c4c",
  },
  arrowRightIconDisabled: {
    fontSize: "1.9rem",
    color: "#D9D9D9",
  },
}),
);

export interface VideoComponentProps {
  source: string
  videoHasSound?: boolean
  videoPoster?: string
  videoSoundOn?: boolean
  widthSet?: string
}

export function VideoComponent({source, videoHasSound, videoPoster, videoSoundOn, widthSet}: VideoComponentProps) {
  const width = widthSet ?? '100%';
  const margin = '0px 0px 0px 0px';
  const classes = useStyles({width, margin})();

  const [isMuted, setIsMuted] = React.useState<boolean>(true);
  const videoElement = useRef(null);

  // Intersection Observer API
  const { ref, inView, entry } = useInView({
    threshold: 0.4,
    triggerOnce: false,
    rootMargin: '10px',
  });

  useEffect(() => {
    if (inView) {
      var isPlaying = (videoElement as any)?.current?.currentTime > 0 && !(videoElement as any)?.current?.paused && !(videoElement as any)?.current?.ended 
      && (videoElement as any)?.current?.readyState > (videoElement as any)?.current?.HAVE_CURRENT_DATA;
      if (!isPlaying) {
        (videoElement as any)?.current?.play()?.catch(function(){});;
      }
    } else {
      var isPlaying = (videoElement as any)?.current?.currentTime > 0 && !(videoElement as any)?.current?.paused && !(videoElement as any)?.current?.ended 
      && (videoElement as any)?.current?.readyState > (videoElement as any)?.current?.HAVE_CURRENT_DATA;
      if (isPlaying) {
        (videoElement as any)?.current?.pause()?.catch(function(){});;
      }
    }
  }, [inView]);
  
  return (
  <div ref={ref} className={classes.cartCarouselVideoContainer}>
    <video
      ref={videoElement}
      src={source}
      autoPlay
      playsInline
      loop
      muted={videoHasSound === false || (videoSoundOn === undefined && isMuted) || videoSoundOn === false}
      poster={
        videoPoster ? 
        videoPoster : 
        undefined
      }
      className={classes.cartCarouselVideo}
      preload='auto'
      >
    </video>
    {
      videoHasSound === undefined || videoHasSound === false || videoSoundOn === true || videoSoundOn === false ? 
      undefined :
      isMuted ? 
      <div className={classes.cartCarouselVideoSoundButton}
      onClick={() => {
        setIsMuted(false);
      }}>
        <VolumeOffIcon />
      </div>:
      <div className={classes.cartCarouselVideoSoundButton}
      onClick={() => {
        setIsMuted(true);
      }}>
        <VolumeUpIcon />
      </div>
    }
  </div>);
}

interface PageProductItemProps {
  carouselItemDataArray: CarouselItemData[]
  linkTo?: string,
}

export default function PageElementCarousel({carouselItemDataArray, linkTo} : PageProductItemProps) {
  const width = '100%';
  const margin = '0px 0px 0px 0px';
  const classes = useStyles({width, margin})();
  const [imageOverlayIndex, setImageOverlayIndex] = React.useState<number>(0);

  if (carouselItemDataArray?.length === 1) {
    return (
      <Box className={classes.carouselContainerBox}>
        {
          carouselItemDataArray[0].contentType === "image" ? 
          <div>
            <img src={carouselItemDataArray[0].contentUrl} className={classes.cartCarouselImage}/>
          </div> :
          carouselItemDataArray[0].contentType === "video-with-sound" ? 
          <VideoComponent 
            source={carouselItemDataArray[0].contentUrl} 
            videoHasSound={true} 
            videoPoster={carouselItemDataArray[0].videoPoster ? carouselItemDataArray[0].videoPoster : undefined}/> :
          carouselItemDataArray[0].contentType === "video-no-sound" ?
          <VideoComponent 
            source={carouselItemDataArray[0].contentUrl} 
            videoHasSound={false} 
            videoPoster={carouselItemDataArray[0].videoPoster ? carouselItemDataArray[0].videoPoster : undefined}/> :
          null
        }
      </Box>
    )
  }

  return (
    <Box className={classes.carouselContainerBox}>
      <Box className={classes.statusHoverContainer}>
        <Typography className={classes.statusHoverText}>
          {imageOverlayIndex + 1} / {carouselItemDataArray ? carouselItemDataArray.length : 1}
        </Typography>
      </Box>
      <Carousel
        selectedItem={imageOverlayIndex}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={12}
        showThumbs={false} 
        showStatus={false}
        showArrows={true}
        autoPlay={true}
        interval={2450}
        infiniteLoop={true}
        dynamicHeight={true}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev ? 
            (
              <IconButton
                color="primary"
                aria-label="Decrement item count"
                onClick={onClickHandler}
                className={classes.arrowStylePrev}
              >
                <ArrowLeftIcon className={classes.arrowLeftIcon} />
              </IconButton>
            ):
            (
              <IconButton
                color="primary"
                aria-label="Decrement item count"
                className={classes.arrowStylePrev}
              >
                <ArrowLeftIcon className={classes.arrowLeftIconDisabled} />
              </IconButton>
            )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext ? 
          (
            <IconButton
              color="primary"
              aria-label="Increment item count"
              onClick={onClickHandler}
              className={classes.arrowStyleNext}
            >
              <ArrowRightIcon className={classes.arrowRightIcon} />
            </IconButton>
          ):
          (
            <IconButton
              color="primary"
              aria-label="Increment item count"
              className={classes.arrowStyleNext}
            >
              <ArrowRightIcon className={classes.arrowRightIconDisabled} />
            </IconButton>
          )
        }
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                className={classes.indicatorItemSelected}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              >

              </li>
            );
          }
          return (
            <li
              className={classes.indicatorItem}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            >
            </li>
          );
        }}
        onChange={ (index, item) => {
          if (imageOverlayIndex !== index) {
            setImageOverlayIndex(index);
            // sendWebsiteEventMessage("User slide hero section " + carouselItemDataArray[0].contentUrl + "df" + " ke slide " + index);
          }
        }}
        >
        {
          carouselItemDataArray.map((carouselItemData, i) => {
            const contentType = carouselItemData.contentType;

            if (contentType === "image") {
              return (
                <div>
                  <img src={carouselItemData.contentUrl} className={classes.cartCarouselImage}/>
                </div>
              );
            } else if (contentType === "video-with-sound") {
              return (<VideoComponent 
                source={carouselItemData.contentUrl} 
                videoHasSound={true} 
                videoPoster={carouselItemData.videoPoster ? carouselItemData.videoPoster : undefined}/>);
            } else if (contentType === "video-no-sound") {
              return (<VideoComponent 
                source={carouselItemData.contentUrl} 
                videoHasSound={false} 
                videoPoster={carouselItemData.videoPoster ? carouselItemData.videoPoster : undefined}/>);
            } else {
              return (
                <div>
                  <img src={carouselItemData.contentUrl} className={classes.cartCarouselImage}/>
                </div>
              );
            }
          })
        }
      </Carousel>
    </Box>
  );
}