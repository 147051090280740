import { Box, Dialog, Link, Slide, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { updateUserTrigger } from '../redux/CartRedux';
import { closeOverlayAndReset, openOverlay, updateSignUpOrSignIn } from '../redux/UIOverlayAccountViewRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import PageAccountSignIn from './PageAccountSignIn';
import PageAccountSignUp from './PageAccountSignUp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 1) + " !important") as any,
    },
    dialogContentContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start', 
    },
    dialogContentLeftContainer: {
      width: '46%',
      paddingLeft: '29%',
      [theme.breakpoints.only('xs')]: {
        paddingLeft: '10%',
        paddingTop: '20px',
        width: '80%',
        justifyContent: 'center',
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
    toggleSignUpSignInContainer: {
      paddingTop: '8px',
      paddingBottom: '10px',
      // paddingLeft: '10px',
      paddingRight: '10px',
    },
    titleLogoContainer: {
      paddingTop: '2.5vw',
      paddingBottom: '6vw',
      height: '5vw',
      [theme.breakpoints.only('xs')]: {
        paddingTop: '5vw',
        paddingBottom: '7vw',
        height: '10vw',
      },
      cursor: 'pointer',
    },
    titleLogoDesktop: {
      paddingTop: '1.5vw',
      height: '5vw',
      [theme.breakpoints.only('xs')]: {
        paddingTop: '3vw',
        height: '10vw',
      }
    },
    signInsignUpToggleText: {
      fontSize: '13px',
      fontWeight: 300,
      [theme.breakpoints.only('xs')]: {
        fontSize: '13px',
      },
      textAlign: "center"
      //padding: '5px',
    },
    horizontalBarDivider: {
      width: '260px',
      margin: '10px 0px 6px 0px',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      }
    },
    linkText: {
      color: '#1e1e1e',
      fontWeight: 500,
    }
  }),
);

interface SignUpSignInLinkProps {
  isSignUp: boolean
  handleClick: (isSignUp: boolean) => void
}

export function SignUpSignInLink({isSignUp, handleClick}: SignUpSignInLinkProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  function handleLink() {
    handleClick(isSignUp);
  }

  return (
  <div className={classes.toggleSignUpSignInContainer}>
    {
      isSignUp === false ? 
      <Box>
        <Typography className={classes.signInsignUpToggleText}>
          Belum punya akun? {"   "}
          <Link href="#" className={classes.linkText} onClick={handleLink}>
            Daftar disini.
          </Link>
        </Typography>
      </Box> : 
      <Box>
        <Typography className={classes.signInsignUpToggleText}>
          Sudah punya ukuran di akun? {"   "}
          <Link href="#" className={classes.linkText} onClick={handleLink}>
            Masuk akun disini.
          </Link>
        </Typography>
      </Box>
    }
  </div>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayView() {
  const overlayView = useAppSelector(state => state.overlayAccountView);
  const isSignedIn = useAppSelector(state => state.account.authState === 'signedin');
  const [signInPhoneNumber, setSignInPhoneNumber] = React.useState<string>('');
  const dispatch = useAppDispatch();
  const classes = useStyles();

  function closeOverlayAndTurnOffUserTrigger() {
    dispatch(closeOverlayAndReset());
    dispatch(updateUserTrigger(false));
  }
  
  if (isSignedIn) {
    dispatch(closeOverlayAndReset());
    return null;
  }

  let children: JSX.Element[] = [];
  if (overlayView.signUpOrSignIn === 'signIn') {
    children.push(
      <PageAccountSignIn phoneNumber={signInPhoneNumber} 
      changeToSignUpWithPhoneNumber={(phoneNumber: string) => {
        dispatch(updateSignUpOrSignIn('signUp'));
      }}/>
    );
  } else {
    children.push(
      <PageAccountSignUp changeToSignInWithPhoneNumber={(phoneNumber: string) => {
        setSignInPhoneNumber(phoneNumber);
        dispatch(updateSignUpOrSignIn('signIn'));
      }}/>
    );
  }
  children.push(
    <SignUpSignInLink 
    isSignUp={overlayView.signUpOrSignIn === 'signUp'}
    handleClick={(isSignUp) => {
      if (isSignUp) {
        dispatch(updateSignUpOrSignIn('signIn'))
      } else {
        dispatch(updateSignUpOrSignIn('signUp'))
      }
    }}/>
  );

  return (
    <Dialog
    fullScreen
    maxWidth="lg"
    open={overlayView.status === UIOverlayViewStatus.On}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => closeOverlayAndTurnOffUserTrigger()}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    className={classes.dialogContainer}>
      <div className={classes.dialogContentContainer}>
        <Box className={classes.titleLogoContainer} >
          <Link onClick={() => closeOverlayAndTurnOffUserTrigger()}>
            <img className={classes.titleLogoDesktop} src={'https://studiosclo.uk/legacy/CreateSizeOverlay_StudioSLogo.svg'} alt="maja logo"/>
          </Link>
        </Box>
        <div className={classes.dialogContentLeftContainer}>
            <Box>
              {children}
            </Box>
        </div>
      </div>
    </Dialog>
  )
}