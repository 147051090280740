import React, { useCallback, useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useInView } from 'react-intersection-observer';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Box, Link, useMediaQuery } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Mixpanel } from '../mixpanel';
import { attemptProductGlimpseEvent } from '../redux/ProductsListRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import { FareastButton, FareastButtonGrey } from './OverlayComponentButton';
import { countdownRenderer, determineMediaType } from './PageProductDetail';
import { VideoComponent } from './PageComponentElementCarousel';
import { postNewProductAnalyticsLog } from '../redux/ProductAnalyticsRedux';
import { getDiscountedPriceInProductSpotlightList, getTimestampInProductSpotlightList } from '../redux/CartRedux';
import Countdown from 'react-countdown';

export function HorizontalInfScrollImg({
  displayImageList,
  widthSet = 35,
}: {
  displayImageList: string[],
  widthSet?: number
}) {

  const ref = useRef(null);
  const containerWidth = (widthSet * displayImageList.length) + "%";

  const renderCards = displayImageList.map((item, i) => {
    return (
      <img src={item} alt={'car-mini-img'} style={{
        width: (Math.round((10000 - 300) / displayImageList.length) / 100) + '%',
      }} />
    );
  }).filter(Boolean);

  return (
    <div style={{overflow: 'hidden', width: '100%'}}>
      <div
        ref={ref}
        style={{
          transform: 'translateX(calc(0%))',
          overflow: 'visible',
          animation: 'animateContainer 10s linear forwards infinite',
          animationPlayState: 'running',
          width: `${containerWidth}`,
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        {renderCards}
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          right: '-100%',
          position: 'absolute',
        }}>
          {renderCards}
        </div>
      </div>
    </div>
  );
}
export function HorizontalInfScrollImgHalf({
  displayImageList,
  widthSet = 35,
}: {
  displayImageList: string[],
  widthSet?: number
}) {

  const ref = useRef(null);
  const containerWidth = (widthSet * displayImageList.length) + "%";

  const renderCards = displayImageList.map((item, i) => {
    return (
      <img src={item} alt={'car-mini-img'} style={{
        width: (Math.round((10000 - 300) / displayImageList.length) / 100) + '%',
      }} />
    );
  }).filter(Boolean);

  return (
    <div style={{overflow: 'hidden', width: '100%'}}>
      <div
        ref={ref}
        style={{
          transform: 'translateX(calc(100%))',
          overflow: 'visible',
          animation: 'animateContainerHalf 10s linear forwards infinite',
          animationPlayState: 'running',
          width: `${containerWidth}`,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {renderCards}
      </div>
    </div>
  );
}