import { Box, Button, InputAdornment, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { updateState } from '../redux/AccountRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { FareastButton } from './OverlayComponentButton';
import { ClothingMeasurements, ClothingType, ClothingTypeDisplayString, FitType, FitTypeDisplayString } from 'common-ts/dist/models/UserMeasurements';
import OverlayComponentCanvas from './OverlayComponentCanvasFitAnimation';
import OverlayComponentBaseTemplate from './OverlayComponentBaseTemplate';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { VarietySliderComponent } from './OverlayViewUniversalSize4Result';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '90%',
      [theme.breakpoints.only('xs')]: {
        width: '95%',
      }
    },
    form: {
      height: 'auto',
      [theme.breakpoints.only('xs')]: {
        padding: '0px 0px 0px 0px',
      }
    },
    formRow: {
      paddingBottom: '30px',
    },
    subFormTitle: {
      fontFamily: 'OakesGrotesk',
      fontWeight: 400,
      color: '#000000',
      fontSize: '24px',
      [theme.breakpoints.only('xs')]: {
        fontSize: '18px',
      },
    },
    toggleSignUpSignInContainer: {
      paddingTop: '34px',
      paddingBottom: '6px',
      // paddingLeft: '10px',
      paddingRight: '10px',
    },
  }),
);

const validationSchema = yup.object({
  measName: yup.string()
    .required('Is required')
    .min(1, 'Cannot be empty'),
});

export interface Props {
  clothingMeasurements: ClothingMeasurements | undefined
  clothingType: ClothingType
  fitType: FitType
  key2: string
  startingName: string
  handleSubmit: (measName: string) => void
  handleBack: () => void
  isAllowedBack: boolean
  isMinimized?: boolean
  showDisplayImagesArray?: boolean
  displayImagesArray?: string[]
}

export default function RegularSaveMeasurement({clothingMeasurements, clothingType, fitType, key2, startingName, handleSubmit, handleBack, isMinimized=false, isAllowedBack, showDisplayImagesArray, displayImagesArray}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  console.log({clothingMeasurements, clothingType, fitType, key2, startingName, handleSubmit, handleBack, isMinimized, isAllowedBack, showDisplayImagesArray, displayImagesArray})

  const sliderTitle = `DIPAKAI UNTUK ${ClothingTypeDisplayString[clothingType].toUpperCase()} ${FitTypeDisplayString[fitType].toUpperCase()}`

  const formik = useFormik({
    initialValues: {
      measName: startingName,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values.measName)
    },
  });

  return (
    <OverlayComponentBaseTemplate alignItems="flex-start">
      <Box>
        <form className={classes.form} noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Box style={{
            marginTop: '2vw',
            marginBottom: isMdOrUp ? '30px' : '5vw',
          }}>
            {
              !isMinimized && (
                <Typography style={{
                  fontSize: isMdOrUp ? '30px' : '5vw',
                  marginBottom: '3vw',
                  fontWeight: 500,
                  marginLeft: '10px'
                }}>
                  Beri Nama Ukuran Baru
                </Typography>
              )
            }
            <Box
              style={{
                height: isMdOrUp ? '200px' : undefined
              }}
            >
              {
                clothingMeasurements ? 
                <OverlayComponentCanvas
                clothingType={clothingType}
                fitType={fitType}
                putAtTop={false}
                scaleRatio={0.9}
                incrementTogether={true}
                goalFit={clothingMeasurements}
                drawGuidelinesData={undefined}/>:
                null
              }
            </Box>
          </Box>
          <div className={classes.formRow}>
            <TextField
              // multiline
              margin="normal"
              className={classes.textField}
              id="measName"
              name="measName"
              label={'Nama Ukuran'}
              type="text"
              value={formik.values.measName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.measName && Boolean(formik.errors.measName)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 50,
              }}
              FormHelperTextProps={{
                style: {
                  display: 'flex',
                  justifyContent: 'space-between',
                },
              }}
              helperText={
                <>
                  <span>{formik.touched.measName && formik.errors.measName}</span>
                  <span>{`${formik.values.measName.length}/50`}</span>
                </>
              }
            />
          </div>
          {
          showDisplayImagesArray && displayImagesArray && (
            <Box
              style={{
                width: isMinimized ? '82vw' : '90vw',
                overflow: 'hidden',
                margin: '-10px 0px 40px 0px'
              }}
            >
              <VarietySliderComponent
                isLoading={false}
                title={sliderTitle}
                displayImagesArray={displayImagesArray}
                customScrollProductHeight={'28vw'}
                customElementWidth={'16.5vw'}
                numOfProducts={displayImagesArray.length}
              />
            </Box>
            )
          }
          <Box style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
            {
              isAllowedBack ? (
                <>
                  <Button
                    onClick={() => {
                        handleBack()
                    }}
                    style={{
                      padding: '7px 25px 4.5px 25px',
                      borderRadius: '20px',
                      fontSize: '10.5px',
                      fontWeight: 600,
                      color: 'white',
                      letterSpacing: '0.75px',
                      backgroundColor: 'black',
                      margin: '0px 0px 20px 15px'
                    }}
                  >
                    KEMBALI
                </Button>
                  <Button
                    type="submit"
                    style={{
                      padding: '7px 25px 4.5px 25px',
                      borderRadius: '20px',
                      fontSize: '10.5px',
                      fontWeight: 600,
                      color: 'white',
                      letterSpacing: '0.75px',
                      backgroundColor: 'black',
                      margin: '0px 15px 20px 0px'
                    }}
                  >
                    SIMPAN
                </Button>
              </>
              ) : (
                <FareastButton type="submit"
                  buttonMarginTopBottom="20px"
                  buttonMarginLeftRight="0px">
                  SIMPAN
                </FareastButton>
              )
            }
            
          </Box>
        </form>
      </Box>
    </OverlayComponentBaseTemplate>
  );
}