import { Box, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { TypeAnimation } from 'react-type-animation';

const TypingAnimation = () => {
  return (
    <TypeAnimation
      sequence={[
        'Your Passion',
        1000, // Waits 1s
        'Your Mission',
        1000, // Waits 1s
        'What You Love', 
        1000, // Waits 1s
        'What Matters',
        4000
      ]}
      wrapper="div"
      cursor={true}
      repeat={Infinity}
      style={{ 
        fontSize: '4.4vw',
        fontFamily: 'OakesGrotesk',
        fontWeight: 500,
        color: '#262626'
      }}
      speed={25}
    />
  );
};

const TypingAnimationMobile = () => {
  return (
    <TypeAnimation
      sequence={[
        'Your Passion',
        1000, // Waits 1s
        'Your Mission',
        1000, // Waits 1s
        'What You Love', 
        1000, // Waits 1s
        'What Matters',
        4000
      ]}
      wrapper="div"
      cursor={true}
      repeat={Infinity}
      style={{ 
        fontSize: '9.4vw',
        fontFamily: 'OakesGrotesk',
        fontWeight: 500,
        color: '#121212'
      }}
      speed={25}
    />
  );
};

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  contentContainer: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    cursor: 'pointer',
    WebkitTapHighlightColor: 'rgba(255,255,255,0)'
  },
  element: {
    width: '100%',
  },
  typingAnimationContainer: {
    position: 'absolute',
    top: '19vw',
    left: '12.7vw'
  },
  typingAnimationContainerMobile: {
    position: 'absolute',
    top: '46vw',
    left: '9.1vw',
  }
}));

interface Props {
  scrollToFunction: () => void
}

export default function GreatFitGreatLooks({ scrollToFunction }: Props) {
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();

  const nextToImage = (
    <img src='https://studiosclo.uk/legacy/CareerPageDesktop_V2_Header.jpg'
    className={classes.element}/>
    );
  const mobile = (
    <img src='https://studiosclo.uk/legacy/CareerPageMobile_V2_Header.jpg' className={classes.element}/>
    );
  
  const content = (
    <Box onClick={scrollToFunction} className={classes.contentContainer}>
      {
        isMdOrUp ? 
        nextToImage :
        mobile
      }
      {
        isMdOrUp ? 
        <Box className={classes.typingAnimationContainer}>
          <TypingAnimation/>
        </Box> :
        <Box className={classes.typingAnimationContainerMobile}>
          <TypingAnimationMobile/>
        </Box>
      }
      
    </Box>
  )

  return content;
}