import { Box, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  contentContainer: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '-2px',
  },
  contentTitle: {
    fontWeight: 600,
    fontSize: '3.7vw',
    lineHeight: '5vw',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    marginBottom: '32px',
    padding: '0px 20px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
  },
  contentText: {
    fontWeight: theme.typography.fontWeightLight as number,
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
    lineHeight: '1.8',
    marginBottom: '0px',
    padding: '0px 20px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  element: {
    width: '100%',
  }
}));

export default function GreatFitGreatLooks() {
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();

  const nextToImage = (
    <img src='https://studiosclo.uk/legacy/CareerPageDesktop_V2_Description.svg' 
    className={classes.element}/>
    );
  const mobile = (
    <img src='https://studiosclo.uk/legacy/CareerPageMobile_V2_Description.svg' className={classes.element}/>
    );

  return (
  <Box className={classes.contentContainer}>
    {
      isMdOrUp ? 
      nextToImage :
      mobile
    }
  </Box>)
}