import { Button, Typography, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  introductionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    marginLeft: '-10vw',
    overflow: 'hidden',
  },
  svgTextMain: {
    width: '42vw',
    marginBottom: '14px',
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
      marginTop: '20px',
    }
  },
  svgTextCaraKerja: {
    position: 'absolute',
    width: '60vw',
    marginBottom: '14px',
    [theme.breakpoints.only('xs')]: {
      width: '62vw',
      marginTop: '2vw',
      position: 'initial',
      marginBottom: '0vw',
    }
  },
  svgTextPS: {
    width: '46vw',
    marginBottom: '14px',
    [theme.breakpoints.only('xs')]: {
      width: '80vw',
      marginTop: '10px',
    }
  },
  svgTextHanya: {
    marginTop: '1vw',
    width: '12vw',
    marginBottom: '14px',
    [theme.breakpoints.only('xs')]: {
      width: '42vw',
      marginTop: '4vw',
    }
  },
  pelajariLanjut: {
    cursor: 'pointer',
    marginTop: '-2.3vw',
    marginBottom: '1.9vw',
  },
  pelajariLanjutText: {
    fontSize: '15px',
    [theme.breakpoints.only('xs')]: {
      width: '80vw',
      fontSize: '13.5px',
    }
  },
  mulaiButton: {
    marginTop: '1.7vw',
    padding: '5px 40px 5px 40px',
    textTransform: 'none',
    color: '#fefefe',
    fontWeight: 400,
    letterSpacing: '0.5px',
    backgroundColor: '#232323',
    [theme.breakpoints.only('xs')]: {
      marginTop: '8vw',
    }
  },
  caraKerjaContainer: {
    position: 'relative',
    marginTop: '-2vw',
    paddingBottom: '14vw',
    [theme.breakpoints.only('xs')]: {
      paddingBottom: '0vw',
      paddingLeft: '6vw',
    }
  }
}),
);

interface Props {
  handleSubmit: () => void
}

export default function PageCheckoutMeasurements({ handleSubmit }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  let children: JSX.Element[] = [];

  let submitChildren: JSX.Element[] = [];

  if (isMdOrUp) {
    children.push(
      <Box
        style={{
          display: 'flex',
          marginBottom: '100px'
        }}
      >
        <video autoPlay muted playsInline loop
          src="https://studiosclo.uk/general/video_ist_1.mp4" style={{
          width: '101%',
          marginTop: '6px',
          maxWidth: '400px',
        }}/>
        <Box style={{
          width: '400px',
          padding: '22px 24px',
        }}>
          <Box style={{
            backgroundColor: '#0B8325',
            padding: '7.2px 8px 3.8px 10px',
            marginBottom: '15px',
            borderRadius: 20,
            width: '125px'
          }}>
            <Typography style={{
              fontFamily: 'OakesGrotesk',
              fontWeight: 200,
              color: '#fefefe',
              fontSize: '10.4px',
              letterSpacing: '0.8px',
              display: 'flex',
            }}>
              INSTANT SIZE TEAM<span style={{fontSize:'6.8px', paddingLeft: '1px'}}>TM</span>
            </Typography>
          </Box>
          <Typography style={{
            fontFamily: 'OakesGrotesk',
            fontWeight: '600',
            color: '#343434',
            fontSize: '20.8px',
            paddingBottom: '12px',
          }}>
            Personal Tailor kamu sudah <u>ready!</u>
          </Typography>
          <Typography style={{
            fontFamily: 'OakesGrotesk',
            fontWeight: 300,
            color: '#676767',
            fontSize: '13px',
            paddingBottom: '8px',
          }}>
            Expert kami siap membuatkan ukuran personal kamu, hanya dalam waktu 5 menit. Lebih personal, lebih pas, lebih puas.
          </Typography>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button variant="outlined" size="large"
              onClick={handleSubmit} className={classes.mulaiButton} style={{
                margin: '30px 0px 0px 0px',
                padding: '8px 40px 4px',
                borderRadius: 50,
              }}>
                MULAI
            </Button>
          </Box>
        </Box>
      </Box>
      );
    // children.push(
    //   <Box style={{
    //     width: '400px',
    //     padding: '22px 24px',
    //   }}>
    //     <Box style={{
    //       backgroundColor: '#0B8325',
    //       padding: '7.2px 8px 3.8px 10px',
    //       marginBottom: '10px',
    //       borderRadius: 20,
    //       width: '125px'
    //     }}>
    //       <Typography style={{
    //         fontFamily: 'OakesGrotesk',
    //         fontWeight: 200,
    //         color: '#fefefe',
    //         fontSize: '10.4px',
    //         letterSpacing: '0.8px',
    //         display: 'flex',
    //       }}>
    //         INSTANT SIZE TEAM<span style={{fontSize:'6.8px', paddingLeft: '1px'}}>TM</span>
    //       </Typography>
    //     </Box>
    //     <Typography style={{
    //       fontFamily: 'OakesGrotesk',
    //       fontWeight: '600',
    //       color: '#343434',
    //       fontSize: '20.8px',
    //       paddingBottom: '12px',
    //     }}>
    //       Personal Tailor kamu sudah <u>ready!</u>
    //     </Typography>
    //     <Typography style={{
    //       fontFamily: 'OakesGrotesk',
    //       fontWeight: 300,
    //       color: '#676767',
    //       fontSize: '13px',
    //       paddingBottom: '8px',
    //     }}>
    //       Expert kami siap membuatkan ukuran personal kamu, hanya dalam waktu 5 menit. Lebih personal, lebih pas, lebih puas.
    //     </Typography>
    //   </Box>
    //   );

    // submitChildren.push(
    //   <Button variant="outlined" size="large"
    //   onClick={handleSubmit} className={classes.mulaiButton} style={{
    //     margin: '10px',
    //     padding: '8px 40px 4px',
    //     borderRadius: 50,
    //   }}>
    //     MULAI
    //   </Button>
    // )
  } else {
    children.push(
      <video autoPlay muted playsInline loop
      src="https://studiosclo.uk/general/video_ist_1.mp4" style={{
        width: '101%',
        marginTop: '6px',
      }}/>
      );
    children.push(
      <Box style={{
        padding: '5.5vw 6vw',
      }}>
        <Box style={{
          backgroundColor: '#0B8325',
          padding: '1.2vw 1vw 0.8vw 3vw',
          marginBottom: '3vw',
          borderRadius: 20,
          width: '36vw'
        }}>
          <Typography style={{
            fontFamily: 'OakesGrotesk',
            fontWeight: 400,
            color: '#fefefe',
            fontSize: '2.5vw',
            letterSpacing: '0.23vw',
            display: 'flex',
          }}>
            INSTANT SIZE TEAM<span style={{fontSize:'2.5vw', paddingLeft: '0.5vw'}}>TM</span>
          </Typography>
        </Box>
        <Typography style={{
          fontFamily: 'OakesGrotesk',
          fontWeight: '600',
          color: '#343434',
          fontSize: '5vw',
          paddingBottom: '3vw',
        }}>
          Personal Tailor kamu sudah <u>ready!</u>
        </Typography>
        <Typography style={{
          fontFamily: 'OakesGrotesk',
          fontWeight: 300,
          color: '#676767',
          fontSize: '3.5vw',
          paddingBottom: '2vw',
        }}>
          Expert kami siap membuatkan ukuran personal kamu, hanya dalam waktu 5 menit. Lebih personal, lebih pas, lebih puas.
        </Typography>
      </Box>
      );

    submitChildren.push(
      <Button variant="outlined" size="large"
      onClick={handleSubmit} className={classes.mulaiButton} style={{
        margin: '10px',
        padding: '2vw 10vw 1vw',
        borderRadius: 50,
      }}>
        MULAI
      </Button>
    )
  }

  return (
    <Box className={classes.introductionContainer}>
      {children}
      <Box style={{
        width: isMdOrUp ? '400px' : '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      }}>
        {submitChildren}
      </Box>
    </Box>
  );
}