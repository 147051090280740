import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { APIRequestStatus } from "common-ts/dist/models/APIRequestStatus";
import axios from "axios";
import { RootState } from "../store";
import {
  CategoryReview,
  CategoryReviewListState,
  CategoryReviewRecap,
} from "common-ts/dist/models/CategoryReview";
import { ProductCategory } from "common-ts/dist/models/Product";
import { changeFileDataSource } from "../App";

// Dari common-ts/dist/models - JANGAN TYPO HAIDAR
// DynamoDB Table Name : CategoryReviewTable
//
// CategoryReviewInterface
// export default interface CategoryReview {
//     category: string; // HAIDAR-TODO -> Belum ada guidelinenya
//     timestamp: string;
//     name: string;
//     comment: string;
//     rating: number;
//     displayImageList?: string; // Gambar opsional
//     productNameList?: string; // HAIDAR-TODO => Waktu itu dibiccarakan kalau nama produk opsional -> saat ini masih digunakan karena ikut Figma (nama produk di UI ambil item di indeks pertama)
//     userSub?: string; // Opsional
//   }



// Initial State
// Untuk CreateSlice => Daftar review UNTUK SATU kategori DAN draft review baru
const initialState: CategoryReviewListState = {
  // Read
  categoryReviewRecap: {},
  categoryReviewListDict: {},
  getStatus: APIRequestStatus.Idle,
  getStatusRecap: APIRequestStatus.Idle,

  // Write
  categoryReviewDraft: {}, // Tipenya any
  fileDraft: {}, // Untuk menyimpan gambar yang di-upload : any
  postStatus: APIRequestStatus.Idle,
};

// FUNGSI => API CALL - GET
// Payload => Query String = Nama Kategori yang diinginkan
// Body
// {
// 'categoryReviewList' : [ {categoryReview1}, {categoryReview2}, ... ]
// }
export const fetchCategoryReviewRecap = createAsyncThunk(
  "categoryReviewList/fetchCategoryReviewRecap",
  async (payload: string, thunkAPI) => {
    const response = await axios({
      method: "get",
      url: "https://ck8k2pv7c9.execute-api.ap-southeast-1.amazonaws.com/ProductAPIProduction/category-reviews",
    });

    return response.data;
  }
);

export const fetchCategoryReviewList = createAsyncThunk(
  "categoryReviewList/fetchCategoryReviewList",
  async (payload: string, thunkAPI) => {
    const response = await axios({
      method: "get",
      url: "https://ck8k2pv7c9.execute-api.ap-southeast-1.amazonaws.com/ProductAPIProduction/category-reviews",
      params: {
        'category': payload
      }
    });

    return response.data;
  }
);

// FUNGSI => API CALL - POST
// Referensi : prodpanel/FilePostRedux.tsx
// 1. Upload Image dan dapatkan imageURL -> nanti di-push ke array yang akan di-stringify untuk menjadi displayImageList akhir
// 2. Buat item baru di fileTable => LEGACY -> Nanti bisa dihapus apabila perlu
// 3. fetch POST ke CategoryReviewTable
export const postNewCategoryReview = createAsyncThunk(
  "categoryReviewList/postNewCategoryReview",
  async (payload, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    let fileDraft = rootState.categoryReviewList.fileDraft;
    let categoryReviewDraft = rootState.categoryReviewList.categoryReviewDraft;

    let postFileInformationSuccess = true; // Tidak penting - LEGACY

    // Daftar gambar display
    let displayImageListArray = [];

    for (
      let fileIndex = 0;
      fileIndex < fileDraft.fileList.length;
      fileIndex = fileIndex + 1
    ) {
      console.log("Mengunggah gambar review ke-" + (fileIndex + 1));

      let currentFileDraft: any = {};

      currentFileDraft.file = fileDraft.fileList[fileIndex];
      currentFileDraft.fileName = currentFileDraft.file.name.replace(/ /g, "-"); // Remove Whitespace(s)
      currentFileDraft.fileDataType = currentFileDraft.file.type;
      currentFileDraft.fileCategory = fileDraft.fileCategory;
      currentFileDraft.fileCategoryData = fileDraft.fileCategoryData;

      const signedURLResponse = await axios({
        method: "post",
        url: "https://w3q706jlua.execute-api.ap-southeast-1.amazonaws.com/FileSystemAPIProduction/signedurl",
        data: currentFileDraft,
      });

      console.log("SignedURL Response: " + signedURLResponse);

      const uploadURL = signedURLResponse.data.uploadURL;
      const createdFileKey = signedURLResponse.data.fileKey;

      try {
        const uploadResult = await axios({
          method: "put",
          url: uploadURL,
          data: currentFileDraft.file,
          headers: {
            "Content-Type": currentFileDraft.fileDataType,
          },
        });

        console.log("Success uploading to S3:");
        console.log(uploadResult.headers);
        console.log(uploadResult.statusText);
      } catch (err) {
        console.log("Error uploading to S3:");
        console.log(err);
      }

      console.log("continue...");

      const fileURL = uploadURL.split("?")[0];

      // INI PENTING => Push URL gambar yang telah diunggah
      displayImageListArray.push(fileURL);

      // Butuhnya cuma sampai sini => Sementara pakai dulu sampai akhir for loop -> Mungkin perlu di-uplaod informasinya ke fileTable juga

      let categoryData = currentFileDraft.fileCategoryData
        ? currentFileDraft.fileCategoryData
        : "";

      let fileInformation = {
        fileName: currentFileDraft.fileName,
        fileDataType: currentFileDraft.fileDataType,
        fileCategory: currentFileDraft.fileCategory,
        fileURL: fileURL,
        fileKey: createdFileKey,
        fileCategoryData: categoryData,
      };

      console.log("Putting item to table: ");
      console.log(fileInformation);

      try {
        const response = await axios({
          method: "post",
          url: "https://w3q706jlua.execute-api.ap-southeast-1.amazonaws.com/FileSystemAPIProduction/files",
          data: fileInformation,
        });
        console.log(response);
        postFileInformationSuccess =
          postFileInformationSuccess &&
          response.data.postFileInformationSuccess;
      } catch (err) {
        console.log("Failed to put item into fileTable: ");
        console.log(err);
      }
    }

    let response;

    // Gabungkan URL gambar tadi
    let tempData = categoryReviewDraft;
    tempData.displayImageList = JSON.stringify(displayImageListArray);

    const data = JSON.stringify(tempData);

    // HAIDAR-TODO => ini jadi yang harus sign-in?
    if (rootState.account.authState === "signedin") {
      const idToken = rootState.account.idToken;

      response = await axios({
        method: "post",
        url: "", // HAIDAR-TODO - URL
        headers: {
          Authorization: "Bearer " + idToken,
        },
        data: data,
      });
    } else {
      response = await axios({
        method: "post",
        url: "", // HAIDAR-TODO - URL
        data: data,
      });
    }

    // Panggil Ulang fetchCategoryReviewList dengan category saat ini
    // thunkAPI.dispatch(fetchCategoryReviewRecap(categoryReviewDraft.category));

    // HAIDAR-TODO => fileDraft harus dikosongkan lagi setelah semua berhasil => Di Komponen yang pakai

    return response.data;
  }
);

export const categoryReviewListSlice = createSlice({
  name: "categoryReviewList",
  initialState,
  reducers: {
    inputCategoryReviewFileDraft(state, action) {
      state.fileDraft = action.payload;
      console.log(`Nilai Draft Gambar: ${JSON.stringify(state.fileDraft)}`);
    },
    inputCategoryReviewForm: (state, action: PayloadAction<CategoryReview>) => {
      Object.assign(state.categoryReviewDraft, action.payload);
    },
    resetCategoryReviewDraft: (state) => {
      state.categoryReviewDraft = {};
      state.fileDraft = {};
    },
  },
  extraReducers(builder) {
    // HAIDAR-TODO : builder untuk POST
    builder
      .addCase(fetchCategoryReviewRecap.pending, (state, action) => {
        // Ganti status GET di state => BERLANGSUNG
        state.getStatusRecap = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchCategoryReviewRecap.fulfilled, (state, action) => {
        if (action?.payload && action?.payload?.success) {
          let categoryReviewRecap: CategoryReviewRecap = action.payload.fetchedData;

          state.categoryReviewRecap = categoryReviewRecap;
          console.log(categoryReviewRecap);
        }

        // Ganti status GET di state => BERHASIL
        state.getStatusRecap = APIRequestStatus.Success;
      })
      .addCase(fetchCategoryReviewRecap.rejected, (state, action) => {
        // Ganti status GET di state => GAGAL
        state.getStatusRecap = APIRequestStatus.Failure;
        console.log("Category Review Recap Fetch [GET] Failed");
      })
      .addCase(fetchCategoryReviewList.pending, (state, action) => {
        // Ganti status GET di state => BERLANGSUNG
        state.getStatus = APIRequestStatus.RequestInProgress;
      })
      .addCase(fetchCategoryReviewList.fulfilled, (state, action) => {
        if (action?.payload && action?.payload?.success) {
          let categoryFetched: ProductCategory = action.payload.category;
          let categoryReviewList: CategoryReview[] = JSON.parse(changeFileDataSource(JSON.stringify(action.payload.categoryReviewList)));
          let lastEvaluatedKey: ProductCategory = action.payload.lastEvaluatedKey;

          if (state?.categoryReviewListDict?.[categoryFetched] !== undefined) {
            state.categoryReviewListDict[categoryFetched] = {
              // Pakai ini kalau lastEvaluatedKey logic nanti akan diimplementasikan
              // categoryReviewList: (
              //   state.categoryReviewListDict[categoryFetched]?.categoryReviewList === undefined ? 
              //   [] :
              //   (state.categoryReviewListDict[categoryFetched] as any).categoryReviewList
              //   ).concat(categoryReviewList),
              categoryReviewList: categoryReviewList,
              lastEvaluatedKey: lastEvaluatedKey,
            }
          } else {
            state.categoryReviewListDict[categoryFetched] = {
              categoryReviewList: categoryReviewList,
              lastEvaluatedKey: lastEvaluatedKey,
            }
          }
          
        }

        // Ganti status GET di state => BERHASIL
        state.getStatus = APIRequestStatus.Success;
      })
      .addCase(fetchCategoryReviewList.rejected, (state, action) => {
        // Ganti status GET di state => GAGAL
        state.getStatus = APIRequestStatus.Failure;
        console.log("Category Review List Fetch [GET] Failed");
      })
      // Ditto
      .addCase(postNewCategoryReview.pending, (state, action) => {
        state.postStatus = APIRequestStatus.RequestInProgress;
      })
      .addCase(postNewCategoryReview.fulfilled, (state, action) => {
        state.postStatus = APIRequestStatus.Success;
      })
      .addCase(postNewCategoryReview.rejected, (state, action) => {
        state.postStatus = APIRequestStatus.Failure;
        console.log("Category Review Fetch [POST] Failed");
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  inputCategoryReviewFileDraft,
  inputCategoryReviewForm,
  resetCategoryReviewDraft,
} = categoryReviewListSlice.actions;

export default categoryReviewListSlice.reducer;
