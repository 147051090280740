import { Button, Typography, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  introductionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    marginLeft: '-10vw',
    overflow: 'hidden',
  },
  svgTextMain: {
    width: '42vw',
    marginBottom: '14px',
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
      marginTop: '20px',
    }
  },
  svgTextCaraKerja: {
    position: 'absolute',
    width: '60vw',
    marginBottom: '14px',
    [theme.breakpoints.only('xs')]: {
      width: '62vw',
      marginTop: '2vw',
      position: 'initial',
      marginBottom: '0vw',
    }
  },
  svgTextPS: {
    width: '46vw',
    marginBottom: '14px',
    [theme.breakpoints.only('xs')]: {
      width: '80vw',
      marginTop: '10px',
    }
  },
  svgTextHanya: {
    marginTop: '1vw',
    width: '12vw',
    marginBottom: '14px',
    [theme.breakpoints.only('xs')]: {
      width: '42vw',
      marginTop: '4vw',
    }
  },
  pelajariLanjut: {
    cursor: 'pointer',
    marginTop: '-2.3vw',
    marginBottom: '1.9vw',
  },
  pelajariLanjutText: {
    fontSize: '15px',
    [theme.breakpoints.only('xs')]: {
      width: '80vw',
      fontSize: '13.5px',
    }
  },
  mulaiButton: {
    marginTop: '1.7vw',
    padding: '5px 40px 5px 40px',
    borderColor: '#000000',
    textTransform: 'none',
    [theme.breakpoints.only('xs')]: {
      marginTop: '8vw',
    }
  },
  caraKerjaContainer: {
    position: 'relative',
    marginTop: '-2vw',
    paddingBottom: '14vw',
    [theme.breakpoints.only('xs')]: {
      paddingBottom: '0vw',
      paddingLeft: '6vw',
    }
  }
}),
);

interface Props {
  handleSubmit: () => void
}

export default function PageCheckoutMeasurements({ handleSubmit }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [seeMore, setSeeMore] = React.useState<boolean>(false);

  let children: JSX.Element[] = [];

  let submitChildren: JSX.Element[] = [];

  if (isMdOrUp) {
    children.push(
      <Box
        style={{
          display: 'flex',
          marginBottom: '100px'
        }}
      >
        <img src="https://studiosclo.uk/general/smartsize-large.jpg" style={{
          width: '101%',
          marginTop: '6px',
          maxWidth: '400px',
        }}/>
        <Box style={{
          width: '400px',
          padding: '22px 24px',
        }}>
          <Typography style={{
            fontFamily: 'OakesGrotesk',
            fontWeight: 300,
            color: '#343434',
            fontSize: '10.4px',
            paddingBottom: '4.8px',
            letterSpacing: '0.8px',
          }}>
            INTRODUCING
          </Typography>
          <Typography style={{
            fontFamily: 'OakesGrotesk',
            fontWeight: 500,
            color: '#343434',
            fontSize: '28px',
            paddingBottom: '12px',
          }}>
            Smart Size<sup style={{ fontSize: '20px' }}>TM</sup>
          </Typography>
          <Typography style={{
            fontFamily: 'OakesGrotesk',
            fontWeight: 300,
            color: '#676767',
            fontSize: '10.4px',
            paddingBottom: '8px',
          }}>
            Dibuat dengan 1,281 data points dari seluruh dearah lokal Indonesia, Smart Size<sup>TM</sup> dari Studio S. dibuat 
            supaya semua orang di Indonesia bisa dapat ukuran lebih baik, dengan cepat dan tepat.
          </Typography>
          <Typography style={{
            fontFamily: 'OakesGrotesk',
            fontWeight: 300,
            color: '#676767',
            fontSize: '10.4px',
          }}>
            Berdasar tinggi, berat dan tipe badan kamu, kita akan meng-<i>adjust</i> ukuran reguler sehingga lebih pas saat kamu pakai.
          </Typography>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button variant="outlined" size="large"
            onClick={handleSubmit} className={classes.mulaiButton} style={{
              margin: '10px',
              padding: '8px 40px 4px',
              borderRadius: '16px',
            }}>
              <b>Mulai!</b>
            </Button>
          </Box>
        </Box>
      </Box>
      
      );
    // children.push(
    //   <Box style={{
    //     width: '400px',
    //     padding: '22px 24px',
    //   }}>
    //     <Typography style={{
    //       fontFamily: 'OakesGrotesk',
    //       fontWeight: 300,
    //       color: '#343434',
    //       fontSize: '10.4px',
    //       paddingBottom: '4.8px',
    //       letterSpacing: '0.8px',
    //     }}>
    //       INTRODUCING
    //     </Typography>
    //     <Typography style={{
    //       fontFamily: 'OakesGrotesk',
    //       fontWeight: 500,
    //       color: '#343434',
    //       fontSize: '28px',
    //       paddingBottom: '12px',
    //     }}>
    //       Smart Size<sup style={{ fontSize: '20px' }}>TM</sup>
    //     </Typography>
    //     <Typography style={{
    //       fontFamily: 'OakesGrotesk',
    //       fontWeight: 300,
    //       color: '#676767',
    //       fontSize: '10.4px',
    //       paddingBottom: '8px',
    //     }}>
    //       Dibuat dengan 1,281 data points dari seluruh dearah lokal Indonesia, Smart Size<sup>TM</sup> dari Studio S. dibuat 
    //       supaya semua orang di Indonesia bisa dapat ukuran lebih baik, dengan cepat dan tepat.
    //     </Typography>
    //     <Typography style={{
    //       fontFamily: 'OakesGrotesk',
    //       fontWeight: 300,
    //       color: '#676767',
    //       fontSize: '10.4px',
    //     }}>
    //       Berdasar tinggi, berat dan tipe badan kamu, kita akan meng-<i>adjust</i> ukuran reguler sehingga lebih pas saat kamu pakai.
    //     </Typography>
    //   </Box>
    //   );

    // submitChildren.push(
    //   <Button variant="outlined" size="large"
    //   onClick={handleSubmit} className={classes.mulaiButton} style={{
    //     margin: '10px',
    //     padding: '8px 40px 4px',
    //     borderRadius: '16px',
    //   }}>
    //     <b>Mulai!</b>
    //   </Button>
    // )
  } else {
    children.push(
      <img src="https://studiosclo.uk/general/smartsize-large.jpg" style={{
        width: '101%',
        marginTop: '6px',
      }}/>
      );
    children.push(
      <Box style={{
        padding: '5.5vw 6vw',
      }}>
        <Typography style={{
          fontFamily: 'OakesGrotesk',
          fontWeight: 300,
          color: '#343434',
          fontSize: '2.6vw',
          paddingBottom: '1.2vw',
          letterSpacing: '0.2vw',
        }}>
          INTRODUCING
        </Typography>
        <Typography style={{
          fontFamily: 'OakesGrotesk',
          fontWeight: 500,
          color: '#343434',
          fontSize: '7vw',
          paddingBottom: '3vw',
        }}>
          Smart Size<sup style={{ fontSize: '5vw' }}>TM</sup>
        </Typography>
        <Typography style={{
          fontFamily: 'OakesGrotesk',
          fontWeight: 300,
          color: '#676767',
          fontSize: '2.6vw',
          paddingBottom: '2vw',
        }}>
          Dibuat dengan 1,281 data points dari seluruh dearah lokal Indonesia, Smart Size<sup>TM</sup> dari Studio S. dibuat 
          supaya semua orang di Indonesia bisa dapat ukuran lebih baik, dengan cepat dan tepat.
        </Typography>
        <Typography style={{
          fontFamily: 'OakesGrotesk',
          fontWeight: 300,
          color: '#676767',
          fontSize: '2.6vw',
        }}>
          Berdasar tinggi, berat dan tipe badan kamu, kita akan meng-<i>adjust</i> ukuran reguler sehingga lebih pas saat kamu pakai.
        </Typography>
      </Box>
      );

    submitChildren.push(
      <Button variant="outlined" size="large"
      onClick={handleSubmit} className={classes.mulaiButton} style={{
        margin: '10px',
        padding: '2vw 10vw 1vw',
        borderRadius: '4vw',
      }}>
        <b>Mulai!</b>
      </Button>
    )
  }

  return (
    <Box className={classes.introductionContainer}>
      {children}
      <Box style={{
        width: isMdOrUp ? '400px' : '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      }}>
        {submitChildren}
      </Box>
    </Box>
  );
}